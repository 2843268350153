import { gql } from '@apollo/client';

export const INTERNAL_CREATE_GROUP = gql`
  mutation InternalCreateGroup($request: CreateGroupInput!) {
    internalCreateGroup(request: $request) {
      id
      name
    }
  }
`;
