import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingRuleConfiguration } from 'src/types/ReportingRuleConfiguration';
import { InsuranceEventType } from '../../../../../types/ReportingRule';

interface ConfigurationDetailsProps {
  ruleConfig: ReportingRuleConfiguration;
  onSelectRule: (
    insuranceEventType: InsuranceEventType,
    ruleId: string,
    configurationRuleId: string,
    providerId: string,
  ) => void;
  insuranceProviderId: string;
}

const ConfigurationDetails: FC<ConfigurationDetailsProps> = ({ ruleConfig, onSelectRule, insuranceProviderId }) => {
  const { t } = useTranslation();

  const Title = ({ title }: { title: string }) => (
    <Typography variant="caption" bold>
      {title}
    </Typography>
  );
  const Property = ({ value }: { value: string | JSX.Element }) => <Typography variant="body2">{value}</Typography>;

  return (
    <>
      <div className="configuration-list-item__titles">
        <Title title={t('insuranceManager:insurance-event')} />
        <Title title={t('insuranceManager:message-format')} />
        <Title title={t('insuranceManager:reported-event')} />
      </div>
      <ul className="configuration-list-item__list">
        {ruleConfig.reportingRuleGroups.flatMap((ruleGroup) => {
          return ruleGroup.rules.map((rule, index) => {
            const availableMessageFormat = rule.availableMessageFormat
              ? t(`insuranceManager:available-message-format.${rule.availableMessageFormat}`)
              : t('common:default');

            const messageFormatCategory: string =
              rule.messageFormatCategory !== null
                ? ' (' + t(`insuranceManager:message-format-category.${rule.messageFormatCategory}`) + ')'
                : '';

            return (
              <li key={`configuration-details-${rule.insuranceEventType}-${index}`}>
                <button
                  onClick={() => onSelectRule(rule.insuranceEventType, rule.id, ruleConfig.id, insuranceProviderId)}
                  className="configuration-list-item__button"
                >
                  <div className="configuration-list-item__row">
                    <Property value={t(`insuranceManager:insurance-event-type.${rule.insuranceEventType}`)} />
                    <Property value={rule.id !== null ? `${availableMessageFormat} ${messageFormatCategory}` : '-'} />
                    <Property value={rule.id === null ? t('common:no') : t('common:yes')} />
                  </div>
                </button>
              </li>
            );
          });
        })}
      </ul>
    </>
  );
};

export default ConfigurationDetails;
