import { Banner, InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceTypeMapping } from 'src/types/InsuranceTypeMapping';
import './invoice-row-mappings-drawer-tab.scss';

interface PaymentNoteTabProps {
  insuranceTypeMapping: InsuranceTypeMapping;
}

const InvoiceRowMappingsDrawerTab: FC<PaymentNoteTabProps> = ({ insuranceTypeMapping }) => {
  const { t } = useTranslation();

  return (
    <div className="invoice-row-mappings-drawer-tab">
      {insuranceTypeMapping.invoiceRowMappings.length !== 0 ? (
        <ListGroup variant="inline-edit">
          {insuranceTypeMapping.invoiceRowMappings.map((mapping) => (
            <InlineEdit label={mapping.invoiceRowName} bold />
          ))}
        </ListGroup>
      ) : (
        <Banner
          message={t(
            'insuranceManager:insurance-type-mapping-drawer.invoice-row-mappings-drawer-tab.no-invoice-row-mappings-banner-info-message',
          )}
        />
      )}
    </div>
  );
};
export default InvoiceRowMappingsDrawerTab;
