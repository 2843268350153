import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { Icon } from 'fgirot-k2-ui-components';
import TextInput from 'src/components/Common/TextInput';

interface MessageMetadataSearchInputProps {
  searchRequest: string;
  onSearch: (searchInput: string) => void;
  disabled?: boolean;
}

const MessageMetadataSearchInput = ({ onSearch, disabled, searchRequest }: MessageMetadataSearchInputProps) => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState(searchRequest);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (searchRequest !== event.target.value) {
      onSearch(searchInput);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchInput);
    } else if (e.key === 'Escape') {
      setSearchInput('');
    }
  };

  return (
    <TextInput
      disabled={disabled}
      placeholder={t('monitoring:transaction-analysis-tab.search-placeholder')}
      type="search"
      variant="default"
      validationKey="uuid"
      maxLength={64}
      icon={<Icon type="Search" />}
      value={searchInput}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
    />
  );
};

export default MessageMetadataSearchInput;
