import { Input, InputVariant, TextProps } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, FocusEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import { ValidationDataType } from 'src/types/validation/ValidationDataType';

type InputType = 'text' | 'search' | 'email' | 'number' | 'password' | 'tel' | 'url';

interface TextInputProps extends TextProps {
  value: string;
  label?: string;
  variant?: InputVariant;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  type?: InputType;
  validationKey?: ValidationDataType;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  className?: string;
  mandatory?:boolean;
}

const TextInput: FC<TextInputProps> = ({
  value,
  label,
  variant = 'inline-edit',
  placeholder,
  disabled,
  maxLength,
  type = 'text',
  innerRef,
  validationKey,
  onChange,
  onBlur,
  mandatory,
  ...props
}) => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { error } = inputSchema.validate({ [validationKey]: value }, { abortEarly: false });

  useEffect(() => {
    if (error && error.details.length > 0 && validationKey) {
      setErrorMessage(t(`validationErrors:${error.details[0].context.key}.${error.details[0].type}`));
    }
    error ?? setErrorMessage('');
  }, [error]);

  return (
    <Input.Text
      innerRef={innerRef}
      variant={variant}
      type={type}
      label={label}
      value={value}
      onChange={onChange}
      mandatory={mandatory}
      onBlur={onBlur}
      placeholder={placeholder}
      errorMessage={errorMessage}
      disabled={disabled}
      maxLength={maxLength}
      {...props}
    />
  );
};

export default TextInput;
