import { Button, Drawer, Icon, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentNote } from 'src/types/PaymentNote';
import ListProperty from 'src/components/Common/ListProperty';
import { Payment } from 'src/types/Payment';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';

interface PaymentNotesDetailsProps {
  payment: Payment;
  paymentNote: PaymentNote;
  onClose: () => void;
  onBackClick: () => void;
}

const PaymentNotesDetails: FC<PaymentNotesDetailsProps> = ({ payment, paymentNote, onClose, onBackClick }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="payment-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {`${t('economy:payment-drawer.payment-notes-tab.recent-notes')} ${payment.invoice.invoiceNumber}`}
        </Typography>
        <div className="payment-drawer__sub-title">
          <Typography>{payment.employer?.legalName}</Typography>
          <Pill label={payment.invoice.insuranceProvider.legalName} />
        </div>
      </div>
      <ListGroup variant="inline-edit">
        <ListProperty
          label={
            <div className="payment-note-tab__drawer-navigation-label">
              <Typography bold variant="body2" component="span">
                {t('economy:payment-drawer.payment-notes-tab.created-date-label')}
              </Typography>
            </div>
          }
          value={String(paymentNote.createdDate).substring(0, 10)}
        />
        <ListProperty
          label={t('economy:payment-drawer.payment-notes-tab.tab-label')}
          value={stringOrBlank(paymentNote.note)}
        />
      </ListGroup>
    </Drawer>
  );
};

export default PaymentNotesDetails;
