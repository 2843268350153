import { InlineEdit, ListGroup, Switch } from 'fgirot-k2-ui-components';
import './groups-drawer-roles-tab.scss';
import { GroupRole } from 'src/types/Group';
import { INTERNAL_ADMIN_ROLE_NAME, Role } from 'src/types/Role';
import { useInternalAddRoleToGroup } from 'src/service/internalGroup/InternalAddRoleToGroup';
import { useInternalRemoveRoleFromGroup } from 'src/service/internalGroup/InternalRemoveRoleFromGroup';
import { useTranslation } from 'react-i18next';

interface GroupsDrawerRolesTabProps {
  allRoles: Role[];
  groupRoles: GroupRole[];
  groupId: string;
  adminGroup: boolean;

}

const GroupsDrawerRolesTab: React.FunctionComponent<GroupsDrawerRolesTabProps> = ({
  allRoles,
  groupRoles,
  groupId,
  adminGroup,
}) => {
  const { t } = useTranslation();
  const addInternalRole = useInternalAddRoleToGroup();
  const removeInternalRole = useInternalRemoveRoleFromGroup();

  const isToggled = (roleId: string) => {
    return !!groupRoles.find((role) => role.id === roleId);
  };

  const onToggleChange = (roleId: string) => {
    if (isToggled(roleId)) {
      removeInternalRole(groupId, roleId);
    } else {
      addInternalRole(groupId, roleId);
    }
  };

  const getTranslation = (roleName: string) => {
    return t(`rolesTranslations:${roleName}`);
  };


  const filterByFGAdmin = (role: Role): boolean => {
    return adminGroup ? role.name === INTERNAL_ADMIN_ROLE_NAME : role.name !== INTERNAL_ADMIN_ROLE_NAME;
  };

  const shouldBeDisabled = (role: Role)=>{
    return role.name===INTERNAL_ADMIN_ROLE_NAME
  }

  return (
    <ListGroup variant="inline-edit" className="groups-drawer-roles-tab">
      {allRoles.filter(role => filterByFGAdmin(role)).map((role) => (
        <InlineEdit
          key={role.id}
          bold
          label={getTranslation(role.name)}
          data-testid={`role-switch-${isToggled(role.id) ? 'active' : 'inactive'}`}

        >
          <Switch toggled={isToggled(role.id)} onChange={() => onToggleChange(role.id)} disabled={shouldBeDisabled(role)}/>
        </InlineEdit>
      ))}
    </ListGroup>
  );
};

export default GroupsDrawerRolesTab;
