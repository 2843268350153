import React from 'react';
import { Tab, Tabs } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import PageBase from 'src/components/PageBase';
import InsuranceProductsTab from '../../components/InsuranceManager/tabs/InsuranceProductsTab/InsuranceProductsTab';
import InsuranceProvidersTab from '../../components/InsuranceManager/tabs/InsuranceProvidersTab/InsuranceProvidersTab';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/GetInsuranceProviders';
import { InsuranceProductPage } from 'src/types/InsuranceProduct';
import { GET_INSURANCE_PRODUCTS } from 'src/graphql/schema/query/GetInsuranceProducts';
import ConfigurationTab from 'src/components/InsuranceManager/tabs/ConfigurationTab';
import useTabParams from 'src/hooks/useTabParams';
import { InsuranceManagerTab } from 'src/types/tabs/InsuranceManagerTab';
import { InsuranceProvider } from '../../types/InsuranceProvider';
import InsuranceTypeMappingsTab from 'src/components/InsuranceManager/tabs/InsuranceTypeMappingsTab';
import './insurance-manager.scss';

const InsuranceManager = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useTabParams('tab', InsuranceManagerTab.PRODUCTS);

  return (
    <PageBase title={t('insuranceManager:title')} header={true}>
      <Tabs className="insurance-manager" tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('insuranceManager:insurance-products')}>
          <QueryWrapper
            query={GET_INSURANCE_PRODUCTS}
            // TODO: add real pageSize and pageNumber when possible
            options={{ variables: { pageNumber: undefined, pageSize: undefined } }}
          >
            {(page: InsuranceProductPage) => <InsuranceProductsTab page={page} />}
          </QueryWrapper>
        </Tab>
        <Tab title={t('insuranceManager:insurance-providers')}>
          <InsuranceProvidersTab />
        </Tab>
        <Tab title={t('insuranceManager:insurance-type-mappings')}>
          <InsuranceTypeMappingsTab />
        </Tab>
        <Tab title={t('insuranceManager:message-configurations')}>
          <QueryWrapper query={GET_INSURANCE_PROVIDERS} type="list" options={{ fetchPolicy: 'no-cache' }}>
            {(insuranceProviders: InsuranceProvider[]) =>
              insuranceProviders.length > 0 && <ConfigurationTab insuranceProviders={insuranceProviders} />
            }
          </QueryWrapper>
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default InsuranceManager;
