import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { Icon, Input } from 'fgirot-k2-ui-components';
import { SearchInvoicesRequest } from 'src/types/SearchInvoicesRequest';

interface InsuranceProvidersSearchInputProps {
  searchRequest: SearchInvoicesRequest;
  onSearch: (searchInput: string) => void;
  disabled?: boolean;
}

const InsuranceProvidersSearchInput = ({ onSearch, disabled, searchRequest }: InsuranceProvidersSearchInputProps) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(searchRequest.searchInput);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (searchRequest.searchInput !== event.target.value) {
      onSearch(searchInput);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchInput);
    } else if (e.key === 'Escape') {
      setSearchInput('');
    }
  };

  return (
    <Input.Text
      disabled={disabled}
      placeholder={t('economy:invoices-tab.filters.search-placeholder')}
      type="search"
      icon={<Icon type="Search" />}
      value={searchInput}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
    />
  );
};

export default InsuranceProvidersSearchInput;
