import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { noNavRoutes } from './Routes';

export const useShowNavElements = () => {
  const [result, setResult] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setResult(!noNavRoutes.includes(location.pathname));
  });
  return result;
};
