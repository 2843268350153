import { gql } from '@apollo/client';

export const SEARCH_REJECTED_INVOICES = gql`
  query InternalSearchRejectedInvoices($request: SearchRejectedInvoicesRequest) {
    internalSearchRejectedInvoices(request: $request) {
      paging {
        hasNext
        totalNumberOfElements
      }
      internalSearchRejectedInvoices {
        id
        employer {
          id
          legalName
        }
        fileName
        invoiceStatus
        paymentDueDate
        dueDate
        insuranceProvider {
          id
          legalName
          organisationNumber
        }
        totalAmount
        invoiceNumber
        invoiceTypeCode
        accountNumberTo
      }
    }
  }
`;
