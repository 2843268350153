import { InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceProduct } from 'src/types/InsuranceProduct';

interface AboutDrawerTabProps {
  insuranceProduct: InsuranceProduct;
}

const AboutDrawerTab: FC<AboutDrawerTabProps> = ({ insuranceProduct }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ListGroup className="insurance-product-drawer__list-group" variant={'inline-edit'}>
        <InlineEdit bold label={t('insuranceManager:insurance-product-drawer.set-status')}>
          <Typography variant="body2">{t(`insuranceManager:product-status.${insuranceProduct.status}`)}</Typography>
        </InlineEdit>
      </ListGroup>
      <ListGroup className="insurance-product-drawer__list-group" variant={'inline-edit'}>
        <InlineEdit bold label={t('insuranceManager:provider')}>
          <Typography variant="body2">{insuranceProduct.insuranceProvider.legalName}</Typography>
        </InlineEdit>
      </ListGroup>
    </div>
  );
};

export default AboutDrawerTab;
