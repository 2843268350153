import React, { FC, useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_PAYMENT_NOTES } from 'src/graphql/schema/query/GetPaymentNotes';
import { PaymentNote } from 'src/types/PaymentNote';
import { PaymentDrawerTab } from 'src/types/tabs/PaymentDrawerTab';
import PaymentDrawerOverview from './PaymentDrawerOverview';
import CreateNewPaymentNote from './PaymentDrawerOverview/CreatePaymentNote';
import PaymentNotesDetails from './PaymentNotesDetails';
import { Payment } from 'src/types/Payment';
import './payment-drawer.scss';

interface PaymentDrawerProps {
  open: boolean;
  onClose: () => void;
  payment: Payment;
}

const PaymentDrawer: FC<PaymentDrawerProps> = ({ open, onClose, payment }) => {
  const [currentTab, setCurrentTab] = useState<PaymentDrawerTab>(PaymentDrawerTab.PAYMENT_NOTE);
  const [addingNewPaymentNote, setAddingNewPaymentNote] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);

  const handleAddNewPaymentNote = () => {
    setAddingNewPaymentNote(true);
    setSelectedNoteId(undefined);
  };

  const handleSelectPaymentNote = (id: string) => {
    setSelectedNoteId(id);
    setAddingNewPaymentNote(false);
  };

  const handleBackToNotes = () => {
    setAddingNewPaymentNote(false);
    setSelectedNoteId(undefined);
  };

  const navigateToPaymentNotes = () => {
    setCurrentTab(PaymentDrawerTab.PAYMENT_NOTE);
    handleBackToNotes();
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose} data-testid="payment-drawer">
      <QueryWrapper query={GET_PAYMENT_NOTES} options={{ variables: { paymentId: payment?.id } }}>
        {(paymentNotes: PaymentNote[]) => (
          <>
            {!addingNewPaymentNote && !selectedNoteId && (
              <PaymentDrawerOverview
                payment={payment}
                paymentNotes={paymentNotes}
                onClose={onClose}
                onAddNewPaymentNote={handleAddNewPaymentNote}
                onSelectPaymentNote={handleSelectPaymentNote}
                currentTab={currentTab}
              />
            )}
            {selectedNoteId && (
              <PaymentNotesDetails
                payment={payment}
                paymentNote={paymentNotes.find((notes) => notes.paymentId === selectedNoteId)}
                onClose={onClose}
                onBackClick={navigateToPaymentNotes}
              />
            )}
            {addingNewPaymentNote && (
              <CreateNewPaymentNote payment={payment} onClose={onClose} onBackClick={navigateToPaymentNotes} />
            )}
          </>
        )}
      </QueryWrapper>
    </DrawerModal>
  );
};
export default PaymentDrawer;
