import { GET_INTERNAL_GROUP } from 'src/graphql/schema/query/GetInternalGroup';
import { useApolloMutation } from '../graphql/Mutation';
import { INTERNAL_ADD_CUSTOMER_TO_GROUP } from 'src/graphql/schema/mutation/internalGroup/InternalAddCustomerToGroup';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';

export const useInternalAddCustomerToGroup = () => {
  const apolloMutation = useApolloMutation();

  const internalAddCustomerToGroup = (groupId: string, customerId: string) =>
    apolloMutation(INTERNAL_ADD_CUSTOMER_TO_GROUP, '', { groupId, customerId }, [
      GET_INTERNAL_GROUP,
      GET_INTERNAL_GROUPS,
    ]);

  return internalAddCustomerToGroup;
};
