import { gql } from '@apollo/client';

export const CREATE_PAYMENT_NOTE = gql`
  mutation InternalCreatePaymentNote($paymentId: UUID!, $request: CreatePaymentNoteInput) {
    internalCreatePaymentNote(paymentId: $paymentId, request: $request) {
      paymentId
      note
      createdDate
    }
  }
`;
