import React, { FC } from 'react';
import { Button, Icon, ListGroup, Message } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import { useDeleteTemplateEmployerAssignment } from 'src/service/DocumentTemplateEmployerAssignment';
import './about-drawer-tab.scss';

interface AboutDrawerTabProps {
  assignment: DocumentTemplateEmployerAssignment;
  onClose: () => void;
}

const AboutDrawerTab: FC<AboutDrawerTabProps> = ({ assignment, onClose }) => {
  const { t } = useTranslation();

  const isSchedulingPresent = assignment.scheduledJobs.length > 0;
  const deleteTemplateEmployerAssignment = useDeleteTemplateEmployerAssignment();

  const handleDeleteTemplateEmployerAssignment = () => {
    deleteTemplateEmployerAssignment(assignment.id, assignment.employer.id).then(() => onClose());
  };
  return (
    <>
      <ListGroup variant="inline-edit" className="about-drawer-tab">
        <ListProperty
          label={t('documents:templates-tab.drawer.about-drawer-tab.template-name-label')}
          value={stringOrBlank(assignment.templateName)}
        />
        <ListProperty
          label={t('documents:templates-tab.drawer.about-drawer-tab.employer-label')}
          value={stringOrBlank(assignment.employer.legalName)}
        />
        <ListProperty
          label={t('documents:templates-tab.drawer.about-drawer-tab.format-type-label')}
          value={assignment.formatType ? stringOrBlank(t(`documents:format-type.${assignment.formatType}`)) : '-'}
        />
        <ListProperty
          label={t('documents:templates-tab.drawer.about-drawer-tab.scheduling-label')}
          value={t(`documents:scheduling.is-scheduling-present.${isSchedulingPresent}`)}
        />
      </ListGroup>
      <div className="about-drawer-tab__buttons">
      {isSchedulingPresent ? (
        <Message
          type="info"
          message={t('documents:templates-tab.drawer.about-drawer-tab.messsage-delete-document-template-assignment')}
        />
      ) : (
        <Button
          className="about-drawer-tab__button"
          icon={<Icon type="Trash" />}
          type="secondary"
          label={t('common:remove')}
          disabled={isSchedulingPresent}
          onClick={handleDeleteTemplateEmployerAssignment}
          data-cy="payment-transaction-details__delete-button"
        />
      )}
      </div>
    </>
  );
};

export default AboutDrawerTab;
