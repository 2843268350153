import { Icon, InlineEdit, ListGroup, Message, Option, Select, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDER_AVAILABLE_MESSAGE_FORMATS } from 'src/graphql/schema/query/GetInsuranceProviderAvailableMessageFormats';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { InsuranceProviderAvailableMessageFormats } from 'src/types/InsuranceProviderAvailableMessageFormats';
import './insurance-provider-message-tab.scss';

interface InsuranceProviderMessageTabProps {
  insuranceProvider: InsuranceProvider;
}

function InsuranceProviderMessageTab({ insuranceProvider }: InsuranceProviderMessageTabProps) {
  const { t } = useTranslation();
  return (
    <QueryWrapper
      query={GET_INSURANCE_PROVIDER_AVAILABLE_MESSAGE_FORMATS}
      options={{ variables: { insuranceProviderId: insuranceProvider.id } }}
    >
      {(providerAvailableMessageFormats: InsuranceProviderAvailableMessageFormats) => {
        const messageFormatOptions: Option<string>[] = providerAvailableMessageFormats.availableMessageFormats.map(
          (format) => {
            return {
              label: t(`insuranceManager:available-message-format.${format.messageFormat}`),
              value: format.messageFormat,
            };
          },
        );
        return (
          <div className="insurance-provider-message-tab">
            <Typography variant="subtitle" bold>
              {t('insuranceManager:insurance-provider-drawer.default-message-format')}
            </Typography>
            <ListGroup variant="inline-edit" className="insurance-provider-message-tab__list-group">
              <InlineEdit label={t('insuranceManager:insurance-provider-drawer.default')} bold>
                <div className="insurance-provider-message-tab__inline-edit-value">
                  {providerAvailableMessageFormats.defaultMessageFormat ? (
                    <Select
                      options={messageFormatOptions}
                      selected={messageFormatOptions.find(
                        (option) => option.value === providerAvailableMessageFormats.defaultMessageFormat,
                      )}
                      onChange={null}
                      disabled
                      small
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </InlineEdit>
            </ListGroup>
            <Typography variant="subtitle" bold>
              {t('insuranceManager:insurance-provider-drawer.available-message-formats')}
            </Typography>
            <ListGroup variant="inline-edit" className="insurance-provider-message-tab__list-group">
              {providerAvailableMessageFormats.availableMessageFormats.map((format) => (
                <InlineEdit label={t(`insuranceManager:available-message-format.${format.messageFormat}`)} bold>
                  <div className="insurance-provider-message-tab__inline-edit-value">
                    {providerAvailableMessageFormats.defaultMessageFormat === format.messageFormat && (
                      <Icon type="Check" stroke="#479890" />
                    )}
                  </div>
                </InlineEdit>
              ))}
            </ListGroup>
            <Message
              type="info"
              message={t('insuranceManager:insurance-provider-drawer.default-message-format-info')}
              className="insurance-provider-message-tab__info-message"
            />
          </div>
        );
      }}
    </QueryWrapper>
  );
}

export default InsuranceProviderMessageTab;
