import { Avatar } from 'fgirot-k2-ui-components';
import React from 'react';
import './td-full-name.scss';

interface TdFullnameProps {
  firstName: string;
  lastName: string;
  alert?: boolean;
}

const TdFullname = ({ firstName, lastName, alert }: TdFullnameProps) => {
  return (
    <div className="td-full-name">
      <Avatar className="td-full-name__avatar" alert={alert} alertBackgroundColor="#ffffff" />
      {`${firstName || '-'} ${lastName || '-'}`}
    </div>
  );
};

export default TdFullname;
