import React from 'react';
import { Tab, Tabs } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import PageBase from 'src/components/PageBase';
import useTabParams from 'src/hooks/useTabParams';
import { MonitoringTab } from 'src/types/tabs/MonitoringTab';
import TransactionAnalysisTab from 'src/components/Monitoring/TransactionAnalysisTab';
import './monitoring.scss';

const Monitoring = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useTabParams('tab', MonitoringTab.TRANSACTION_ANALYSIS);

  return (
    <PageBase title={t('monitoring:title')} header={true}>
      <Tabs className="monitoring" tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('monitoring:tabs.transaction-analysis')}>
          <TransactionAnalysisTab />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default Monitoring;
