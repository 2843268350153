import { useApolloMutation } from './graphql/Mutation';
import { RESEND_REPORTING_MESSAGE } from 'src/graphql/schema/mutation/ResendReportingMessage';

export const useResendReportingMessage = () => {
  const apolloMutation = useApolloMutation();

  const resendReportingMessage = (messageTransactionId: string, employerId: string, customerId: string) =>
    apolloMutation(RESEND_REPORTING_MESSAGE, employerId, { messageTransactionId }, null, customerId);

  return resendReportingMessage;
};
