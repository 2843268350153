import { gql } from '@apollo/client';

export const GET_INSURANCE_PROVIDER_AVAILABLE_MESSAGE_FORMATS = gql`
  query ($insuranceProviderId: UUID!) {
    insuranceProviderAvailableMessageFormats(insuranceProviderId: $insuranceProviderId) {
      insuranceProviderId
      defaultMessageFormat
      availableMessageFormats {
        id
        messageFormat
        messageFormatCategory
      }
    }
  }
`;
