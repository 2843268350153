import React, { useState } from 'react';
import { SearchPaymentsFilters, SearchPaymentsRequest } from 'src/types/SearchPaymentsRequest';
import { getInitialSearchPaymentsRequest } from './utils/paymentUtils';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SEARCH_PAYMENTS } from 'src/graphql/schema/query/SearchPayments';
import { SearchPaymentsPage } from 'src/types/Payment';
import Payments from './Payments';
import './payments-tab.scss';

const PaymentsTab = () => {
  const [searchPaymentsRequest, setSearchPaymentssRequest] = useState<SearchPaymentsRequest>(
    getInitialSearchPaymentsRequest(),
  );

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleTextSearch = (searchInput: string) => {
    setSearchPaymentssRequest({
      ...searchPaymentsRequest,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: SearchPaymentsFilters) => {
    setSearchPaymentssRequest({ ...searchPaymentsRequest, filters });
  };

  return (
    <QueryWrapper
      query={SEARCH_PAYMENTS}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: {
            searchInput: searchPaymentsRequest.searchInput,
            pageNumber: queryPageNumber,
            pageSize,
            filters: searchPaymentsRequest.filters,
          },
        },
      }}
    >
      {({ internalSearchPayments, paging }: SearchPaymentsPage) => (
        <Payments
          payments={internalSearchPayments}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          searchPaymentsRequest={searchPaymentsRequest}
          onTextSearch={handleTextSearch}
          onApplyFilters={handleApplyFilters}
          onPageChange={handleOnPageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default PaymentsTab;
