import Joi from 'joi';

export const datePickerSchema = Joi.object({
  employmentStartDate: Joi.date().iso(),
  fromDate: Joi.date().iso(),
  startDate: Joi.date().allow(null, '').iso(),
  endDate: Joi.date().allow(null, '').iso(),
  paymentDueDate: Joi.date().allow(null, '').iso(),
  createdDate: Joi.date().allow(null, '').iso(),
});
