import { Input, Icon, Select } from 'fgirot-k2-ui-components';
import React, { ChangeEvent } from 'react';
import './filter-component.scss';
import { useTranslation } from 'react-i18next';
import { FilterSelectProps } from './FilterSelectProps';

interface FilterComponentProps {
  searchPlaceholder?: string;
  searchFilter?: string;
  onSearchFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  selectProps: FilterSelectProps<unknown>[];
  showSearch?: boolean;
}

const FilterComponent = ({
  searchPlaceholder,
  searchFilter,
  onSearchFilterChange,
  selectProps,
  showSearch,
}: FilterComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="filter-component">
      {showSearch && (
        <Input.Text
          type="search"
          value={searchFilter}
          onChange={onSearchFilterChange}
          icon={<Icon type="Search" />}
          placeholder={searchPlaceholder}
          className="filter-component__search"
        />
      )}
      <div className="filter-component__dropdowns">
        {selectProps.map((props, index) => {
          return (
            <Select
              key={`filter-component-dropdown-${index}`}
              className="filter-component__dropdown"
              selectAll
              selectAllLabel={t('common:all')}
              multiple
              placeholder={props.placeholder}
              {...props}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilterComponent;
