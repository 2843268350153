import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import InsuranceProvidersTableRow from './InsuranceProvidersTableRow/InsuranceProvidersTableRow';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import './insurance-providers-table.scss';

interface InsuranceProvidersTableProps {
  insuranceProviders: InsuranceProvider[];
  onClick: (insuranceProviderId: string) => void;
}

const InsuranceProvidersTable: FC<InsuranceProvidersTableProps> = ({ insuranceProviders, onClick }) => {
  const { t } = useTranslation();

  const head = [
    { name: t('insuranceManager:insurance-provider-legal-name'), centered: false },
    { name: t('insuranceManager:organisation-number'), centered: false },
  ];

  return (
    <div className={['insurance-providers-table'].join(' ')}>
      <TableBase maxHeightPercentage={60}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th centered={title.centered} key={`InsuranceProvidersTable-${title.name}-${index}`}>
                {title.name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {insuranceProviders.map((provider) => (
            <InsuranceProvidersTableRow
              key={`InsuranceProvidersTableRow-${provider.id}`}
              insuranceProvider={provider}
              onClick={() => onClick(provider.id)}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default InsuranceProvidersTable;
