import { Accordion, IconPill, ListItem, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { ReportingRuleConfiguration } from 'src/types/ReportingRuleConfiguration';
import ConfigurationDetails from '../ConfigurationDetails';
import './configuration-list-item.scss';
import { InsuranceEventType } from '../../../../../types/ReportingRule';

interface ConfigurationListItemProps {
  insuranceProvider: InsuranceProvider;
  ruleConfig: ReportingRuleConfiguration;
  onSelectConfiguration: (ruleId: string, providerId: string) => void;
  onSelectRule: (
    insuranceEventType: InsuranceEventType,
    ruleId: string,
    configurationRuleId: string,
    providerId: string,
  ) => void;
}

const ConfigurationListItem: FC<ConfigurationListItemProps> = ({
  insuranceProvider,
  ruleConfig,
  onSelectConfiguration,
  onSelectRule,
}) => {
  const { t } = useTranslation();

  const ruleConfigurationName = ruleConfig.name;

  const handleOpenConfigurationDrawer = () => {
    onSelectConfiguration(ruleConfig.id, insuranceProvider.id);
  };

  const header = (
    <div className="configuration-list-item__header">
      <Typography variant="subtitle" bold className="configuration-list-item__header-title">
        {ruleConfigurationName}
      </Typography>
      <IconPill label={insuranceProvider.legalName} />
      <IconPill label={insuranceProvider.organisationNumber} />
    </div>
  );

  return (
    <ListItem
      onClick={handleOpenConfigurationDrawer}
      className="configuration-tab"
      buttonLabel={t('insuranceManager:navigate-to-detail-page')}
      header={header}
    >
      <Accordion header={<Typography variant="body2">{t('common:details')}</Typography>}>
        <ConfigurationDetails
          ruleConfig={ruleConfig}
          onSelectRule={onSelectRule}
          insuranceProviderId={insuranceProvider.id}
        />
      </Accordion>
    </ListItem>
  );
};

export default ConfigurationListItem;
