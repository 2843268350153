import { split, HttpLink, ApolloClient, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode, FragmentDefinitionNode } from 'graphql';
import { WebSocketLink } from '@apollo/client/link/ws';
import KeycloakInstance from 'src/keycloak';
import cache from './cache';

const wsOptions: WebSocketLink.Configuration = {
  uri: `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:${
    window.location.port
  }/subscriptions`,
  options: { reconnect: true, lazy: true },
};
const webSocketLink = new WebSocketLink(wsOptions);

const authLink = new ApolloLink((operation, forward) => {
  const token = KeycloakInstance.token;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'employer-id': operation.getContext().employerId ? operation.getContext().employerId : '',
      'customer-id': operation.getContext().customerId ? operation.getContext().customerId : '',
    },
  });
  return forward(operation);
});

export const externalHttpLink = new HttpLink({
  uri: '/graphql',
});
export const internalHttpLink = new HttpLink({
  uri: '/internal/graphql',
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  webSocketLink,
  externalHttpLink,
);
const splitLinkHttp = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    const queryName = getQueryName(definition);
    return queryName?.startsWith('internal');
  },
  internalHttpLink,
  splitLink,
);

export const client = new ApolloClient({
  link: authLink.concat(splitLinkHttp),
  cache: cache,
});

const getQueryName = (definition: OperationDefinitionNode | FragmentDefinitionNode) => {
  return definition?.selectionSet?.selections[0]?.['name']?.value;
};
