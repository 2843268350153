import { Accordion, ListItem, Pagination, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleCardBox from 'src/components/Common/ModuleCardBox';
import AccountingBalanceDetails from './AccountingBalanceDetails';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Paging } from 'src/types/PageableResponse';
import { PeriodAggregationBalance } from 'src/types/PeriodAggregation';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import './accounting-balance-list-item.scss';

interface AccountingBalanceListItemProps {
  employerLegalName: string;
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  groupedPeriodAggregationsByEmployer: PeriodAggregationBalance[];
  onPageChange: (nextPageNumber: number) => void;
}

const AccountingBalanceListItem: FC<AccountingBalanceListItemProps> = ({
  employerLegalName,
  paging,
  pageNumber,
  pageSize,
  groupedPeriodAggregationsByEmployer,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
  const showPagination = totalPages > 1;

  const groupedInvoicesByEmployer = groupedPeriodAggregationsByEmployer.flatMap(
    (employer) => employer.invoiceAggregations,
  );

  const totalBalanceByEmployer = groupedInvoicesByEmployer.reduce((acc, curr) => acc + curr.balance, 0);

  const header = (
    <div className="accounting-balance-list-item__header">
      <ModuleCardBox className="accounting-balance-list-item__employer" value={employerLegalName} />
      <div className="accounting-balance-list-item__right-header-content">
        <Pill
          size="large"
          type="heads-up"
          label={
            `${groupedInvoicesByEmployer.length} ` +
            t('economy:accounting-balance-tab.accounting-balance-details.imbalance-pill')
          }
        />
        <Typography bold variant="body2">
          {formatSweAmountText(`${totalBalanceByEmployer}`)}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <div className="accounting-balance-list-item">
        <ListItem header={header} data-testid={`accounting-balance-list-item-${employerLegalName}`}>
          <Accordion
            data-testid={`accunting-balance-accordion-${employerLegalName}`}
            header={<Typography variant="body2">{t('economy:accounting-balance-tab.accordion-title')}</Typography>}
          >
            <AccountingBalanceDetails groupedInvoicesByEmployer={groupedInvoicesByEmployer} />
          </Accordion>
        </ListItem>
      </div>
      {showPagination && (
        <div>
          <Pagination
            currentPage={pageNumber}
            onPageChange={(nextPageNumber) => onPageChange(nextPageNumber)}
            totalPages={totalPages}
          />
        </div>
      )}
    </>
  );
};
export default AccountingBalanceListItem;
