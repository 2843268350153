import { useApolloMutation } from '../graphql/Mutation';
import { CreateInternalUser } from 'src/types/InternalUser';
import { INTERNAL_CREATE_USER } from 'src/graphql/schema/mutation/user/InternalCreateUser';
import { GET_INTERNAL_USERS } from 'src/graphql/schema/query/GetInternalUsers';

export const useInternalCreateUser = () => {
  const apolloMutation = useApolloMutation();

  const internalCreateUser = (request: CreateInternalUser) =>
    apolloMutation(INTERNAL_CREATE_USER, '', { request }, [GET_INTERNAL_USERS]);

  return internalCreateUser;
};
