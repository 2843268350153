import { client } from 'src/graphql';
import { UPDATE_REPORTING_RULE } from 'src/graphql/schema/mutation/UpdateReportingRule';
import { GET_REPORTING_RULE } from 'src/graphql/schema/query/GetReportingRule';
import { SEARCH_REPORTING_RULE_CONFIGURATIONS } from 'src/graphql/schema/query/SearchReportingRuleConfigurations';
import { ReportingRuleRequest } from 'src/types/request/ReportingRuleRequest';

export const updateReportingRule = (ruleId: string, request: ReportingRuleRequest) =>
  client
    .mutate({
      mutation: UPDATE_REPORTING_RULE,
      variables: {
        ruleId,
        request,
      },
    })
    .then(() => {
      client.refetchQueries({
        include: [GET_REPORTING_RULE, SEARCH_REPORTING_RULE_CONFIGURATIONS],
      });
    });
