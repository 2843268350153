import { INTERNAL_CREATE_GROUP } from 'src/graphql/schema/mutation/internalGroup/CreateInternalGroup';
import { CreateGroupInput } from 'src/types/Group';
import { useApolloMutation } from 'src/service/graphql/Mutation';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';

export const useInternalCreateGroup = () => {
  const apolloMutation = useApolloMutation();

  const internalCreateGroup = (request: CreateGroupInput) =>
    apolloMutation(INTERNAL_CREATE_GROUP, '', { request }, [GET_INTERNAL_GROUPS]);
  return internalCreateGroup;
};
