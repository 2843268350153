import React from 'react';
import { useTranslation } from 'react-i18next';
import PageBase from 'src/components/PageBase';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/customer/GetCustomers';
import { GetCustomerPage } from 'src/types/Customer';
import DocumentsOverview from './DocumentsOverview';
import './documents.scss';

const Documents = () => {
  const { t } = useTranslation();

  return (
    <PageBase title={t('documents:title')} header={true}>
      <QueryWrapper query={GET_ALL_CUSTOMERS}>
        {({ customers }: GetCustomerPage) => <DocumentsOverview customers={customers} />}
      </QueryWrapper>
    </PageBase>
  );
};

export default Documents;
