import { gql } from '@apollo/client';

export const INTERNAL_REMOVE_CUSTOMER_FROM_GROUP = gql`
  mutation InternalRemoveCustomerFromGroup($groupId: UUID!, $customerId: UUID!) {
    internalRemoveCustomerFromGroup(groupId: $groupId, customerId: $customerId) {
      groupId
      customerId
    }
  }
`;
