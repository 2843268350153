import { DrawerNavigation, InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import './groups-drawer-users-tab.scss';
import { useTranslation } from 'react-i18next';
import { GroupUser } from 'src/types/Group';

interface GroupsDrawerUsersTab {
  onAddUserClick: () => void;
  users: GroupUser[];
}
const GroupsDrawerUsersTab: React.FunctionComponent<GroupsDrawerUsersTab> = ({ users, onAddUserClick }) => {
  const { t } = useTranslation();
  return (
    <>
      <ListGroup variant={'inline-edit'} className="groups-drawer-users-tab">
        <DrawerNavigation
          className="groups-drawer-users-tab__navigation"
          label={t('permission:internal.groups-drawer.users.add-new-user')}
          onClick={onAddUserClick}
        />
      </ListGroup>
      <ListGroup variant={'inline-edit'}>
        {users.map((user) => (
          <InlineEdit key={user.id} label={user.name} alignRight bold />
        ))}
      </ListGroup>
    </>
  );
};

export default GroupsDrawerUsersTab;
