import { INTERNAL_REMOVE_ROLE_FROM_GROUP } from 'src/graphql/schema/mutation/internalGroup/InternalRemoveRoleFromGroup';
import { GET_INTERNAL_GROUP } from 'src/graphql/schema/query/GetInternalGroup';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';
import { useApolloMutation } from 'src/service/graphql/Mutation';

export const useInternalRemoveRoleFromGroup = () => {
  const apolloMutation = useApolloMutation(false);

  const internalRemoveRoleFromGroup = (groupId: string, roleId: string) =>
    apolloMutation(INTERNAL_REMOVE_ROLE_FROM_GROUP, '', { groupId, roleId }, [GET_INTERNAL_GROUP, GET_INTERNAL_GROUPS]);

  return internalRemoveRoleFromGroup;
};
