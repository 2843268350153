import { DrawerNavigation, InlineEdit, ListGroup, Button } from 'fgirot-k2-ui-components';
import './groups-drawer-customers-tab.scss';
import { useTranslation } from 'react-i18next';
import { Customer } from 'src/types/Customer';
import { useInternalRemoveCustomerFromGroup } from 'src/service/internalGroup/InternalRemoveCustomerFromGroup';

interface GroupsDrawerCustomersTabProps {
  onAddCustomerClick: () => void;
  groupCustomers: Customer[];
  groupId: string;
}
const GroupsDrawerCustomersTab: React.FunctionComponent<GroupsDrawerCustomersTabProps> = ({
  onAddCustomerClick,
  groupCustomers,
  groupId,
}) => {
  const { t } = useTranslation();
  const removeCustomerFromInternalGroup = useInternalRemoveCustomerFromGroup();
  const removeCustomer = (customer: Customer) => {
    removeCustomerFromInternalGroup(groupId, customer.id);
  };

  return (
    <div>
      <ListGroup variant={'inline-edit'} className="groups-drawer-customers-tab">
        <DrawerNavigation
          className="groups-drawer-customers-tab__navigation"
          label={t('permission:internal.groups-drawer.customers.add-new-customer')}
          onClick={onAddCustomerClick}
        />
      </ListGroup>
      <ListGroup variant={'inline-edit'}>
        {groupCustomers.map((customer) => (
          <InlineEdit bold key={customer.id} label={customer.name} alignRight>
            <Button label={t('common:remove')} type="secondary" onClick={() => removeCustomer(customer)} />
          </InlineEdit>
        ))}
      </ListGroup>
    </div>
  );
};

export default GroupsDrawerCustomersTab;
