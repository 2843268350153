import { CREATE_PAYMENT_MESSAGES } from 'src/graphql/schema/mutation/CreatePaymentMessages';
import { CreatePaymentMessagesInput } from 'src/types/request/CreatePaymentMessagesInput';
import { useApolloMutation } from './graphql/Mutation';
import { SEARCH_INVOICES } from 'src/graphql/schema/query/SearchInvoices';

export const useCreatePaymentMessages = () => {
  const apolloMutation = useApolloMutation();

  const createPaymentMessages = (request: CreatePaymentMessagesInput, employerId: string, customerId: string) =>
    apolloMutation(CREATE_PAYMENT_MESSAGES, employerId, { request }, [SEARCH_INVOICES], customerId);

  return createPaymentMessages;
};
