import { gql } from '@apollo/client';

export const GET_REPORTING_RULE_CONFIGURATION = gql`
  query ($reportingRuleConfigurationId: UUID!) {
    reportingRuleConfiguration(reportingRuleConfigurationId: $reportingRuleConfigurationId) {
      id
      name
      description
      insuranceProviderId
      reportingRuleGroups {
        reportingType
        rules {
          id
          insuranceEventType
          availableMessageFormat
          messageFormatCategory
        }
      }
    }
  }
`;
