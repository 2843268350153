import React, { FC, useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { Banner, Button, Icon } from 'fgirot-k2-ui-components';
import TemplatesTable from './TemplatesTable';
import AddTemplateEmployerAssignmentModal from './AddTemplateEmployerAssignmentModal';
import useModal from 'src/util/Hooks/ModalHook';
import { useTranslation } from 'react-i18next';
import { GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS } from 'src/graphql/schema/query/documenttemplate/GetDocumentTemplateEmployerAssignments';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import { Employer } from 'src/types/Employer';
import './templates-tab.scss';
import TemplateDrawer from './TemplateDrawer';

interface TemplatesTabProps {
  customerId: string;
  employer: Employer;
}

const TemplatesTab: FC<TemplatesTabProps> = ({ customerId, employer }) => {
  const { t } = useTranslation();

  const { modalOpen, setModalOpen, closeModal } = useModal();

  const [selectedAssignmentId, setSelectedAssignmentId] = useState<string>();

  const handleOpenDrawer = (id: string) => {
    setSelectedAssignmentId(id);
  };

  return (
    <>
      {(!customerId || !employer.id) && (
        <Banner className="templates-tab__banner" type="announcement" message={'Välj kund och bolag'} />
      )}

      {employer.id && (
        <QueryWrapper
          query={GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS}
          options={{ variables: { employerId: employer.id } }}
        >
          {(documentTemplateEmployerAssignments: DocumentTemplateEmployerAssignment[]) => {
            return (
              <>
                <div className="templates-tab">
                  <TemplatesTable assignments={documentTemplateEmployerAssignments} onClick={handleOpenDrawer} />

                  {selectedAssignmentId !== undefined && (
                    <TemplateDrawer
                      open={selectedAssignmentId !== undefined}
                      onClose={() => setSelectedAssignmentId(undefined)}
                      assignment={documentTemplateEmployerAssignments.find(
                        (assignment) => assignment.id === selectedAssignmentId,
                      )}
                    />
                  )}
                </div>
                <AddTemplateEmployerAssignmentModal
                  open={modalOpen}
                  employerAssignments={documentTemplateEmployerAssignments}
                  employerId={employer.id}
                  customerId={customerId}
                  onClose={closeModal}
                />
                <div className="templates-tab__add-template-modal-button" data-testid="add-template-modal-button">
                  <Button
                    type="floating-action-button"
                    icon={<Icon type={'PlusLarge'} />}
                    label={t('documents:templates-tab:add-template-button-label')}
                    onClick={setModalOpen}
                    disabled={!employer.id}
                  />
                </div>
              </>
            );
          }}
        </QueryWrapper>
      )}
    </>
  );
};

export default TemplatesTab;
