import { SortDirection, TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { sortAlphabeticalAsc, sortAlphabeticalDesc } from 'src/util/SortUtil';
import InsuranceTypeMappingTableRow from './InsuranceTypeMappingTableRow';
import { InsuranceTypeMapping } from 'src/types/InsuranceTypeMapping';
import InsuranceTypeMappingDrawer from '../InsuranceTypeMappingDrawer';

interface InsuranceTypeMappingsTableProps {
  insuranceTypeMappings: InsuranceTypeMapping[];
}

const InsuranceTypeMappingsTable: FC<InsuranceTypeMappingsTableProps> = ({ insuranceTypeMappings }) => {
  const [selectedMappingId, setSelectedMappingId] = useState<string>(undefined);

  const handleOpenDrawer = (id: string) => {
    setSelectedMappingId(id);
  };

  const handleCloseDrawer = () => {
    setSelectedMappingId(undefined);
  };

  const [sortDirection, setSortDirection] = useState<SortDirection>('DOWN');
  const [sortField, setSortField] = useState<
    | 'insuranceProvider'
    | 'insuranceTypeName'
    | 'invoiceRowName'
    | 'benefitType'
    | 'insuranceCategory'
    | 'taxRule'
    | 'premiumSource'
  >('insuranceProvider');

  const tableHeads = [
    {
      title: t('insuranceManager:insurance-type-mappings-tab.table.head.insurance-provider'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceProvider');
      },
      fieldName: 'insuranceProvider',
    },
    {
      title: t('insuranceManager:insurance-type-mappings-tab.table.head.insurance-type-name'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceTypeName');
      },
      fieldName: 'insuranceTypeName',
    },
    {
      title: t('insuranceManager:insurance-type-mappings-tab.table.head.invoice-row-name'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('invoiceRowName');
      },
      fieldName: 'invoiceRowName',
    },
    {
      title: t('insuranceManager:insurance-type-mappings-tab.table.head.benefit-type'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('benefitType');
      },
      fieldName: 'benefitType',
    },
    {
      title: t('insuranceManager:insurance-type-mappings-tab.table.head.insurance-category'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('insuranceCategory');
      },
      fieldName: 'insuranceCategory',
    },
    {
      title: t('insuranceManager:insurance-type-mappings-tab.table.head.tax-rule'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('taxRule');
      },
      fieldName: 'taxRule',
    },
    {
      title: t('insuranceManager:insurance-type-mappings-tab.table.head.premium-source'),
      sort: (direction: SortDirection) => {
        setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
        setSortField('premiumSource');
      },
      fieldName: 'premiumSource',
    },
  ];

  const sortInsuranceTypeMappings = (a: InsuranceTypeMapping, b: InsuranceTypeMapping) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    switch (sortField) {
      case 'insuranceProvider':
        return sortAlphabetical(a.insuranceProvider.legalName, b.insuranceProvider.legalName);
      case 'insuranceTypeName':
        return sortAlphabetical(a.name, b.name);
      case 'benefitType':
        return sortAlphabetical(a.benefitType, b.benefitType);
      case 'insuranceCategory':
        return sortAlphabetical(a.insuranceCategory, b.insuranceCategory);
      case 'taxRule':
        return sortAlphabetical(a.taxRule, b.taxRule);
      case 'premiumSource':
        return sortAlphabetical(a.premiumSource, b.premiumSource);
      default:
        return undefined;
    }
  };

  return (
    <>
      <TableBase>
        <TableHead>
          <TableRow>
            {tableHeads.map((head) => (
              <Th
                includeSortButtons={head?.sort !== undefined}
                handleSortDirectionChange={head.sort}
                key={`InsuranceTypeMappingsTableHead-${head.title}`}
                sortDirection={head.fieldName === sortField ? sortDirection : null}
              >
                {head.title}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {insuranceTypeMappings.sort(sortInsuranceTypeMappings).map((insuranceType) => {
            return (
              <InsuranceTypeMappingTableRow
                key={`InsuranceTypeMappingTableRow-${insuranceType.id}`}
                insuranceTypeMapping={insuranceType}
                onRowClick={handleOpenDrawer}
              />
            );
          })}
        </TableBody>
      </TableBase>
      {selectedMappingId !== undefined && (
        <InsuranceTypeMappingDrawer
          open={!!selectedMappingId}
          onClose={handleCloseDrawer}
          insuranceTypeMapping={insuranceTypeMappings.find((mapping) => mapping.id === selectedMappingId)}
        />
      )}
    </>
  );
};
export default InsuranceTypeMappingsTable;
