import { DrawerNavigation, InlineEdit, ListGroup, Message, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import ScrollBase from 'src/components/Common/ScrollBase';
import { GET_REPORTING_RULE_CONFIGURATION } from 'src/graphql/schema/query/GetReportingRuleConfiguration';
import { InsuranceEventType } from 'src/types/ReportingRule';
import { ReportingRuleConfiguration } from 'src/types/ReportingRuleConfiguration';
import './messages-tab.scss';
import { InsuranceProviderAvailableMessageFormats } from 'src/types/InsuranceProviderAvailableMessageFormats';
import { GET_INSURANCE_PROVIDER_AVAILABLE_MESSAGE_FORMATS } from 'src/graphql/schema/query/GetInsuranceProviderAvailableMessageFormats';

interface MessagesTabProps {
  ruleConfigId: string;
  insuranceProviderId: string;
  onSelectRule: (
    insuranceEventType: InsuranceEventType,
    ruleId: string,
    configurationRuleId: string,
    providerId: string,
  ) => void;
}

const MessagesTab: React.FC<MessagesTabProps> = ({ ruleConfigId, onSelectRule, insuranceProviderId }) => {
  const { t } = useTranslation();

  const handleShowReportingRuleDetails = (insuranceEventType: InsuranceEventType, ruleId: string) =>
    onSelectRule(insuranceEventType, ruleId, ruleConfigId, insuranceProviderId);
  return (
    <div className="messages-tab">
      <ScrollBase maxHeightPercentage={70}>
        <QueryWrapper
          query={GET_REPORTING_RULE_CONFIGURATION}
          options={{ variables: { reportingRuleConfigurationId: ruleConfigId } }}
        >
          {(reportingRuleConfiguration: ReportingRuleConfiguration) => (
            <>
              <QueryWrapper
                query={GET_INSURANCE_PROVIDER_AVAILABLE_MESSAGE_FORMATS}
                options={{ variables: { insuranceProviderId: reportingRuleConfiguration.insuranceProviderId } }}
              >
                {(providerAvailableMessageFormats: InsuranceProviderAvailableMessageFormats) => (
                  <>
                    <Typography variant="subtitle" bold>
                      {t('insuranceManager:insurance-provider-drawer.default-message-format')}
                    </Typography>
                    <ListGroup variant="inline-edit" className="insurance-provider-message-tab__list-group">
                      <InlineEdit label={t('insuranceManager:insurance-provider-drawer.standard')} bold>
                        <Typography variant="body2">
                          {providerAvailableMessageFormats.defaultMessageFormat
                            ? t(
                                `insuranceManager:available-message-format.${providerAvailableMessageFormats.defaultMessageFormat}`,
                              )
                            : '-'}
                        </Typography>
                      </InlineEdit>
                    </ListGroup>
                  </>
                )}
              </QueryWrapper>
              <Message
                type="info"
                className="messages-tab__info-text"
                message={t('insuranceManager:message-configuration-drawer.info')}
              />
              {reportingRuleConfiguration.reportingRuleGroups.map((ruleGroup) => {
                return (
                  <>
                    <Typography variant="body1" component="h3" bold>
                      {t(`insuranceManager:reporting-type.${ruleGroup.reportingType}`)}
                    </Typography>
                    <ListGroup className="messages-tab__rule-group" variant="drawer-navigation">
                      {ruleGroup.rules.map((rule, index) => {
                        const handleOnClick = () => handleShowReportingRuleDetails(rule.insuranceEventType, rule.id);
                        const availableMessageFormat = rule.availableMessageFormat
                          ? t(`insuranceManager:available-message-format.${rule.availableMessageFormat}`)
                          : t('common:default');
                        return (
                          <DrawerNavigation
                            key={`message-drawer-tab-${rule.insuranceEventType}-${index}`}
                            onClick={handleOnClick}
                            label={t(`insuranceManager:insurance-event-type.${rule.insuranceEventType}`)}
                            value={rule.id === null ? t('common:off') : `${availableMessageFormat}`}
                          />
                        );
                      })}
                    </ListGroup>
                  </>
                );
              })}
            </>
          )}
        </QueryWrapper>
      </ScrollBase>
    </div>
  );
};

export default MessagesTab;
