import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
  query customer($customerId: UUID!) {
    customer(customerId: $customerId) {
      id
      name
    }
  }
`;
