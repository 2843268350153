import React, { FC, useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { InsuranceTypeMapping } from 'src/types/InsuranceTypeMapping';
import { InsuranceTypeMappingDrawerTab } from 'src/types/tabs/InvoiceRowMappingsDrawerTab';
import InsuranceTypeMappingDrawerOverview from './InsuranceTypeMappingDrawerOverview';
import './insurance-type-mapping-drawer.scss';

interface InsuranceTypeMappingDrawerProps {
  open: boolean;
  onClose: () => void;
  insuranceTypeMapping: InsuranceTypeMapping;
}

const InsuranceTypeMappingDrawer: FC<InsuranceTypeMappingDrawerProps> = ({ open, onClose, insuranceTypeMapping }) => {
  const [currentTab] = useState<InsuranceTypeMappingDrawerTab>(InsuranceTypeMappingDrawerTab.INVOICE_ROW_MAPPINGS);

  return (
    <DrawerModal
      overlayBackground
      overlayOnClose
      open={open}
      onClose={onClose}
      data-testid="insurance-type-mapping-drawer"
    >
      <InsuranceTypeMappingDrawerOverview
        insuranceTypeMapping={insuranceTypeMapping}
        onClose={onClose}
        currentTab={currentTab}
      />
    </DrawerModal>
  );
};
export default InsuranceTypeMappingDrawer;
