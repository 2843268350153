import React from 'react';
import { Button, Icon, ListGroup } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { MessageMetadata } from 'src/types/MessageMetadata';
import ListProperty from 'src/components/Common/ListProperty';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import { useResendReportingMessage } from 'src/service/ResendReportingMessage';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import './message-drawer-tab.scss';

interface MessageDrawerTabProps {
  onClose: () => void;
  message: MessageMetadata;
}

const MessageDrawerTab = ({ onClose, message }: MessageDrawerTabProps) => {
  const { t } = useTranslation();
  const resendReportingMessage = useResendReportingMessage();

  const handleResendReportingMessage = (id: string) => {
    resendReportingMessage(id, message.employer.id, message.customerId);
    onClose();
  };

  return (
    <>
      <ListGroup variant="inline-edit" className="message-drawer-tab">
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.message-label')}
          value={stringOrBlank(message.messageFileName)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.message-reference-label')}
          value={stringOrBlank(message.messageFileReference)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.receipt-label')}
          value={stringOrBlank(message.receiptFileName)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.receipt-reference-label')}
          value={stringOrBlank(message.receiptFileReference)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.response-label')}
          value={stringOrBlank(message.responseFileName)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.response-reference-label')}
          value={stringOrBlank(message.responseFileReference)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.insurance-provider-label')}
          value={stringOrBlank(message.insuranceProvider.legalName)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.employer-label')}
          value={stringOrBlank(message.employer.legalName)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.wage-file-period-label')}
          value={usePeriodText(message.wageFilePeriod) ?? '-'}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.message-status-label')}
          value={message.messageStatus ? t(`reporting:message-status.${message.messageStatus}`) : '-'}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.transfer-channel-label')}
          value={stringOrBlank(message.transferChannel)}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.sent-at-label')}
          value={message.messageSentAt ? stringOrBlank(message.messageSentAt.toString().substring(0, 10)) : '-'}
        />
        <ListProperty
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.available-message-format-label')}
          value={
            message.availableMessageFormat
              ? t(`reporting:available-message-format.${message.availableMessageFormat}`)
              : '-'
          }
        />
      </ListGroup>
      <div className="message-drawer-tab__buttons">
        <Button
          className="message-drawer-tab__button"
          type="primary"
          label={t('monitoring:transaction-analysis-tab.drawer.message-drawer-tab.button-label')}
          icon={<Icon type="Settings" size="medium" />}
          onClick={() => handleResendReportingMessage(message.messageTransactionId)}
        />
      </div>
    </>
  );
};

export default MessageDrawerTab;
