import { gql } from '@apollo/client';

export const ADD_INTERNAL_ROLE_TO_INTERNAL_GROUP = gql`
  mutation InternalAddRoleToGroup($groupId: UUID!, $roleId: UUID!) {
    internalAddRoleToGroup(groupId: $groupId, roleId: $roleId) {
      groupId
      roleId
    }
  }
`;
