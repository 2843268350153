import React, { FC, useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { RejectedInvoiceDrawerTab } from 'src/types/tabs/RejectedInvoiceDrawerTab';
import RejectedInvoiceDrawerOverview from './RejectedInvoiceDrawerOverview';
import { GET_REJECTION_DETAILS } from 'src/graphql/schema/query/GetRejectionDetails';
import { RejectionDetail } from 'src/types/RejectionDetail';
import { Invoice } from 'src/types/Invoice';
import RejectionDetails from './RejectionDetails';
import './rejected-invoice-drawer.scss';

interface RejectedInvoiceDrawerProps {
  invoice: Invoice;
  open: boolean;
  onClose: () => void;
}

const RejectedInvoiceDrawer: FC<RejectedInvoiceDrawerProps> = ({ invoice, open, onClose }) => {
  const [currentTab, setCurrentTab] = useState<RejectedInvoiceDrawerTab>(RejectedInvoiceDrawerTab.REJECTION_DETAILS);
  const [selectedRejectionDetailId, setSelectedRejectionDetailId] = useState<string | null>(null);

  const handleSelectRejectionDetail = (id: string) => {
    setSelectedRejectionDetailId(id);
  };

  const handleBackToRejectionDetails = () => {
    setSelectedRejectionDetailId(undefined);
  };

  const navigateToRejectionDetails = () => {
    setCurrentTab(RejectedInvoiceDrawerTab.REJECTION_DETAILS);
    handleBackToRejectionDetails();
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose} data-testid="rejected-invoice-drawer">
      <QueryWrapper query={GET_REJECTION_DETAILS} type="list" options={{ variables: { invoiceHeaderId: invoice?.id } }}>
        {(rejectionDetails: RejectionDetail[]) => (
          <>
            {!selectedRejectionDetailId && (
              <RejectedInvoiceDrawerOverview
                rejectionDetails={rejectionDetails}
                onClose={onClose}
                onSelectRejectionDetail={handleSelectRejectionDetail}
                currentTab={currentTab}
                invoice={invoice}
              />
            )}
            {selectedRejectionDetailId && (
              <RejectionDetails
                invoice={invoice}
                rejectionDetail={rejectionDetails.find((detail) => detail.id === selectedRejectionDetailId)}
                onClose={onClose}
                onBackClick={navigateToRejectionDetails}
              />
            )}
          </>
        )}
      </QueryWrapper>
    </DrawerModal>
  );
};
export default RejectedInvoiceDrawer;
