import { InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/Invoice';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import './invoices-calculated-amount.scss';

interface InvoicesCalculatedAmountProps {
  invoices: Invoice[];
}

const InvoicesCalculatedAmount: FC<InvoicesCalculatedAmountProps> = ({ invoices }) => {
  const { t } = useTranslation();

  return (
    <div className="invoices-calculated-amount">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('economy:invoices-tab.total-amount-label')} bold>
          <Typography component="span" variant="body2">
            {formatSweAmountText(invoices.reduce((acc, curr) => acc + curr.remainingAmount, 0).toString())}
          </Typography>
        </InlineEdit>
      </ListGroup>
    </div>
  );
};

export default InvoicesCalculatedAmount;
