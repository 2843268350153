import { gql } from '@apollo/client';

export const UPDATE_REPORTING_RULE = gql`
  mutation ($ruleId: UUID!, $request: ReportingRuleRequest!) {
    updateReportingRule(ruleId: $ruleId, request: $request) {
      id
      insuranceEventType
      availableMessageFormat
    }
  }
`;
