import { gql } from '@apollo/client';

export const SEARCH_INVOICES = gql`
  query InternalSearchInvoices($request: SearchInvoicesRequest) {
    internalSearchInvoices(request: $request) {
      paging {
        hasNext
        totalNumberOfElements
      }
      internalSearchInvoices {
        id
        customerId
        employer {
          id
          legalName
        }
        invoiceStatus
        paymentDueDate
        dueDate
        insuranceProvider {
          id
          legalName
          organisationNumber
        }
        totalAmount
        invoiceNumber
        note
        accountNumberTo
        ocrNumber
        remainingAmount
        account {
          paymentServiceProvider
          bank
          accountNumber
        }
      }
    }
  }
`;
