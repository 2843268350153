import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { GetGroup } from 'src/types/Group';
import { GroupsDrawerTab } from 'src/types/tabs/GroupsDrawerTab';
import AddCustomerToGroup from './AddCustomerToGroup/AddCustomerToGroup';
import GroupsDrawerCustomersTab from './GroupsDrawerCustomersTab';
import GroupsDrawerUsersTab from './GroupsDrawerUsersTab';
import './groups-drawer.scss';
import AddUserToGroup from './AddUserToGroup';
import GroupsDrawerRolesTab from './GroupsDrawerRolesTab';
import { Role } from 'src/types/Role';
import { InternalUser } from 'src/types/InternalUser';
import { Customer } from 'src/types/Customer';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';

interface GroupsDrawerProps {
  open: boolean;
  group: GetGroup;
  roles: Role[];
  allUsers: InternalUser[];
  onClose: () => void;
  allCustomers: Customer[];
}

const GroupsDrawer: React.FC<GroupsDrawerProps> = ({ open, group, roles, onClose, allUsers, allCustomers }) => {
  const { t } = useTranslation();
  const isFGAdminGroup = group.admin;
  const [currentTab, setCurrentTab] = useState<GroupsDrawerTab>(
    isFGAdminGroup ? GroupsDrawerTab.USERS : GroupsDrawerTab.CUSTOMERS,
  );
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const breadcrumbs = [t('permission:breadcrumbs.internal-permission'), t('permission:breadcrumbs.groups')];

  const handleOnAddCustomerClick = () => {
    setShowAddCustomer(true);
  };

  const handleCloseAddCustomerToGroup = () => {
    setShowAddCustomer(false);
  };

  const handleOnAddUserClick = () => {
    setShowAddUser(true);
  };

  const handleCloseAddUserToGroup = () => {
    setShowAddUser(false);
  };

  const showDrawerBase = !showAddCustomer && !showAddUser;
  return (
    <div className="groups-drawer">
      <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
        <>
          {showDrawerBase && (
            <DrawerBase
              onClose={onClose}
              title={
                <>
                  <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
                  <Typography variant="h3" component="h3" bold className="internal-users-drawer__title">
                    {group.name}
                  </Typography>
                </>
              }
            >
              <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
                {!isFGAdminGroup ? (
                  <Tab title={`${t('permission:internal.groups-drawer.tabs.customers-label')}`}>
                    <GroupsDrawerCustomersTab
                      groupCustomers={group.customers}
                      onAddCustomerClick={handleOnAddCustomerClick}
                      groupId={group.id}
                    />
                  </Tab>
                ) : (
                  <></>
                )}
                <Tab title={`${t('permission:internal.groups-drawer.tabs.users-label')}`}>
                  <GroupsDrawerUsersTab users={group.users} onAddUserClick={handleOnAddUserClick} />
                </Tab>
                <Tab title={`${t('permission:internal.groups-drawer.tabs.roles-label')}`}>
                  <GroupsDrawerRolesTab
                    allRoles={roles}
                    groupRoles={group.roles}
                    groupId={group.id}
                    adminGroup={group.admin}
                  />
                </Tab>
              </Tabs>
            </DrawerBase>
          )}
          {showAddCustomer && (
            <AddCustomerToGroup
              groupName={group.name}
              groupId={group.id}
              onClose={handleCloseAddCustomerToGroup}
              groupCustomers={group.customers}
              allCustomers={allCustomers}
              breadcrumbs={breadcrumbs}
            />
          )}
          {showAddUser && (
            <AddUserToGroup
            group={group}
              onClose={handleCloseAddUserToGroup}
              groupUsers={group.users}
              allUsers={allUsers}
              breadcrumbs={breadcrumbs}
            />
          )}
          {/* {selectedUserId && (
                TODO: Use later on
                <EditGroupUser
                  groupId={internalGroup.id}
                  user={internalGroup.users.find((user) => user.id === selectedUserId)}
                  onClose={handleCloseEditUser}
                />
              )} */}
        </>
      </DrawerModal>
    </div>
  );
};

export default GroupsDrawer;
