import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import MessageMetadataTableRow from './MessageMetadataTableRow';
import { MessageMetadata } from 'src/types/MessageMetadata';
import './message-metadata-table.scss';

interface MessageMetadataTableProps {
  messageMetadata: MessageMetadata[];
  onClick: (userId: string) => void;
}

const MessageMetadataTable: FC<MessageMetadataTableProps> = ({ messageMetadata, onClick }) => {
  const { t } = useTranslation();

  const head = [
    { name: t('monitoring:transaction-analysis-tab.table.head.message-file-name'), centered: false },
    { name: t('monitoring:transaction-analysis-tab.table.head.message-transaction-id'), centered: false },
  ];

  return (
    <div className="transaction-analysis-table" data-testid="transaction-analysis-table">
      <TableBase>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th centered={title.centered} key={`MessageMetadataTable-${title.name}-${index}`}>
                {title.name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {messageMetadata?.map((message) => (
            <MessageMetadataTableRow
              key={`MessageMetadataTableRow-${message.id}`}
              message={message}
              onClick={() => onClick(message.id)}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default MessageMetadataTable;
