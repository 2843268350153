import { gql } from '@apollo/client';

export const GET_EMPLOYERS_QUERY = gql`
  query ($customerId: UUID!) {
    employers(customerId: $customerId) {
      id
      legalName
      organisationNumber
    }
  }
`;
