import { gql } from '@apollo/client';

export const GET_INTERNAL_GROUP = gql`
  query GetInternalGroup($groupId: UUID!) {
    internalGroup(groupId: $groupId) {
      name
      id
      admin
      roles {
        name
        id
      }
      users {
        id
        name
      }
      customers {
        id
        name
      }
    }
  }
`;


