import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, FC, useState } from 'react';
import { InsuranceProductPage, InsuranceProductStatus } from 'src/types/InsuranceProduct';
import FilterComponent from 'src/components/Common/FilterComponent';
import { createOptions } from 'src/util/OptionUtil';
import { stringFilter } from 'src/util/Filter/StringFilter';
import { Option } from 'src/types/Option';
import { optionFilter } from 'src/util/Filter/OptionFilter';
import { unique } from 'src/util/ArrayUtil/ArrayUtil';
import InsuranceProductsDrawer from './InsuranceProductsDrawer';
import InsuranceProductsTable from './InsuranceProductsTable';

interface InsuranceProductsTabProps {
  page: InsuranceProductPage;
}

const InsuranceProductsTab: FC<InsuranceProductsTabProps> = ({ page }) => {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState('');
  const [providerFilter, setProviderFilter] = useState<Option<string>[]>([]);
  const [statusFilter, setStatusFilter] = useState<Option<InsuranceProductStatus>[]>([]);
  const [selectedInsuranceProductId, setSelectedInsuranceProductId] = useState<string>(undefined);

  const providerOptions = createOptions(
    unique(page.insuranceProducts.map((product) => product.insuranceProvider.legalName)),
  );
  const handleFilterTextChange = (e: ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value);
  const filteredProducts = page.insuranceProducts
    .filter((product) => stringFilter(product.name, filterText))
    .filter((product) => optionFilter(providerFilter, product.insuranceProvider.legalName))
    .filter((product) => optionFilter(statusFilter, product.status));

  const selectProps = [
    {
      placeholder: t('insuranceManager:provider'),
      options: providerOptions,
      selected: providerFilter,
      onChange: (options: Option<string>[]) => setProviderFilter([...options]),
    },
    {
      placeholder: t('common:status'),
      options: [
        { label: t(`insuranceManager:product-status.DRAFT`), value: 'DRAFT' },
        { label: t(`insuranceManager:product-status.ACTIVE`), value: 'ACTIVE' },
      ],
      selected: statusFilter,
      onChange: (options: Option<InsuranceProductStatus>[]) => setStatusFilter([...options]),
    },
  ];

  const handleOpenInsuranceProductsDrawer = (insuranceProductId: string) => {
    setSelectedInsuranceProductId(insuranceProductId);
  };

  const handleCloseInsuranceProductsDrawer = () => {
    setSelectedInsuranceProductId(undefined);
  };

  return (
    <>
      <FilterComponent
        searchPlaceholder={t('insuranceManager:search-product-placeholder')}
        searchFilter={filterText}
        showSearch
        onSearchFilterChange={handleFilterTextChange}
        selectProps={selectProps}
      />
      <InsuranceProductsTable insuranceProducts={filteredProducts} onClick={handleOpenInsuranceProductsDrawer} />
      {selectedInsuranceProductId !== undefined && (
        <InsuranceProductsDrawer
          open={selectedInsuranceProductId !== undefined}
          insuranceProduct={page.insuranceProducts.find((product) => product.id === selectedInsuranceProductId)}
          onClose={handleCloseInsuranceProductsDrawer}
        />
      )}
    </>
  );
};

export default InsuranceProductsTab;
