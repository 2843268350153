import { Button, Icon, InlineEdit, ListGroup, Option, Select, Switch } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_REPORTING_RULE } from 'src/graphql/schema/query/GetReportingRule';
import { createReportingRule } from 'src/service/CreateReportingRule';
import { deleteReportingRule } from 'src/service/DeleteReportingRule';
import { updateReportingRule } from 'src/service/UpdateReportingRule';
import { AvailableMessageFormat, InsuranceEventType, ReportingRule } from 'src/types/ReportingRule';
import { ReportingRuleRequest } from 'src/types/request/ReportingRuleRequest';
import { createOptions } from 'src/util/OptionUtil';
import './configuration-details-drawer.scss';
import { GET_INSURANCE_PROVIDER_AVAILABLE_MESSAGE_FORMATS } from '../../../../../../graphql/schema/query/GetInsuranceProviderAvailableMessageFormats';
import { InsuranceProviderAvailableMessageFormats } from '../../../../../../types/InsuranceProviderAvailableMessageFormats';

interface ReportingRuleDetailsProps {
  ruleConfigId: string;
  insuranceEventType: InsuranceEventType;
  insuranceProviderId: string;
  ruleId: string;
  onClose: () => void;
  onBackClick: () => void;
  onRuleIdChange: (ruleId: string) => void;
}

const ConfigurationDetailsDrawer: FC<ReportingRuleDetailsProps> = ({
  ruleConfigId,
  insuranceEventType,
  ruleId,
  onClose,
  onBackClick,
  onRuleIdChange,
  insuranceProviderId,
}) => {
  const { t } = useTranslation();

  const handleToggleRule = (ruleConfigId: string) => {
    if (ruleId === null) {
      const request: ReportingRuleRequest = {
        insuranceEventType: insuranceEventType,
        availableMessageFormat: null,
      };
      createReportingRule(ruleConfigId, request).then((res) => onRuleIdChange(res.id));
    } else {
      deleteReportingRule(ruleConfigId, ruleId).then(() => onRuleIdChange(null));
    }
  };

  const handleSelectedAvailableMessageFormat = (option: Option<AvailableMessageFormat>, rule: ReportingRule) => {
    if (ruleId !== null) {
      const request: ReportingRuleRequest = {
        insuranceEventType: insuranceEventType,
        availableMessageFormat: option.value,
      };
      updateReportingRule(rule.id, request).catch(() => {
        alert('Error when updating message format');
      });
    }
  };

  return (
    <DrawerBase
      leftButton={
        <Button type="link" label={`${t('common:back')}`} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
      title={t(`insuranceManager:insurance-event-type.${insuranceEventType}`)}
    >
      <>
        <ListGroup variant="inline-edit">
          <InlineEdit bold label={t('insuranceManager:reporting-rule-details.report-event')}>
            <Switch toggled={ruleId !== null} onChange={() => handleToggleRule(ruleConfigId)} />
          </InlineEdit>
        </ListGroup>

        {ruleId !== null && (
          <div className="configuration-details-drawer">
            <QueryWrapper query={GET_REPORTING_RULE} options={{ variables: { ruleId }, skip: !ruleId }}>
              {(rule: ReportingRule) => (
                <QueryWrapper
                  query={GET_INSURANCE_PROVIDER_AVAILABLE_MESSAGE_FORMATS}
                  options={{
                    variables: { insuranceProviderId: insuranceProviderId },
                    skip: !insuranceProviderId,
                  }}
                >
                  {(providerAvailableMessageFormats: InsuranceProviderAvailableMessageFormats) => {
                    const availableMessageFormats = providerAvailableMessageFormats.availableMessageFormats.map(
                      (format) => format.messageFormat,
                    );

                    const availableMessageFormatOptions: Option<AvailableMessageFormat>[] = [
                      ...createOptions(availableMessageFormats, t, 'insuranceManager:available-message-format'),
                      {
                        label: t('common:default'),
                        value: null,
                      },
                    ];
                    return (
                      <ListGroup variant="inline-edit">
                        <InlineEdit bold label={t('insuranceManager:reporting-rule-details.message-format')}>
                          <Select
                            small
                            options={availableMessageFormatOptions}
                            selected={availableMessageFormatOptions.find(
                              (option) => option.value === rule.availableMessageFormat,
                            )}
                            onChange={(option) => handleSelectedAvailableMessageFormat(option, rule)}
                            placeholder={t('insuranceManager:reporting-rule-details.format-placeholder')}
                          />
                        </InlineEdit>
                      </ListGroup>
                    );
                  }}
                </QueryWrapper>
              )}
            </QueryWrapper>
          </div>
        )}
      </>
    </DrawerBase>
  );
};

export default ConfigurationDetailsDrawer;
