import React from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { InsuranceEventType } from '../../../../../types/ReportingRule';
import ConfigurationDetailsDrawer from './ConfigurationDetailsDrawer';
import ConfigurationOverviewDrawer from './ConfigurationOverviewDrawer';

interface ConfigurationDrawerProps {
  open: boolean;
  ruleConfigId: string;
  insuranceProviderId: string;
  ruleId: string;
  insuranceEventType: InsuranceEventType;
  onClose: () => void;
  onRuleIdChange: (ruleId: string) => void;
  onBackClick: () => void;
  onSelectRule: (
    insuranceEventType: InsuranceEventType,
    ruleId: string,
    configurationRuleId: string,
    providerId: string,
  ) => void;
}

const ConfigurationDrawer: React.FC<ConfigurationDrawerProps> = ({
  open,
  onClose,
  ruleConfigId,
  insuranceProviderId,
  ruleId,
  insuranceEventType,
  onRuleIdChange,
  onBackClick,
  onSelectRule,
}) => {
  return (
    <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
      {insuranceEventType ? (
        <ConfigurationDetailsDrawer
          ruleConfigId={ruleConfigId}
          insuranceEventType={insuranceEventType}
          ruleId={ruleId}
          onClose={onClose}
          onBackClick={onBackClick}
          onRuleIdChange={onRuleIdChange}
          insuranceProviderId={insuranceProviderId}
        />
      ) : (
        <ConfigurationOverviewDrawer
          ruleConfigId={ruleConfigId}
          onSelectRule={onSelectRule}
          onClose={onClose}
          insuranceProviderId={insuranceProviderId}
        />
      )}
    </DrawerModal>
  );
};

export default ConfigurationDrawer;
