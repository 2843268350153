import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { GroupsDrawerTab } from 'src/types/tabs/GroupsDrawerTab';
import './internal-users-drawer.scss';
import InternalUsersDrawerAboutTab from './InternalUsersDrawerAboutTab';
import InternalUsersDrawerGroupsTab from './InternalUsersDrawerGroupsTab';
import InternalUsersDrawerAddUserToGroup from './InternalUsersDrawerGroupsTab/InternalUsersDrawerAddUserToGroup';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INTERNAL_USER } from 'src/graphql/schema/query/GetInternalUser';
import { GetUser } from 'src/types/User';
import { InternalGroup } from 'src/types/Group';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';

interface InternalUsersDrawerProps {
  open: boolean;
  onClose: () => void;
  userId: string;
  allGroups: InternalGroup[]
}

const InternalUsersDrawer: React.FC<InternalUsersDrawerProps> = ({ open, onClose, userId, allGroups }) => {
  const [currentTab, setCurrentTab] = useState<GroupsDrawerTab>(GroupsDrawerTab.CUSTOMERS);
  const [showAddUserToGroup, setShowAddUserToGroup] = useState(false);
  const { t } = useTranslation();
  const breadcrumbs = [
    t('permission:breadcrumbs.internal-permission'),
    t('permission:breadcrumbs.users'),
  ];

  const handleOnAddUserToGroupClick = () => {
    setShowAddUserToGroup(true);
  };

  const handleCloseAddUserToGroup = () => {
    setShowAddUserToGroup(false);
  };

  return (
    <div className="internal-users-drawer">
      <QueryWrapper query={GET_INTERNAL_USER} options={{ variables: { userId } }}>
        {({ user, internalGroups }: GetUser) => (
          <>
            <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
              {!showAddUserToGroup && (
                <DrawerBase onClose={onClose} title={
                  <>
                  <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
                  <Typography variant="h3" component="h3" bold className="internal-users-drawer__title">
                  {`${user.firstName??''} ${user.lastName??''}`}
                  </Typography>
                </>
                }>
                  <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
                    <Tab title={`${t('permission:internal.internal-users-drawer.tabs.about-label')}`}>
                      <InternalUsersDrawerAboutTab user={user} />
                    </Tab>
                    <Tab title={`${t('permission:internal.internal-users-drawer.tabs.groups-label')}`}>
                      <InternalUsersDrawerGroupsTab
                        onAddUserToGroupClick={handleOnAddUserToGroupClick}
                        userId={user.id}
                        groups={internalGroups}
                      />
                    </Tab>
                  </Tabs>
                </DrawerBase>
              )}
              {showAddUserToGroup && (
                <InternalUsersDrawerAddUserToGroup onClose={handleCloseAddUserToGroup} user={user} allGroups={allGroups} userGroups={internalGroups} breadcrumbs={breadcrumbs}/>
              )}
            </DrawerModal>
          </>
        )}
      </QueryWrapper>
    </div>
  );
};

export default InternalUsersDrawer;
