import { Account } from './Account';
import { Employer } from './Employer';
import { InsuranceProvider } from './InsuranceProvider';
import { PageableResponse } from './PageableResponse';

export interface Invoice {
  id: string;
  customerId: string;
  employer: Employer;
  fileName: string;
  invoiceStatus: InvoiceStatus;
  dueDate: string;
  paymentDueDate: string;
  totalAmount: number;
  invoiceNumber: string;
  invoiceTypeCode: InvoiceTypeCode;
  insuranceProvider: InsuranceProvider;
  note: string;
  accountNumberTo: string;
  account: Account;
  ocrNumber: string;
  remainingAmount: number;
}

export interface SearchInvoicesPage extends PageableResponse {
  internalSearchInvoices: Invoice[];
}

export interface SearchRejectedInvoicesPage extends PageableResponse {
  internalSearchRejectedInvoices: Invoice[];
}

export type InvoiceStatus =
  | 'INVOICE_ARRIVED'
  | 'REJECTED'
  | 'CHECKPOINT_PENDING'
  | 'VALIDATION_DONE'
  | 'INVOICE_MATCHING_DONE'
  | 'PREPARED_FOR_PAYMENTS'
  | 'PRELIMINARY_ACCOUNTING_DONE'
  | 'ACCOUNTING_DONE'
  | 'INVOICE_PAYMENT_REQUEST_SENT'
  | 'INVOICE_PAYMENT_FAILED'
  | 'INVOICE_PAID'
  | 'PAUSED';

export enum InvoiceStatusEnum {
  INVOICE_ARRIVED = 'INVOICE_ARRIVED',
  REJECTED = 'REJECTED',
  CHECKPOINT_PENDING = 'CHECKPOINT_PENDING',
  VALIDATION_DONE = 'VALIDATION_DONE',
  INVOICE_MATCHING_DONE = 'INVOICE_MATCHING_DONE',
  PREPARED_FOR_PAYMENTS = 'PREPARED_FOR_PAYMENTS',
  PRELIMINARY_ACCOUNTING_DONE = 'PRELIMINARY_ACCOUNTING_DONE',
  ACCOUNTING_DONE = 'ACCOUNTING_DONE',
  INVOICE_PAYMENT_REQUEST_SENT = 'INVOICE_PAYMENT_REQUEST_SENT',
  INVOICE_PAYMENT_FAILED = 'INVOICE_PAYMENT_FAILED',
  INVOICE_PAID = 'INVOICE_PAID',
  PAUSED = 'PAUSED',
}

export type InvoiceTypeCode = 'DEBIT' | 'CREDIT' | 'BOOKKEEPING';
