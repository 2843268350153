import { gql } from '@apollo/client';

export const DELETE_REPORTING_RULE = gql`
  mutation ($reportingRuleConfigurationId: UUID!, $reportingRuleId: UUID!) {
    deleteReportingRule(
      reportingRuleConfigurationId: $reportingRuleConfigurationId
      reportingRuleId: $reportingRuleId
    ) {
      id
    }
  }
`;
