import { DrawerNavigation, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RejectionDetail } from 'src/types/RejectionDetail';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import './rejection-details-tab.scss';

interface RejectionDetailsTabProps {
  onSelectRejectionDetail: (id: string) => void;
  rejectionDetails: RejectionDetail[];
}

const RejectionDetailsTab: FC<RejectionDetailsTabProps> = ({ onSelectRejectionDetail, rejectionDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="rejection-details-tab">
      <ListGroup data-testid="rejection-detail" variant="drawer-navigation">
        {rejectionDetails.map((detail) => (
          <DrawerNavigation
            key={`RejectionDetailsTab-${detail.id}`}
            label={
              <Typography bold variant="body2" component="span">
                {t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.rejection-detail-label') +
                  ` ${String(detail.createdDate).substring(0, 10)}`}
              </Typography>
            }
            value={stringOrBlank(t(`invoice:rejection-cause.${detail.rejectionCause}`))}
            onClick={() => onSelectRejectionDetail(detail.id)}
          />
        ))}
      </ListGroup>
    </div>
  );
};
export default RejectionDetailsTab;
