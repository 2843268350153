import { InlineEdit, ListGroup, Message, Switch, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import './insurance-provider-scheduling-tab.scss';
import TextInput from 'src/components/Common/TextInput';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';

interface InsuranceProviderReportingTabProps {
  insuranceProvider: InsuranceProvider;
}

function InsuranceProviderReportingTab({ insuranceProvider }: InsuranceProviderReportingTabProps) {
  const { t } = useTranslation();

  const handleLastDayOfMonthChange = () => {
    // TODO: implement this when necessary
  };

  return (
    <div className="insurance-provider-scheduling-tab">
      <Typography variant="subtitle" bold>
        {t('insuranceManager:insurance-provider-drawer.reporting-default')}
      </Typography>
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('insuranceManager:insurance-provider-drawer.last-day-of-month')} bold>
          <Switch
            toggled={insuranceProvider.defaultReportScheduling?.isLastDayOfMonth ? true : false}
            disabled={true}
            onChange={handleLastDayOfMonthChange}
          />
        </InlineEdit>
        <InlineEdit label={t('insuranceManager:insurance-provider-drawer.day-of-month')} bold>
          <TextInput value={stringOrBlank(insuranceProvider.defaultReportScheduling?.dayOfMonth)} disabled={true} />
        </InlineEdit>
        <InlineEdit label={t('insuranceManager:insurance-provider-drawer.hour')} bold>
          <TextInput value={stringOrBlank(insuranceProvider.defaultReportScheduling?.time)} disabled={true} />
        </InlineEdit>
      </ListGroup>
      <Message type="info" message={t('insuranceManager:insurance-provider-drawer.scheduling-info-message')} />
    </div>
  );
}

export default InsuranceProviderReportingTab;
