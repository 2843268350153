import { TableHead, TableRow, Th, TableBody } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { Employer } from 'src/types/Employer';
import { GET_EMPLOYERS_QUERY } from 'src/graphql/schema/query/GetEmployers';
import TableBase from 'src/components/Common/TableBase';
import CustomerDetailsRow from '../CustomerDetailsRow';

interface EmployersTabProps {
  customerId: string;
}

const EmployersTab = ({ customerId }: EmployersTabProps) => {
  const { t } = useTranslation();

  const head = [
    { name: t('customer:employer'), centered: false },
    { name: t('customer:organisation-number'), centered: false },
  ];

  return (
    <TableBase maxHeightPercentage={75}>
      <TableHead filled>
        <TableRow>
          {head.map((title, index) => (
            <Th centered={title.centered} key={`CustomersTable-${title.name}-${index}`}>
              {title.name}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <QueryWrapper query={GET_EMPLOYERS_QUERY} options={{ variables: { customerId } }}>
        {(employers: Employer[]) => {
          return (
            <TableBody>
              {employers.map((employer) => (
                <CustomerDetailsRow employer={employer} />
              ))}
            </TableBody>
          );
        }}
      </QueryWrapper>
    </TableBase>
  );
};

export default EmployersTab;
