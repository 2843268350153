import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollBase from 'src/components/Common/ScrollBase';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { Paging } from 'src/types/PageableResponse';
import { InsuranceEventType } from 'src/types/ReportingRule';
import { ReportingRuleConfiguration } from 'src/types/ReportingRuleConfiguration';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import ConfigurationDrawer from '../ConfigurationDrawer';
import ConfigurationListItem from '../ConfigurationListItem';
import CreateMessageConfigurationModal from '../CreateMessageConfigurationModal';

interface ConfigurationsProps {
  configurations: ReportingRuleConfiguration[];
  insuranceProviders: InsuranceProvider[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  onPageChange: (nextPageNumber: number) => void;
}

const Configurations: FC<ConfigurationsProps> = ({
  configurations,
  insuranceProviders,
  paging,
  pageNumber,
  pageSize,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const totalPages = getTotalPages(paging?.totalNumberOfElements, pageSize);
  const showPagination = totalPages > 1;
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [selectedRuleId, setSelectedRuleId] = useState(null);
  const [selectedRuleConfigId, setSelectedRuleConfigId] = useState<string>();
  const [selectedInsuranceProviderId, setSelectedInsuranceProviderId] = useState<string>();
  const [selectedInsuranceEventType, setSelectedInsuranceEventType] = useState(null);

  const onSelectConfiguration = (configurationRuleId: string, providerId: string) => {
    setDrawerOpen(true);
    setSelectedRuleConfigId(configurationRuleId);
    setSelectedInsuranceProviderId(providerId);
  };

  const onSelectRule = (
    insuranceEventType: InsuranceEventType,
    ruleId: string,
    configurationRuleId: string,
    providerId: string,
  ) => {
    setSelectedRuleId(ruleId);
    setSelectedInsuranceEventType(insuranceEventType);
    setSelectedRuleConfigId(configurationRuleId);
    setSelectedInsuranceProviderId(providerId);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedInsuranceProviderId(null);
    setSelectedRuleConfigId(null);
    setSelectedInsuranceEventType(null);
    setSelectedRuleId(null);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleBackClick = () => {
    setSelectedInsuranceEventType(null);
    setSelectedRuleId(null);
  };

  const onRuleIdChange = (ruleId: string) => {
    setSelectedRuleId(ruleId);
  };

  return (
    <>
      <ScrollBase maxHeightPercentage={60}>
        <ul className="configuration-tab__list">
          {configurations.map((configuration) => (
            <ConfigurationListItem
              key={`configuration-tab-${configuration.id}`}
              ruleConfig={configuration}
              onSelectConfiguration={onSelectConfiguration}
              insuranceProvider={insuranceProviders.find(
                (provider) => provider.id === configuration.insuranceProviderId,
              )}
              onSelectRule={onSelectRule}
            />
          ))}
        </ul>
      </ScrollBase>
      {showPagination && (
        <div data-testid="configuration-tab-pagination">
          <Pagination
            currentPage={pageNumber}
            onPageChange={(nextPageNumber) => onPageChange(nextPageNumber)}
            totalPages={totalPages}
          />
        </div>
      )}

      {drawerOpen && (
        <ConfigurationDrawer
          open={drawerOpen}
          onClose={handleDrawerClose}
          ruleConfigId={selectedRuleConfigId}
          insuranceEventType={selectedInsuranceEventType}
          ruleId={selectedRuleId}
          insuranceProviderId={selectedInsuranceProviderId}
          onBackClick={handleBackClick}
          onRuleIdChange={onRuleIdChange}
          onSelectRule={onSelectRule}
        />
      )}
      <div className="configuration-tab__create-message-configuration-button">
        <Button
          type="floating-action-button"
          icon={<Icon type="PlusLarge" />}
          label={t('insuranceManager:add-message-configuration')}
          onClick={handleOpenModal}
        />
      </div>
      {modalOpen && <CreateMessageConfigurationModal open={modalOpen} onClose={handleCloseModal} />}
    </>
  );
};
export default Configurations;
