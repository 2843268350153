import { DrawerNavigation, ListGroup } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import './scheduling-drawer-tab.scss';

interface SchedulingDrawerTabProps {
  assignment: DocumentTemplateEmployerAssignment;
  onAddScheduling: () => void;
  onSelectScheduling: (id: string) => void;
}

const SchedulingDrawerTab: FC<SchedulingDrawerTabProps> = ({ assignment, onAddScheduling, onSelectScheduling }) => {
  const { t } = useTranslation();

  return (
    <div className="scheduling-drawer-tab">
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('documents:templates-tab.drawer.scheduling-drawer-tab.add-scheduling-label')}
          onClick={onAddScheduling}
        />
      </ListGroup>
      {assignment.scheduledJobs.length > 0 && (
        <ListGroup data-testid="scheduling-drawer-tab" variant="drawer-navigation">
          {assignment.scheduledJobs.map((scheduling) => (
            <DrawerNavigation
              label={`${scheduling.triggerCron}`}
              key={`SchedulingDrawerTab-${scheduling.id}`}
              value={t(`documents:reporting-period.${scheduling.reportingPeriod}`)}
              onClick={() => onSelectScheduling(scheduling.id)}
            />
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default SchedulingDrawerTab;
