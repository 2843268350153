import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';

interface TemplateTableRowProps {
  assignment: DocumentTemplateEmployerAssignment;
  onClick: (templateId: string) => void;
}

const TemplateTableRow: FC<TemplateTableRowProps> = ({ assignment, onClick }) => {
  const { t } = useTranslation();

  const isSchedulingPresent = assignment.scheduledJobs.length > 0;

  return (
    <TableRow data-testid={`template-table-row__${assignment.id}`} onClick={() => onClick(assignment.id)}>
      <Td>{stringOrBlank(assignment.templateName)}</Td>
      <Td>{stringOrBlank(t(`documents:format-type.${assignment.formatType}`))}</Td>
      <Td>{stringOrBlank(t(`documents:scheduling.is-scheduling-present.${isSchedulingPresent}`))}</Td>
    </TableRow>
  );
};
export default TemplateTableRow;
