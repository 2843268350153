import React, { useState } from 'react';
import './groups-tab.scss';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import CreateInternalGroupModal from './CreateInternalGroupModal';
import GroupsTable from './GroupsTable';
import GroupsDrawer from './GroupsDrawer';
import {GetGroupsPage } from 'src/types/Group';
import { GET_INTERNAL_ROLES } from 'src/graphql/schema/query/GetInternalRoles';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GetRolesPage } from 'src/types/Role';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { GET_ALL_INTERNAL_USERS } from 'src/graphql/schema/query/GetInternalUsers';
import { GetInternalUsersPage } from 'src/types/InternalUser';
import { GetCustomerPage } from 'src/types/Customer';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/customer/GetCustomers';

const GroupsTab = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string>(undefined);
  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 15;

  const handleOpenDrawer = (id: string) => {
    setSelectedGroupId(id);
  };

  const handleCloseDrawer = () => {
    setSelectedGroupId(undefined);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  return (
    <div className="groups-tab">
      <QueryWrapper query={GET_INTERNAL_GROUPS} options={{ variables: { pageSize, pageNumber: queryPageNumber } }}>
        {({ groups, paging }: GetGroupsPage) => {
          const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
          return (
            <>
              <GroupsTable groups={groups} onClick={handleOpenDrawer} />
              {totalPages > 1 && (
                <Pagination
                  currentPage={pageNumber}
                  onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                  totalPages={totalPages}
                />
              )}
              <QueryWrapper query={GET_INTERNAL_ROLES} options={{ variables: { pageSize: 40, pageNumber: 0 } }}>
                {(getRoles: GetRolesPage) => (
                  <QueryWrapper query={GET_ALL_CUSTOMERS}>
                    {({ customers }: GetCustomerPage) => (
                      <QueryWrapper query={GET_ALL_INTERNAL_USERS}>
                        {(getInternalUsers: GetInternalUsersPage) => (
                          <>
                            {selectedGroupId && (
                              <GroupsDrawer
                                open={selectedGroupId !== undefined}
                                group={groups.find((group) => group.id === selectedGroupId)}
                                roles={getRoles.roles}
                                onClose={handleCloseDrawer}
                                allCustomers={customers}
                                allUsers={getInternalUsers.internalUsers}
                              />
                            )}
                          </>
                        )}
                      </QueryWrapper>
                    )}
                  </QueryWrapper>
                )}
              </QueryWrapper>
            </>
          );
        }}
      </QueryWrapper>

      <div className="groups-tab__create-internal-group-modal-button" data-testid="create-internal-group-modal-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'PlusLarge'} />}
          label={t('permission:internal.create-internal-group-button')}
          onClick={handleOpenModal}
        />
      </div>
      <CreateInternalGroupModal open={modalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default GroupsTab;
