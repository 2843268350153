import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import AboutDrawerTab from './AboutDrawerTab';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import SchedulingDrawerTab from './SchedulingDrawerTab';
import { TemplatesDrawerTab } from 'src/types/tabs/TemplatesDrawerTab';

interface TemplateDrawerOverviewProps {
  assignment: DocumentTemplateEmployerAssignment;
  onClose: () => void;
  onAddScheduling: () => void;
  onSelectScheduling: (id: string) => void;
  currentTab: TemplatesDrawerTab;
}

const TemplateDrawerOverview: React.FC<TemplateDrawerOverviewProps> = ({
  assignment,
  onClose,
  onAddScheduling,
  onSelectScheduling,
  currentTab,
}) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    t('documents:templates-tab.drawer.bread-crumbs.documents'),
    t('documents:templates-tab.drawer.bread-crumbs.templates'),
  ];

  return (
    <DrawerBase
      onClose={onClose}
      title={
        <>
          <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
          <Typography variant="h3" component="h3" bold>
            {assignment.templateName}
          </Typography>
        </>
      }
    >
      <Tabs tabIndex={currentTab}>
        <Tab
          data-testid="template-drawer-overview__about"
          title={t('documents:templates-tab.drawer.about-drawer-tab.tab-title')}
        >
          <AboutDrawerTab assignment={assignment} onClose={onClose} />
        </Tab>
        <Tab
          data-testid="template-drawer-overview__scheduling"
          title={t('documents:templates-tab.drawer.scheduling-drawer-tab.tab-title')}
        >
          <SchedulingDrawerTab
            assignment={assignment}
            onAddScheduling={onAddScheduling}
            onSelectScheduling={onSelectScheduling}
          />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default TemplateDrawerOverview;
