import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useState } from 'react';
import { createOptions } from 'src/util/OptionUtil';
import TextInput from 'src/components/Common/TextInput';
import { SearchPaymentsFilters } from 'src/types/SearchPaymentsRequest';
import { isBlank } from 'src/util/StringUtil/StringUtil';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import './search-payments-filters-modal.scss';
import { BankType, bankType } from 'src/types/BankType';
import { PaymentServiceProviderType, paymentServiceProviderType } from 'src/types/PaymentServiceProviderType';
import { PaymentStatus, paymentStatuses } from 'src/types/Payment';

interface SearchPaymentsFiltersModalProps {
  open: boolean;
  filters: SearchPaymentsFilters;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchPaymentsFilters) => void;
}

const SearchPaymentsFiltersModal: FC<SearchPaymentsFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<SearchPaymentsFilters>({ ...filters });

  const handleBankFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      banksFilter: options.map((option) => option.value),
    });
  };

  const handlePaymentStatusFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      paymentStatuses: options.length > 0 ? options.map((option) => option.value) : [],
    });
  };

  const handlePaymentServiceProviderFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      paymentServiceProvidersFilter: options.map((option) => option.value),
    });
  };

  const handlePaymentDueDateFilterChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      paymentDueDateFilter: value,
    });
  };

  const handlePaymentCreatedStartDateFilterChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDateFilter: {
        ...newFilters.paymentCreatedDateFilter,
        startDate: value,
      },
    });
  };

  const handlePaymentCreatedStartTimeFilterChange = (input: ChangeEvent<HTMLInputElement>) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDateFilter: {
        ...newFilters.paymentCreatedDateFilter,
        startTime: input?.target.value,
      },
    });
  };

  const handlePaymentCreatedEndDateFilterChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDateFilter: {
        ...newFilters.paymentCreatedDateFilter,
        endDate: value,
      },
    });
  };

  const handlePaymentCreatedEndTimeFilterChange = (input: ChangeEvent<HTMLInputElement>) => {
    setNewFilters({
      ...newFilters,
      paymentCreatedDateFilter: {
        ...newFilters.paymentCreatedDateFilter,
        endTime: input?.target.value,
      },
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  const bankOptions: Option<BankType>[] = createOptions(bankType, t, 'payment:bank');
  const paymentStatusOptions: Option<PaymentStatus>[] = createOptions(paymentStatuses, t, 'payment:payment-status');

  const paymentServiceProviderOptions: Option<PaymentServiceProviderType>[] = createOptions(
    paymentServiceProviderType,
    t,
    'payment:payment-service-provider',
  );

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="search-payments-filters-modal"
        title={t('economy:search-payments-filters-modal.title')}
        icon={<Icon type="Sliders" className="search-payments-filters-modal__icon" />}
        size="wide"
      >
        <div className="search-payments-filters-modal__filters">
          <div className="search-payments-filters-modal__select">
            <Select
              label={t('economy:search-payments-filters-modal.payment-status-label')}
              placeholder={t('common:all')}
              selectAll
              options={paymentStatusOptions}
              selected={paymentStatusOptions.filter((option) => newFilters?.paymentStatuses?.includes(option.value))}
              onChange={handlePaymentStatusFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="search-payments-filters-modal__select">
            <Select
              label={t('economy:search-payments-filters-modal.bank-label')}
              placeholder={t('common:all')}
              selectAll
              options={bankOptions}
              selected={bankOptions.filter((option) => newFilters?.banksFilter?.includes(option.value))}
              onChange={handleBankFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="search-payments-filters-modal__select">
            <Select
              label={t('economy:search-payments-filters-modal.payment-service-provider-label')}
              placeholder={t('common:all')}
              selectAll
              options={paymentServiceProviderOptions}
              selected={paymentServiceProviderOptions.filter((option) =>
                newFilters?.paymentServiceProvidersFilter?.includes(option.value),
              )}
              onChange={handlePaymentServiceProviderFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="search-payments-filters-modal__date-section">
            <div className="search-payments-filters-modal__date">
              <UpdateDatePicker
                label={t('economy:search-payments-filters-modal.payment-due-date-label')}
                fieldValue={newFilters?.paymentDueDateFilter?.toString() ?? ''}
                fieldKey="paymentDueDate"
                onBlur={(_, value) => {
                  handlePaymentDueDateFilterChange(value);
                }}
              />
            </div>
            <div className="search-payments-filters-modal__date-time-interval">
              <div>
                <UpdateDatePicker
                  label={t('economy:search-payments-filters-modal.payment-created-date-start-date-label')}
                  fieldValue={newFilters?.paymentCreatedDateFilter?.startDate?.toString() ?? ''}
                  fieldKey="startDate"
                  onBlur={(_, value) => {
                    handlePaymentCreatedStartDateFilterChange(value);
                  }}
                />
              </div>
              <TextInput
                label={t('economy:search-payments-filters-modal.payment-created-date-start-time-label')}
                type="text"
                variant="default"
                validationKey="hourWithMinutes"
                placeholder={t('economy:search-payments-filters-modal.payment-created-date-time-placeholder')}
                onChange={handlePaymentCreatedStartTimeFilterChange}
                value={newFilters?.paymentCreatedDateFilter?.startTime?.toString()}
                disabled={isBlank(newFilters?.paymentCreatedDateFilter?.startDate)}
                maxLength={5}
              />
            </div>
          </div>
          <div className="search-payments-filters-modal__date-section">
            <div className="search-payments-filters-modal__date-time-interval">
              <div>
                <UpdateDatePicker
                  label={t('economy:search-payments-filters-modal.payment-created-date-end-date-label')}
                  fieldValue={newFilters?.paymentCreatedDateFilter?.endDate?.toString() ?? ''}
                  fieldKey="endDate"
                  onBlur={(_, value) => {
                    handlePaymentCreatedEndDateFilterChange(value);
                  }}
                />
              </div>
              <TextInput
                label={t('economy:search-payments-filters-modal.payment-created-date-end-time-label')}
                type="text"
                variant="default"
                validationKey="hourWithMinutes"
                placeholder={t('economy:search-payments-filters-modal.payment-created-date-time-placeholder')}
                onChange={handlePaymentCreatedEndTimeFilterChange}
                value={newFilters?.paymentCreatedDateFilter?.endTime?.toString()}
                disabled={isBlank(newFilters?.paymentCreatedDateFilter?.endDate)}
                maxLength={5}
              />
            </div>
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('economy:search-payments-filters-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchPaymentsFiltersModal;
