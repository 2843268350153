import { INTERNAL_ADD_USER_TO_GROUP } from 'src/graphql/schema/mutation/internalGroup/InternalAddUserToGroup';
import { useApolloMutation } from '../graphql/Mutation';
import { AddUserToGroupInput } from 'src/types/Group';
import { GET_INTERNAL_USER } from 'src/graphql/schema/query/GetInternalUser';
import { GET_INTERNAL_USERS } from 'src/graphql/schema/query/GetInternalUsers';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';
import { GET_INTERNAL_GROUP } from 'src/graphql/schema/query/GetInternalGroup';


export const useInternalAddUserToGroup = () => {
  const apolloMutation = useApolloMutation();

  const internalAddUserToGroup = (groupId: string, userId: string, request: AddUserToGroupInput) =>
    apolloMutation(INTERNAL_ADD_USER_TO_GROUP, '', { groupId, userId, request }, [
      GET_INTERNAL_USER,
      GET_INTERNAL_USERS,
      GET_INTERNAL_GROUP,
      GET_INTERNAL_GROUPS
    ]);

  return internalAddUserToGroup;
};
