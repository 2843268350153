import React from 'react';
import './internal-users-drawer-about-tab.scss';
import { Banner, Button, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { User } from 'src/types/User';
import { useInternalLockUser, useInternalUnlockUser } from 'src/service/user/LockUser';
import { getLoggedInUser, loggedInUserIsFGAdmin } from 'src/service/user/getUserFromToken';

interface InternalUsersDrawerAboutTabProps {
  user: User;
}

const InternalUsersDrawerAboutTab = ({ user }: InternalUsersDrawerAboutTabProps) => {
  const { t } = useTranslation();
  const lockUser = useInternalLockUser();
  const unlockUser = useInternalUnlockUser();
  const isLocked = !user.accountNonLocked;
  const loggedInUser = getLoggedInUser();
  const buttonDisabled = loggedInUserIsFGAdmin() && user.id === loggedInUser.userId;


  const getConnectionLabel = (validTo: string) => {
    return validTo
      ? t('permission:about-user.connection-limited-label')
      : t('permission:about-user.connection-running-label');
  };
  const clickUnlockUser = () => {
    unlockUser(user.id);
  };
  const clickLockUser = () => {
    lockUser(user.id);
  };

  return (
    <ListGroup variant={'inline-edit'} className="internal-users-drawer-about-tab">
      {isLocked && (
        <Banner
          type="warning"
          message={t('permission:about-user.user-locked-label')}
          className="internal-users-drawer-about-tab__locked-pill"
        />
      )}
      <InlineEdit bold label={t('common:first-name')}>
        <Typography variant="body2">{user.firstName}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('common:last-name')}>
        <Typography variant="body2">{user.lastName}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('permission:about-user.identifier-label')}>
        <Typography variant="body2">{user.identifier}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('permission:about-user.phone-label')}>
        <Typography variant="body2">{user.phone}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('common:email')}>
        <Typography variant="body2">{user.email}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('permission:about-user.order-ref-label')}>
        <Typography variant="body2">{user.orderRef}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('permission:about-user.identification-label')}>
        <Typography variant="body2">{user.identifierType}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('permission:about-user.connection-label')}>
        <Typography variant="body2">{getConnectionLabel(user.validTo)}</Typography>
      </InlineEdit>
      <InlineEdit bold label={t('permission:about-user.valid-to-label')}>
        <Typography variant="body2">{user.validTo ?? '-'}</Typography>
      </InlineEdit>
      <div className="internal-users-drawer-about-tab__lock-button-wrapper">
        {isLocked && (
          <Button
            className="internal-users-drawer-about-tab__lock-button"
            type="secondary"
            label={t('permission:about-user.unlock-button')}
            icon={<Icon type="PlusLarge" />}
            onClick={clickUnlockUser}
            disabled={buttonDisabled}
          />
        )}
        {!isLocked && (
          <Button
            className="internal-users-drawer-about-tab__lock-button"
            type="secondary"
            label={t('permission:about-user.lock-button')}
            icon={<Icon type="Cross" />}
            onClick={clickLockUser}
            disabled={buttonDisabled}
          />
        )}
      </div>
    </ListGroup>
  );
};

export default InternalUsersDrawerAboutTab;
