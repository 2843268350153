import { InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Payment } from 'src/types/Payment';
import './payments-calculated-amount.scss';

interface PaymentsCalculatedAmountProps {
  payments: Payment[];
}

const PaymentsCalculatedAmount: FC<PaymentsCalculatedAmountProps> = ({ payments }) => {
  const { t } = useTranslation();

  const calculatedAmountLabel =
    t(`economy:payments-tab.total-amount-label`) +
    ` (${
      payments.length === 1
        ? payments.length + ' ' + t(`economy:payments-tab.single-marked-label`)
        : payments.length + ' ' + t(`economy:payments-tab.several-marked-label`)
    }` +
    ')';

  return (
    <div className="payments-calculated-amount">
      <ListGroup variant="inline-edit">
        <InlineEdit label={calculatedAmountLabel} bold>
          <Typography component="span" variant="body2">
            {payments.reduce((acc, curr) => acc + curr.amount, 0).toString()}
          </Typography>
        </InlineEdit>
      </ListGroup>
    </div>
  );
};

export default PaymentsCalculatedAmount;
