import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import './internal-users-tab.scss';
import InternalUsersTable from './InternalUsersTable';
import CreateInternalUserModal from 'src/components/Permission/InternalPermission/InternalUsersTab/CreateInternalUserModal';
import InternalUsersDrawer from './InternalUsersDrawer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INTERNAL_USERS } from 'src/graphql/schema/query/GetInternalUsers';
import { GetInternalUsersPage } from 'src/types/InternalUser';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { GET_ALL_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';
import { GetGroupsPage } from 'src/types/Group';

const InternalUsersTab = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [getInternalUsersPageNumber, setInternalUsersPageNumber] = useState(1);
  const internalUsersQueryPageNumber = getInternalUsersPageNumber - 1;
  const pageSize = 15;
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOnCloseDrawer = () => {
    setSelectedUserId(undefined);
  };

  const handleClickOnUserRow = (userId: string) => {
    setSelectedUserId(userId);
  };

  const handleOnPageChange = (nextPageNumber: number) => {
    setInternalUsersPageNumber(nextPageNumber);
  };

  return (
    <div className="internal-users-tab" data-testid={'internal-users-tab'}>
      <QueryWrapper
        query={GET_INTERNAL_USERS}
        options={{ variables: { pageSize, pageNumber: internalUsersQueryPageNumber } }}
      >
        {({ internalUsers, paging }: GetInternalUsersPage) => (
          <QueryWrapper query={GET_ALL_INTERNAL_GROUPS}>
            {({ groups }: GetGroupsPage) => {
              const hasUsers = !!internalUsers?.length;
              const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
              const showPagination = totalPages > 1;
              return (
                <>
                  {!hasUsers && <div data-testid={`internal-users-no-users`}>Inga användare hittade</div>}
                  {hasUsers && (
                    <>
                      <InternalUsersTable
                        internalUsers={internalUsers}
                        onClick={handleClickOnUserRow}
                        data-testid={`internal-users-tab-table`}
                      />
                      {showPagination && (
                        <div data-testid="internal-users-tab-pagination">
                          <Pagination
                            currentPage={getInternalUsersPageNumber}
                            onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                            totalPages={totalPages}
                          />
                        </div>
                      )}
                      {selectedUserId && (
                        <InternalUsersDrawer
                          open={!!selectedUserId}
                          userId={selectedUserId}
                          onClose={handleOnCloseDrawer}
                          allGroups={groups}
                        />
                      )}
                    </>
                  )}
                </>
              );
            }}
          </QueryWrapper>
        )}
      </QueryWrapper>
      <div className="internal-users-tab__create-user-modal-button" data-testid="internal-users-tab-create-user-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'PlusLarge'} />}
          label={t('permission:create-user-button')}
          onClick={handleOpenModal}
        />
      </div>
      <CreateInternalUserModal open={modalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default InternalUsersTab;
