import { Option } from 'fgirot-k2-ui-components';

export const getYears = (): number[] => {
  const years: number[] = [];
  for (let i = new Date().getFullYear() - 5; i <= new Date().getFullYear() + 10; i++) {
    years.push(i);
  }
  return years;
};

export const useYearOptions = (): Option<number>[] => {
  const years: Option<number>[] = [];
  for (let year = new Date().getFullYear() - 24; year <= new Date().getFullYear(); year++) {
    years.push({ label: year.toString(), value: year });
  }
  return years.reverse();
};
