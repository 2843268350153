import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import TdFullname from 'src/components/Common/TdFullname';
import { InternalGroup } from 'src/types/Group';
import { User } from 'src/types/User';

interface InternalUsersTableRowProps {
  user: User;
  internalGroups: InternalGroup[];
  onClick: (userId: string) => void;
}

const InternalUsersTableRow: FC<InternalUsersTableRowProps> = ({ user, internalGroups, onClick }) => {
  const formatInternalGroupsString = (): string => {
    if (internalGroups.length) {
      const displayedName = internalGroups[0].name;
      const remainingGroups = internalGroups.slice(1);
      if (remainingGroups.length) {
        return `${displayedName} + ${remainingGroups.length}`;
      }
      return displayedName;
    }
    return '-';
  };
  return (
      <TableRow onClick={() => onClick(user.id)} data-testid={`internal-users-table-row-${user.id}`}>
        <Td>{<TdFullname firstName={user.firstName} lastName={user.lastName} />}</Td>
        <Td>{user.email ?? ''}</Td>
        <Td>{formatInternalGroupsString()}</Td>
      </TableRow>
  );
};

export default InternalUsersTableRow;
