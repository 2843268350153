import { DrawerNavigation, InlineEdit, ListGroup, Button } from 'fgirot-k2-ui-components';
import './internal-users-drawer-groups-tab.scss';
import { useTranslation } from 'react-i18next';
import { InternalGroup } from 'src/types/Group';
import { useInternalRemoveUserFromGroup } from 'src/service/internalGroup/InternalRemoveUserFromGroup';

interface InternalUsersDrawerGroupsTabProps {
  onAddUserToGroupClick: () => void;
  groups: InternalGroup[];
  userId: string;
}
const InternalUsersDrawerGroupsTab: React.FunctionComponent<InternalUsersDrawerGroupsTabProps> = ({
  onAddUserToGroupClick,
  groups,
  userId,
}) => {
  const { t } = useTranslation();
  const removeUserFromInternalGroup = useInternalRemoveUserFromGroup();
  const removeUserFromGroup = (groupId: string) => {
    removeUserFromInternalGroup(groupId, userId);
  };
  return (
    <>
      <ListGroup variant={'inline-edit'} className="internal-users-drawer-groups-tab">
        <DrawerNavigation
          className="internal-users-drawer-groups-tab__navigation"
          label={t('permission:internal.internal-users-drawer.groups.add-user-to-group')}
          onClick={onAddUserToGroupClick}
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        {groups.map((group) => (
          <InlineEdit key={group.id} label={group.name} alignRight bold>
            <Button label={t('common:remove')} type="secondary" onClick={() => removeUserFromGroup(group.id)} />
          </InlineEdit>
        ))}
      </ListGroup>
    </>
  );
};

export default InternalUsersDrawerGroupsTab;
