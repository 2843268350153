import { Option, Select } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Month } from 'src/types/Month';
import { convertMonthEnumToStringWithZeroes, useMonthOptions } from 'src/util/date/Months';
import { useYearOptions } from 'src/util/date/Years';

interface PeriodFilterProps {
  selectedMonth: Option<Month>;
  setSelectedMonth: React.Dispatch<React.SetStateAction<Option<Month>>>;
  selectedYear: Option<number>;
  setSelectedYear: React.Dispatch<React.SetStateAction<Option<number>>>;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<string>>;
}

const PeriodFilter = ({
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear,
  setSelectedPeriod,
}: PeriodFilterProps) => {
  const { t } = useTranslation();

  const handlePeriodChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedMonth(monthOption);
    setSelectedYear(yearOption);

    if (monthOption && yearOption) {
      const period = yearOption.label + '-' + convertMonthEnumToStringWithZeroes(monthOption.value);
      setSelectedPeriod(period);
    }
  };

  const monthOptions = useMonthOptions();
  const yearsOptions = useYearOptions();

  return (
    <div className="accounting-balance-tab__period-select">
      <div>
        <Select
          placeholder={!selectedMonth ? t('common:month').toLowerCase() : t(`common:months.${selectedMonth.value}`)}
          options={monthOptions}
          alphabetical={false}
          onChange={(option: Option<Month>) => handlePeriodChange(option, selectedYear)}
          selected={selectedMonth}
          data-testid="period-filter_month"
        />
      </div>
      <div>
        <Select
          placeholder={!selectedYear ? t('common:year').toLowerCase() : selectedYear.value.toString()}
          options={yearsOptions}
          onChange={(option: Option<number>) => handlePeriodChange(selectedMonth, option)}
          selected={selectedYear}
          data-testid="period-filter_year"
        />
      </div>
    </div>
  );
};

export default PeriodFilter;
