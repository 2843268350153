import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query ($customerId: UUID) {
    users(customerId: $customerId) {
      id
      firstName
      lastName
      customers {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;
