import { Button, Drawer, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { ChangeEvent, useState } from 'react';
import './add-customer-to-group.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { Customer } from 'src/types/Customer';
import { useInternalAddCustomerToGroup } from 'src/service/internalGroup/InternalAddCustomerToGroup';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';

interface AddCustomerToGroupProps {
  onClose: () => void;
  groupName: string;
  groupId: string;
  groupCustomers: Customer[];
  allCustomers: Customer[];
  breadcrumbs: string[];
}

const AddCustomerToGroup: React.FunctionComponent<AddCustomerToGroupProps> = ({
  onClose,
  groupName,
  groupId,
  groupCustomers,
  allCustomers,
  breadcrumbs,
}) => {
  const { t } = useTranslation();

  const addCustomerToInternalGroup = useInternalAddCustomerToGroup();

  const [searchCustomerInput, setSearchCustomerInput] = useState('');
  const searchCustomerInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchCustomerInput(event.target.value);
  };

  const addCustomer = (customer: Customer) => {
    addCustomerToInternalGroup(groupId, customer.id);
  };

  const customerAlreadyPartOfGroup = (customer: Customer) =>
    !groupCustomers.find((gCustomer) => gCustomer.id === customer.id);

  return (
    <Drawer
      leftButton={<Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onClose} />}
      type="elements"
      onClose={onClose}
    >
      <div className="add-customer-to-group__title">
        <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
        <Typography variant="h3" component="h3" bold>
          {t('permission:internal.groups-drawer.customers.add-new-customer')}
        </Typography>
        <div className="add-customer-to-group__sub-title">
          <Typography>{groupName}</Typography>
        </div>
        <ListGroup variant="inline-edit">
          <InlineEdit
            label={t('permission:internal.groups-drawer.customers.search-label')}
            className="add-customer-to-group__search-input"
          >
            <TextInput
              value={searchCustomerInput}
              onChange={searchCustomerInputOnChange}
              placeholder={t('common:name')}
              data-testid="search-input"
            />
          </InlineEdit>
        </ListGroup>
      </div>
      <ListGroup variant="inline-edit">
        {allCustomers
          .filter(customerAlreadyPartOfGroup)
          .filter((customer) => customer.name?.toLocaleLowerCase().includes(searchCustomerInput.toLocaleLowerCase()))
          .slice(0,15)
          .map((customer) => (
            <InlineEdit key={customer.id} label={customer.name} alignRight data-testid="customer-list-item">
              <Button label={t('common:add')} onClick={() => addCustomer(customer)} />
            </InlineEdit>
          ))}
      </ListGroup>
    </Drawer>
  );
};

export default AddCustomerToGroup;
