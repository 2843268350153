import React, { useState } from 'react';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SearchRejectedInvoicesPage } from 'src/types/Invoice';
import { getInitialRejectedInvoicesRequest } from './utils/rejectedInvoiceUtils';
import RejectedInvoicesTable from './RejectedInvoicesTable';
import RejectedInvoicesFilterDetails from './RejectedInvoicesFilterDetails';
import RejectedInvoicesFiltersModal from './RejectedInvoicesFiltersModal';
import { SearchRejectedInvoicesFilters, SearchRejectedInvoicesRequest } from 'src/types/ShearchRejectedInvoicesRequest';
import { SEARCH_REJECTED_INVOICES } from 'src/graphql/schema/query/SearchRejectedInvoices';
import RejectedInvoiceDrawer from './RejectiedInvoiceDrawer';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import './rejected-invoices-tab.scss';

const RejectedInvoicesTab = () => {
  const { t } = useTranslation();
  const [searchRejectedInvoicesRequest, setSearchRejectedInvoicesRequest] = useState<SearchRejectedInvoicesRequest>(
    getInitialRejectedInvoicesRequest(),
  );
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleDrawerOpen = (id: string) => {
    setSelectedInvoiceId(id);
  };

  const handleDrawerClose = () => {
    setSelectedInvoiceId(undefined);
  };

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const handleApplyFilters = (filters: SearchRejectedInvoicesFilters) => {
    setSearchRejectedInvoicesRequest({ ...searchRejectedInvoicesRequest, filters });
  };

  return (
    <QueryWrapper
      query={SEARCH_REJECTED_INVOICES}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: { pageNumber: queryPageNumber, pageSize, filters: searchRejectedInvoicesRequest.filters },
        },
      }}
    >
      {({ internalSearchRejectedInvoices, paging }: SearchRejectedInvoicesPage) => {
        const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
        const showPagination = totalPages > 1;

        return (
          <>
            <div className="rejected-invoices-tab">
              <RejectedInvoicesFilterDetails
                searchRequest={searchRejectedInvoicesRequest}
                applyFilters={handleApplyFilters}
              />
              <div className="rejected-invoices-tab">
                <RejectedInvoicesTable invoices={internalSearchRejectedInvoices} onClick={handleDrawerOpen} />
              </div>
              {showPagination && (
                <div data-testid="rejected-invoices-tab-pagination">
                  <Pagination
                    currentPage={pageNumber}
                    onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </div>
            {filtersModalOpen && (
              <RejectedInvoicesFiltersModal
                open={filtersModalOpen}
                onClose={handleCloseModal}
                onApplyFilters={handleApplyFilters}
                filters={searchRejectedInvoicesRequest?.filters}
              />
            )}
            <div className="rejected-invoices-tab__filter-button">
              <Button
                type="floating-action-button"
                icon={<Icon type={'Sliders'} stroke="white" />}
                label={t('common:filter')}
                onClick={handleModalOpen}
                data-cy="filter-button"
              />
            </div>
            {selectedInvoiceId && (
              <RejectedInvoiceDrawer
                open={selectedInvoiceId !== undefined}
                invoice={internalSearchRejectedInvoices.find((invoice) => invoice.id === selectedInvoiceId)}
                onClose={handleDrawerClose}
              />
            )}
          </>
        );
      }}
    </QueryWrapper>
  );
};
export default RejectedInvoicesTab;
