import { Banner, Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_DOCUMENT_TEMPLATES } from 'src/graphql/schema/query/documenttemplate/GetDocumentTemplates';
import { useCreateDocumentTemplateEmployerAssignment } from 'src/service/DocumentTemplateEmployerAssignment';
import { CreateDocumentTemplateEmployerAssignmentInput } from 'src/types/documenttemplate/CreateDocumentTemplateEmployerAssignmentInput';
import { DocumentTemplate } from 'src/types/documenttemplate/DocumentTemplate';
import { createOptions } from 'src/util/OptionUtil';
import './add-template-employer-assignment-modal.scss';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';

interface AddTemplateEmployerAssignmentModalProps {
  open: boolean;
  employerId: string;
  customerId: string;
  onClose: () => void;
  employerAssignments: DocumentTemplateEmployerAssignment[];
}
const AddTemplateEmployerAssignmentModal: FC<AddTemplateEmployerAssignmentModalProps> = ({
  open,
  employerId,
  customerId,
  onClose,
  employerAssignments,
}) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<CreateDocumentTemplateEmployerAssignmentInput>({
    templateId: undefined,
    templateName: '',
    employerId,
    formatType: undefined,
  });

  const createDocumentTemplateEmployerAssignment = useCreateDocumentTemplateEmployerAssignment();

  const handleClose = () => {
    setRequest({
      templateId: undefined,
      templateName: '',
      employerId,
      formatType: undefined,
    });
    onClose();
  };

  const handleReportChange = (option: Option<DocumentTemplate>) =>
    setRequest((prev) => ({
      ...prev,
      templateId: option.value.id,
      templateName: option.value.name,
      formatType: option.value.formatType,
    }));

  return (
    <QueryWrapper query={GET_DOCUMENT_TEMPLATES}>
      {(documentTemplates: DocumentTemplate[]) => {
        const filteredTemplates = documentTemplates.filter( dt => !employerAssignments.find( ea => ea.templateId === dt.id));
        const templatesOptions = createOptions(filteredTemplates, null, null, 'name');

        const templateIds = documentTemplates.map((template) => template.id);
        const templateNames = documentTemplates.map((template) => template.name);
        const templateFormatTypes = documentTemplates.map((template) => template.formatType);

        const handleCreateDocumentTemplateEmployerAssignment = () => {
          const input: CreateDocumentTemplateEmployerAssignmentInput = {
            templateId: templateIds.find((id) => id === request.templateId),
            templateName: templateNames.find((name) => name === request.templateName),
            employerId: employerId,
            formatType: templateFormatTypes.find((formatType) => formatType === request.formatType),
          };
          return createDocumentTemplateEmployerAssignment(input, employerId, customerId).then(handleClose);
        };
        return (
          <Modal centered overlayBackground open={open} onClose={onClose}>
            <Card
              className="add-template-employer-assignment-modal"
              title={t('documents:templates-tab.add-template-modal.title')}
              defaultClose
              onClose={onClose}
            >
              <Select
                label={t('documents:templates-tab.add-template-modal.report-label')}
                placeholder={t('documents:templates-tab.add-template-modal.report-placeholder')}
                selected={templatesOptions.find((option) => option.value.id === request.templateId)}
                options={templatesOptions}
                onChange={handleReportChange}
                className="add-template-employer-assignment-modal__dropdown"
                small
                outlined
              />

              {filteredTemplates.length === 0 && (
                <Banner type="announcement" message={t('documents:templates-tab.all-templates-has-been-assigned')}/>
              )}

              <CardAction>
                <Button type="link" label={t('common:cancel')} onClick={handleClose} />
                <Button label={t('common:add')} onClick={handleCreateDocumentTemplateEmployerAssignment} />
              </CardAction>
            </Card>
          </Modal>
        );
      }}
    </QueryWrapper>
  );
};

export default AddTemplateEmployerAssignmentModal;
