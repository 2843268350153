import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import DrawerBase from 'src/components/Common/DrawerBase';
import { PaymentNote } from 'src/types/PaymentNote';
import { PaymentDrawerTab } from 'src/types/tabs/PaymentDrawerTab';
import { Payment } from 'src/types/Payment';
import PaymentNoteTab from './PaymentNoteTab';
import { useTranslation } from 'react-i18next';

interface PaymentDrawerOverviewProps {
  payment: Payment;
  paymentNotes: PaymentNote[];
  onClose: () => void;
  onAddNewPaymentNote: () => void;
  onSelectPaymentNote: (id: string) => void;
  currentTab: PaymentDrawerTab;
}

const PaymentDrawerOverview: FC<PaymentDrawerOverviewProps> = ({
  payment,
  paymentNotes,
  onClose,
  onAddNewPaymentNote,
  onSelectPaymentNote,
  currentTab,
}) => {
  const { t } = useTranslation();
  return (
    <DrawerBase
      onClose={onClose}
      title={`${t('economy:payment-drawer.drawer-title')} ${payment?.invoice.invoiceNumber}`}
      subTitle={
        <div className="payment-drawer__sub-title">
          <Typography>{payment.employer.legalName}</Typography>
          <Pill label={payment.invoice.insuranceProvider?.legalName} />
        </div>
      }
      data-testid={`payment-drawer-overview-id`}
    >
      <Tabs tabIndex={currentTab}>
        <Tab title={t('economy:payment-drawer.payment-notes-tab.tab-label')}>
          <PaymentNoteTab
            onAddNewPaymentNote={onAddNewPaymentNote}
            onSelectPaymentNote={onSelectPaymentNote}
            paymentNotes={paymentNotes}
          />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default PaymentDrawerOverview;
