import { Checkbox, TableRow, Td } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Payment } from 'src/types/Payment';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import './payments-table-row.scss';
import { formatTime } from 'src/util/date/PeriodUtil';

interface PaymentsTableRowProps {
  payment: Payment;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onRowClick: (id: string) => void;
}
const PaymentsTableRow: FC<PaymentsTableRowProps> = ({ payment, checked, onChange, onRowClick }) => {
  const { t } = useTranslation();

  return (
    <TableRow
      className="payments-table-row"
      data-testid={`payments-table-row__${payment.id}`}
      onClick={(e) => {
        if ((e.target as HTMLElement).tagName !== 'INPUT') {
          onRowClick(payment.id);
        }
      }}
    >
      <Td compressed>
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={checked} onChange={onChange} />
        </div>
      </Td>
      <Td compressed>{stringOrBlank(payment.employer?.legalName)}</Td>
      <Td compressed>{stringOrBlank(payment.invoice.insuranceProvider?.legalName)}</Td>
      <Td compressed>{payment.invoice.invoiceNumber}</Td>
      <Td compressed>{formatSweAmountText(`${payment.amount}`)}</Td>
      <Td compressed>{stringOrBlank(t(`payment:payment-status.${payment.paymentStatus}`))}</Td>
      <Td compressed>{payment.errorCode ? t(`economy:payments-tab.error-codes.${payment.errorCode}`) : '-'}</Td>
      <Td compressed>{stringOrBlank(payment.paymentDueDate)}</Td>
      <Td compressed>{stringOrBlank(payment.paymentCreatedDate).substring(0, 10)}</Td>
      <Td compressed>{formatTime(payment.paymentCreatedDate)}</Td>
      <Td compressed>{t(`payment:payment-service-provider.${payment.paymentServiceProvider}`)}</Td>
      <Td compressed>{t(`payment:bank.${payment.bank}`)}</Td>
      <Td compressed>{payment.accountFrom}</Td>
      <Td compressed>{payment.accountTo}</Td>
      <Td compressed>
        {payment.numberOfNotes > 0
          ? `${payment.numberOfNotes} ${
              payment.numberOfNotes === 1 ? t('economy:payments-tab.note') : t('economy:payments-tab.notes')
            }`
          : '-'}
      </Td>
    </TableRow>
  );
};

export default PaymentsTableRow;
