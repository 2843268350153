import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { Employer } from 'src/types/Employer';

interface CustomerDetailsRowProps {
  employer: Employer;
}

const CustomerDetailsRow: FC<CustomerDetailsRowProps> = ({ employer }) => {
  return (
    <TableRow className={['customer-details-row'].join(' ')}>
      <Td>{employer.legalName}</Td>
      <Td>{employer.organisationNumber}</Td>
    </TableRow>
  );
};

export default CustomerDetailsRow;
