import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceProduct } from 'src/types/InsuranceProduct';
import './insurance-products-table-row.scss';

interface InsuranceProductsTableRowProps {
  insuranceProduct: InsuranceProduct;
  onClick: (insuranceProductId: string) => void;
}

const InsuranceProductsTableRow: FC<InsuranceProductsTableRowProps> = ({ insuranceProduct, onClick }) => {
  const { t } = useTranslation();

  return (
    <TableRow className={['insurance-products-table-row'].join(' ')} onClick={() => onClick(insuranceProduct.id)}>
      <Td>{insuranceProduct.name}</Td>
      <Td>{insuranceProduct.insuranceProvider.legalName}</Td>
      <Td>{t(`insuranceManager:product-status.${insuranceProduct.status}`)}</Td>
    </TableRow>
  );
};

export default InsuranceProductsTableRow;
