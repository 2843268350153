import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SEARCH_INVOICES } from 'src/graphql/schema/query/SearchInvoices';
import { SearchInvoicesPage } from 'src/types/Invoice';
import { SearchInvoicesFilters, SearchInvoicesRequest } from 'src/types/SearchInvoicesRequest';
import { getInitialSearchInvoicesRequest } from './utils/invoiceUtils';
import Invoices from './Invoices';
import './invoices-tab.scss';

const InvoicesTab = () => {
  const [searchInvoicesRequest, setSearchInvoicesRequest] = useState<SearchInvoicesRequest>(
    getInitialSearchInvoicesRequest(),
  );

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleTextSearch = (searchInput: string) => {
    setSearchInvoicesRequest({
      ...searchInvoicesRequest,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: SearchInvoicesFilters) => {
    const updatedFilters = {
      ...filters,
      invoiceTypeCodesFilter: ['DEBIT'],
    };
    setSearchInvoicesRequest({ ...searchInvoicesRequest, filters: updatedFilters });
  };

  return (
    <QueryWrapper
      query={SEARCH_INVOICES}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: {
            searchInput: searchInvoicesRequest?.searchInput,
            pageNumber: queryPageNumber,
            pageSize: pageSize,
            filters: searchInvoicesRequest?.filters,
          },
        },
      }}
    >
      {({ internalSearchInvoices, paging }: SearchInvoicesPage) => (
        <Invoices
          invoices={internalSearchInvoices}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          searchInvoicesRequest={searchInvoicesRequest}
          onTextSearch={handleTextSearch}
          onApplyFilters={handleApplyFilters}
          onPageChange={handleOnPageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default InvoicesTab;
