/**
 * Parse a user input text string representing an amount to computer format
 *
 * Swedish user format: 45 120,12
 * Computer format: 45120.12
 *
 * @param inString User Amount format
 */
export const parseSweAmountText = (inString: string): string => {
  return inString.trim().replaceAll(new RegExp(`[^0-9.,]`, 'g'), '').replace(',', '.');
};

/**
 * Format an amount from computer format to a Swedish amount string representation
 *
 * Computer format: 45120.12
 * Swedish user format: 45 120,12
 *
 * @param rawString Computer Amount to format
 */
export const formatSweAmountText = (rawString: string): string => {
  if (rawString && rawString.length > 0) {
    const parts: string[] = rawString.split('.');
    const formattedNumber = new Intl.NumberFormat('sv-SE').format(parseInt(parts[0]));
    const formattedDecimal = parts.length > 1 ? ',' + parts[1].padEnd(2, '0') : '';
    return formattedNumber + formattedDecimal;
  }
  return rawString;
};

/**
 * Validate that raw string confirms to computer amount format
 *
 * Computer format: 123456789.1234
 *
 * @param rawString Computer Amount to validate
 */
export const validateAmountWithDec = (rawString: string): boolean => {
  return rawString && new RegExp('^([0-9]{1,9}((\\.)[0-9]{0,2})?)$').test(rawString);
};
