import { gql } from '@apollo/client';

export const GET_PERIOD_AGGREGATIONS = gql`
  query InternalPeriodAggregations(
    $accountingPeriod: String
    $onlyImbalance: Boolean
    $pageSize: Int
    $pageNumber: Int
  ) {
    internalPeriodAggregations(
      accountingPeriod: $accountingPeriod
      onlyImbalance: $onlyImbalance
      pageSize: $pageSize
      pageNumber: $pageNumber
    ) {
      paging {
        hasNext
        totalNumberOfElements
      }
      internalPeriodAggregations {
        id
        employer {
          id
          legalName
        }
        balancing
        invoiceAggregations {
          id
          invoice {
            id
            invoiceNumber
          }
          balance
          premiumBalance
          salaryTaxBalance
        }
      }
    }
  }
`;
