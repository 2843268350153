import React, { useState } from 'react';
import './customers.scss';
import {Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import PageBase from 'src/components/PageBase';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_CUSTOMERS } from 'src/graphql/schema/query/customer/GetCustomers';
import { GetCustomerPage } from 'src/types/Customer';
import CustomersTable from 'src/components/Customers/CustomersTable';
import { getTotalPages } from 'src/util/Pagination/Pagination';

const Customers = () => {
  const { t } = useTranslation();
  const [customersPageNumber, setCustomersPageNumber] = useState(1);
  const customersQueryPageNumber = customersPageNumber - 1;
  const pageSize = 15;

  return (
    <PageBase title={t('customers:title')} header={true}>
      <QueryWrapper query={GET_CUSTOMERS} options={{ variables: { pageSize, pageNumber: customersQueryPageNumber } }}>
        {({ customers, paging }: GetCustomerPage) => {
          const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
          const showPagination = totalPages > 1;
          return (
            <>
              {customers && (
                <>
                  <CustomersTable customers={customers} />
                  {showPagination && (
                    <Pagination
                      currentPage={customersPageNumber}
                      onPageChange={(nextPageNumber) => setCustomersPageNumber(nextPageNumber)}
                      totalPages={totalPages}
                    />
                  )}
                </>
              )}
            </>
          );
        }}
      </QueryWrapper>
      {/* <div className="customers__create-customer-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'PlusLarge'} />}
          label={t('customers:add-customer-title')}
          onClick={handleOpenModal}
        />
      </div>
      <CreateCustomerModal open={modalOpen} onClose={handleCloseModal} /> */}
    </PageBase>
  );
};

export default Customers;
