import { gql } from '@apollo/client';

export const SEARCH_INSURANCE_PROVIDERS = gql`
  query InternalInsuranceProviders($request: SearchInsuranceProvidersRequest) {
    internalInsuranceProviders(request: $request) {
      paging {
        hasNext
        totalNumberOfElements
      }
      internalInsuranceProviders {
        id
        legalName
        organisationNumber
      }
    }
  }
`;
