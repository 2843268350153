import { gql } from '@apollo/client';

export const GET_REPORTING_RULE = gql`
  query ($ruleId: UUID!) {
    reportingRule(ruleId: $ruleId) {
      id
      insuranceEventType
      availableMessageFormat
      messageFormatCategory
    }
  }
`;
