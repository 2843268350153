export const sortAlphabeticalDesc = (a: string, b: string) =>
  b?.toLocaleLowerCase().localeCompare(a?.toLocaleLowerCase());

export const sortAlphabeticalAsc = (a: string, b: string) =>
  a?.toLocaleLowerCase().localeCompare(b?.toLocaleLowerCase());
export const sortNumericalAsc = (a: string | number, b: string | number) =>
  convertNumberIfString(a) - convertNumberIfString(b);

export const sortNumericalDesc = (a: string | number, b: string | number) =>
  convertNumberIfString(b) - convertNumberIfString(a);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore because it doesnt like subtracting dates
export const sortDateAsc = (a: Date | string, b: Date | string) => convertDateIfString(a) - convertDateIfString(b);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore because it doesnt like subtracting dates
export const sortDateDesc = (a: Date | string, b: Date | string) => convertDateIfString(b) - convertDateIfString(a);

const convertNumberIfString = (val: string | number): number => (typeof val === 'string' ? parseInt(val) : val);

const convertDateIfString = (val: string | Date): Date => (typeof val === 'string' ? new Date(val) : val);
