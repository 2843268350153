import React, { useEffect, useState } from 'react';
import { FgLogo, Icon, Navigation, NavigationTab } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { navOptions } from './NavOptions';

import { useShowNavElements } from 'src/service/routes/LocationHooks';

const NavigationBar = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number>();
  const shouldShowNavElements = useShowNavElements();
  const { t } = useTranslation();

  useEffect(() => {
    setSelected(navOptions.find((option) => location.pathname.includes(option.label))?.value);
  }, [location.pathname]);

  return (
    shouldShowNavElements && (
      <Navigation logo={<FgLogo />} selected={selected} variant="secondary">
        <NavigationTab
          title={t('navigation:customers')}
          onClick={() => navigate('/customers')}
          icon={<Icon type="Users" />}
        />
        <NavigationTab
          title={t('navigation:economy')}
          onClick={() => navigate('/economy')}
          icon={<Icon type="Invoice" />}
        />
        <NavigationTab
          title={t('navigation:products')}
          onClick={() => navigate('/products')}
          icon={<Icon type="Info" />}
        />
        <NavigationTab
          title={t('navigation:documents')}
          onClick={() => navigate('/documents')}
          icon={<Icon type="Clipboard" />}
        />
        <NavigationTab
          title={t('navigation:permission')}
          onClick={() => navigate('/permission')}
          icon={<Icon type="Users" />}
        />
        <NavigationTab
          title={t('navigation:monitoring')}
          onClick={() => navigate('/monitoring')}
          icon={<Icon type="Sliders" />}
        />
      </Navigation>
    )
  );
};
export default NavigationBar;
