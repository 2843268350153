import { CREATE_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENT } from 'src/graphql/schema/mutation/documenttemplate/CreateDocumentTemplateEmployerAssignment';
import { DELETE_TEMPLATE_EMPLOYER_ASSIGNMENT } from 'src/graphql/schema/mutation/documenttemplate/DeleteTemplateEmployerAssignment';
import { GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS } from 'src/graphql/schema/query/documenttemplate/GetDocumentTemplateEmployerAssignments';
import { CreateDocumentTemplateEmployerAssignmentInput } from 'src/types/documenttemplate/CreateDocumentTemplateEmployerAssignmentInput';
import { useApolloMutation } from './graphql/Mutation';

export const useCreateDocumentTemplateEmployerAssignment = () => {
  const apolloMutation = useApolloMutation();

  const createDocumentTemplateEmployerAssignment = (
    request: CreateDocumentTemplateEmployerAssignmentInput,
    employerId: string,
    customerId: string,
  ) =>
    apolloMutation(CREATE_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENT, employerId, { request }, [
      GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS,
    ], customerId);

  return createDocumentTemplateEmployerAssignment;
};

export const useDeleteTemplateEmployerAssignment = () => {
  const apolloMutation = useApolloMutation();

  const deleteTemplateEmployerAssignment = (employerAssignmentId: string, employerId: string) =>
    apolloMutation(DELETE_TEMPLATE_EMPLOYER_ASSIGNMENT, employerId, { employerAssignmentId }, [
      GET_DOCUMENT_TEMPLATE_EMPLOYER_ASSIGNMENTS,
    ]);

  return deleteTemplateEmployerAssignment;
};
