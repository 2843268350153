import { client } from 'src/graphql';
import { DELETE_REPORTING_RULE } from 'src/graphql/schema/mutation/DeleteReportingRule';
import { GET_REPORTING_RULE_CONFIGURATION } from 'src/graphql/schema/query/GetReportingRuleConfiguration';
import { SEARCH_REPORTING_RULE_CONFIGURATIONS } from 'src/graphql/schema/query/SearchReportingRuleConfigurations';

export const deleteReportingRule = (reportingRuleConfigurationId: string, reportingRuleId: string) =>
  client
    .mutate({
      mutation: DELETE_REPORTING_RULE,
      variables: { reportingRuleConfigurationId, reportingRuleId },
    })
    .then(() => {
      client.refetchQueries({
        include: [GET_REPORTING_RULE_CONFIGURATION, SEARCH_REPORTING_RULE_CONFIGURATIONS],
      });
    });
