import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { SearchInvoicesFilters, SearchInvoicesRequest } from 'src/types/SearchInvoicesRequest';
import './invoices-filter-details.scss';

interface InvoicesFilterDetailsProps {
  searchRequest: SearchInvoicesRequest;
  applyFilters: (filters: SearchInvoicesFilters) => void;
}

export const InvoicesFilterDetails: FC<InvoicesFilterDetailsProps> = ({ searchRequest, applyFilters }) => {
  const { t } = useTranslation();
  const filtersApplied =
    searchRequest.filters?.paymentDueDateFilter?.length > 0 ||
    searchRequest.filters?.banksFilter?.length > 0 ||
    searchRequest.filters?.paymentServiceProvidersFilter?.length > 0;

  const removePaymentDueDateFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      paymentDueDateFilter: '',
    });
  };

  const removeBanksFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      banksFilter: null,
    });
  };

  const removePaymentServiceProvidersFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      paymentServiceProvidersFilter: null,
    });
  };

  return (
    <div className="invoices-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {searchRequest.filters?.paymentDueDateFilter?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.paymentDueDateFilter +
                ' ' +
                t('economy:search-invoices-filters.payment-due-date')
              }
              onClick={removePaymentDueDateFilter}
            />
          )}
          {searchRequest.filters?.banksFilter?.length > 0 && (
            <FilterButton
              label={searchRequest.filters?.banksFilter.length + ' ' + t('economy:search-invoices-filters:bank')}
              onClick={removeBanksFilter}
            />
          )}
          {searchRequest.filters?.paymentServiceProvidersFilter?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.paymentServiceProvidersFilter.length +
                ' ' +
                t('economy:search-invoices-filters:payment-service-provider')
              }
              onClick={removePaymentServiceProvidersFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InvoicesFilterDetails;
