import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import AdminUsersTableRow from './AdminUsersTableRow';
import { ExternalUser } from 'src/types/ExternalUser';

interface AdminUsersTable {
  adminUsers: ExternalUser[];
  onClick: (userId: string) => void;
}

const AdminUsersTable: FC<AdminUsersTable> = ({ adminUsers, onClick }) => {
  const { t } = useTranslation();

  const head = [
    { name: t('permission:internal.users-table-head.name'), centered: false },
    { name: t('permission:internal.users-table-head.email'), centered: false },
    { name: t('permission:internal.users-table-head.customer'), centered: false },
  ];
  return (
    <div data-testid="admin-users-table">
      <TableBase maxHeightPercentage={60}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th centered={title.centered} key={`AdminUsersTable-${title.name}-${index}`}>
                {title.name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {adminUsers.map((user, index) => (
            <AdminUsersTableRow
              key={`AdminUsersTableRow-${index}`}
              externalUser={user}
              onClick={() => onClick(user.user.id)}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default AdminUsersTable;
