import { InlineEdit, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { GET_BANKGIRO_NUMBERS } from 'src/graphql/schema/query/GetBankgiroNumbers';
import { BankgiroNumber } from 'src/types/BankgiroNumber';

interface BankgiroNumbersTabProps {
  insuranceProvider: InsuranceProvider;
}

function BankgiroNumbersTab({ insuranceProvider }: BankgiroNumbersTabProps) {
  const { t } = useTranslation();
  return (
    <QueryWrapper query={GET_BANKGIRO_NUMBERS} options={{ variables: { insuranceProviderId: insuranceProvider.id } }}>
      {(bankgiroNumbers: BankgiroNumber[]) => {
        return (
          <div>
            {bankgiroNumbers.map((bankgiroNumber) => (
              <InlineEdit
                key={`insurance-providers-details-${bankgiroNumber.id}`}
                label={t('insuranceManager:bankgiro-number')}
                bold
              >
                <Typography variant="body2">{bankgiroNumber.number}</Typography>
              </InlineEdit>
            ))}
          </div>
        );
      }}
    </QueryWrapper>
  );
}

export default BankgiroNumbersTab;
