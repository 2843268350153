import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { GetGroup } from 'src/types/Group';
import GroupsTableRow from './GroupsTableRow';


interface GroupsTableProps {
  groups: GetGroup[];
  onClick: (groupId:string) => void;
}

const GroupsTable: FC<GroupsTableProps> = ({groups, onClick}) => {
  const { t } = useTranslation();

  const head = [
    { name: t('permission:internal.groups-table-head.name'), centered: false },
    { name: t('permission:internal.groups-table-head.customers'), centered: false },
    { name: t('permission:internal.groups-table-head.users'), centered: false },
    { name: t('permission:internal.groups-table-head.roles'), centered: false },
  ];

  return (
    <div data-testid="groups-table">
      <TableBase maxHeightPercentage={60}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th centered={title.centered} key={`GroupsTable-${title.name}-${index}`}>
                {title.name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group, index) => (
            <GroupsTableRow key={`GroupsTableRow-${index}`} group={group} onClick={onClick} />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default GroupsTable;


