import { client } from 'src/graphql';
import { CREATE_REPORTING_RULE } from 'src/graphql/schema/mutation/CreateReportingRule';
import { GET_REPORTING_RULE } from 'src/graphql/schema/query/GetReportingRule';
import { GET_REPORTING_RULE_CONFIGURATION } from 'src/graphql/schema/query/GetReportingRuleConfiguration';
import { SEARCH_REPORTING_RULE_CONFIGURATIONS } from 'src/graphql/schema/query/SearchReportingRuleConfigurations';
import { ReportingRule } from 'src/types/ReportingRule';
import { ReportingRuleRequest } from 'src/types/request/ReportingRuleRequest';

export const createReportingRule = (
  reportingRuleConfigurationId: string,
  request: ReportingRuleRequest,
): Promise<ReportingRule> => {
  return client
    .mutate({
      mutation: CREATE_REPORTING_RULE,
      variables: {
        reportingRuleConfigurationId,
        request,
      },
    })
    .then((res) => {
      client.refetchQueries({
        include: [GET_REPORTING_RULE, GET_REPORTING_RULE_CONFIGURATION, SEARCH_REPORTING_RULE_CONFIGURATIONS],
      });
      return res.data.createReportingRule;
    });
};
