interface WhenProps {
        condition: boolean;
        children: JSX.Element | JSX.Element[];
      }
      
      const When = ({ children, condition }: WhenProps) => {
        if (condition) return Array.isArray(children) ? <>{children}</> : children;
        return null;
      };
      
      export default When;
      