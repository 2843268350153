import common from './common.json';
import dashboard from './dashboard.json';
import customer from './customer.json';
import customers from './customers.json';
import navigation from './navigation.json';
import report from './report.json';
import fileUpload from './file-upload.json';
import manualUpload from './manual-upload.json';
import months from './months.json';
import days from './days.json';
import datatable from './datatable.json';
import singleFileError from './single-file-error.json';
import companyUploadStatus from './company-upload-status.json';
import errors from './errors.json';
import violationTypes from './violation-types.json';
import wageTypes from './wage-types.json';
import validatingTable from './validating-table.json';
import employmentTypes from './employment-types.json';
import employmentCategories from './employment-categories.json';
import examinationTable from './examination-table.json';
import certifyTable from './certify-table.json';
import periodDifference from './period-difference.json';
import searchTerms from './search-terms.json';
import employeeFilter from './employee-filter.json';
import subActionCategories from './subaction-categories.json';
import remunerationTypes from './remuneration-types.json';
import validationErrors from './validation-errors.json';
import examinationViolationTypes from './examination-violation-types.json';
import benefitTypeAbbrevations from './benefit-type-abbreviations.json';
import insuranceEventTypes from './insurance-event-types.json';
import benefitTypes from './benefit-types.json';
import certifyConditionsModal from './certify-conditions-modal.json';
import certifyEmployeeDrawer from './certify-employee-drawer.json';
import accountEmployers from './account-employers.json';
import insuranceEventTable from './insurance-event-table.json';
import employmentCheckpointSettings from './employment-checkpoint-settings.json';
import insuranceManager from './insurance-manager.json';
import identifierTypes from './identifier-types.json';
import economy from './economy.json';
import permission from './permission.json';
import invoice from './invoice.json';
import payment from './payment.json';
import monitoring from './monitoring.json';
import reporting from './reporting.json';
import userFeedback from './user-feedback.json';
import rolesTranslations from './roles-translations.json';
import insuranceTypeMapping from './insurance-type-mapping.json';
import documents from './documents.json';

export default {
  common,
  dashboard,
  customer,
  customers,
  navigation,
  report,
  fileUpload,
  manualUpload,
  months,
  days,
  datatable,
  singleFileError,
  companyUploadStatus,
  errors,
  violationTypes,
  wageTypes,
  validatingTable,
  employmentTypes,
  examinationTable,
  periodDifference,
  searchTerms,
  employeeFilter,
  subActionCategories,
  employmentCategories,
  remunerationTypes,
  validationErrors,
  examinationViolationTypes,
  certifyTable,
  benefitTypeAbbrevations,
  insuranceEventTypes,
  benefitTypes,
  certifyConditionsModal,
  certifyEmployeeDrawer,
  accountEmployers,
  insuranceEventTable,
  employmentCheckpointSettings,
  insuranceManager,
  identifierTypes,
  economy,
  permission,
  invoice,
  payment,
  monitoring,
  reporting,
  userFeedback,
  rolesTranslations,
  insuranceTypeMapping,
  documents,
};
