import { ADD_INTERNAL_ROLE_TO_INTERNAL_GROUP } from 'src/graphql/schema/mutation/internalGroup/InternalAddRoleToGroup';
import { useApolloMutation } from '../graphql/Mutation';
import { GET_INTERNAL_GROUP } from 'src/graphql/schema/query/GetInternalGroup';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';

export const useInternalAddRoleToGroup = () => {
  const apolloMutation = useApolloMutation(false);

  const internalAddRoleToGroup = (groupId: string, roleId: string) =>
    apolloMutation(ADD_INTERNAL_ROLE_TO_INTERNAL_GROUP, '', { groupId, roleId }, [GET_INTERNAL_GROUP, GET_INTERNAL_GROUPS]);

  return internalAddRoleToGroup;
};
