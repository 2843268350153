import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceTypeMapping } from 'src/types/InsuranceTypeMapping';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';

interface InsuranceTypeMappingTableRowProps {
  insuranceTypeMapping: InsuranceTypeMapping;
  onRowClick: (id: string) => void;
}

const InsuranceTypeMappingTableRow: FC<InsuranceTypeMappingTableRowProps> = ({ insuranceTypeMapping, onRowClick }) => {
  const { t } = useTranslation();

  const printInvoiceRowNames = (insuranceTypeMapping: InsuranceTypeMapping): string => {
    if (insuranceTypeMapping.invoiceRowMappings.length === 0) {
      return '-';
    } else if (insuranceTypeMapping.invoiceRowMappings.length === 1) {
      return insuranceTypeMapping.invoiceRowMappings[0].invoiceRowName;
    } else if (insuranceTypeMapping.invoiceRowMappings.length > 1) {
      return (
        `${insuranceTypeMapping.invoiceRowMappings.length} ` +
        t('insuranceManager:insurance-type-mappings-tab.table.invoice-rows')
      );
    }
  };

  return (
    <TableRow
      data-testid={`insurance-type-mapping-table-row__${insuranceTypeMapping.id}`}
      onClick={() => onRowClick(insuranceTypeMapping.id)}
    >
      <Td>{stringOrBlank(insuranceTypeMapping.insuranceProvider.legalName)}</Td>
      <Td>{insuranceTypeMapping.name ? stringOrBlank(insuranceTypeMapping.name) : '-'}</Td>
      <Td>{printInvoiceRowNames(insuranceTypeMapping)}</Td>
      <Td>
        {insuranceTypeMapping.benefitType ? stringOrBlank(t(`benefitTypes:${insuranceTypeMapping.benefitType}`)) : '-'}
      </Td>
      <Td>{stringOrBlank(t(`insuranceTypeMapping:insurance-category.${insuranceTypeMapping.insuranceCategory}`))}</Td>
      <Td>
        {insuranceTypeMapping.taxRule
          ? stringOrBlank(t(`insuranceTypeMapping:tax-rule.${insuranceTypeMapping.taxRule}`))
          : '-'}
      </Td>
      <Td>{stringOrBlank(t(`insuranceTypeMapping:premium-source.${insuranceTypeMapping.premiumSource}`))}</Td>
    </TableRow>
  );
};
export default InsuranceTypeMappingTableRow;
