import { gql } from '@apollo/client';

export const CREATE_REPORTING_RULE = gql`
  mutation ($reportingRuleConfigurationId: UUID!, $request: ReportingRuleRequest!) {
    createReportingRule(reportingRuleConfigurationId: $reportingRuleConfigurationId, request: $request) {
      id
      insuranceEventType
      availableMessageFormat
    }
  }
`;
