import { useEffect, useState } from 'react';
import { CreateExternalUser } from 'src/types/ExternalUser';
import { IdentifierType } from 'src/types/User';
import { inputSchema } from 'src/validation/schemas/inputSchema';

export const useValidateCreateAdminUserForm = (
  request: CreateExternalUser,
  selectedConnection: string,
  selectedCustomerId: string,
) => {
  const [hasInvalidPatterns, setHasInvalidPatterns] = useState(true);

  useEffect(() => {
    const firstNameValidation = inputSchema.validate({
      text255: request.firstName,
    });
    const lastNameValidation = inputSchema.validate({
      text255: request.lastName,
    });
    
    const identifierValidation = validateIdentifier(request);

    const phoneNumberValidation = inputSchema.validate({
      phoneNumber: request.phone,
    });
    const emailValidation = inputSchema.validate({
      email: request.email,
    });

    const orderRefValidation = inputSchema.validate({
      text255: request.orderRef,
    });

    const invalidPatternFound = [
      firstNameValidation,
      lastNameValidation,
      identifierValidation,
      phoneNumberValidation,
      emailValidation,
      orderRefValidation,
    ].some((item) => item.error);

    setHasInvalidPatterns(invalidPatternFound);
  }, [request.identifier, request.phone, request.email, request.firstName, request.lastName, request.orderRef, request.identifierType]);

  const validateIdentifier = (request: CreateExternalUser) => {
    if (request.identifierType === IdentifierType.BANK_ID)
      return inputSchema.validate({
        personNumber: request.identifier,
      });
    if (request.identifierType === IdentifierType.E_ID)
      return inputSchema.validate({
        expisoft: request.identifier,
      });

    return { error: {} };
  };

  const connectionLimitedWithNoValidToDate = selectedConnection === 'LIMITED' && !request.validTo?.length;
  return (
    !request.lastName ||
    !request.firstName ||
    !request.identifier ||
    !request.email ||
    !request.phone ||
    !selectedCustomerId ||
    connectionLimitedWithNoValidToDate ||
    hasInvalidPatterns
  );
};

export default useValidateCreateAdminUserForm;
