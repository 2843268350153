import React from 'react';
import './permission-module-card-box.scss';
import { Typography } from 'fgirot-k2-ui-components';

interface PermissionModuleCardBoxProps {
  title: string;
  subTitle: string;
}

const PermissionModuleCardBox = ({ title, subTitle }: PermissionModuleCardBoxProps) => {
  return (
    <div className="permission-module-card-box">
      <Typography variant="caption" bold className="permission-module-card-box__title">
        {title}
      </Typography>
      <Typography variant="body1" bold className="permission-module-card-box__subTitle">
        {subTitle}
      </Typography>
    </div>
  );
};

export default PermissionModuleCardBox;
