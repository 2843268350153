import { Option } from 'src/types/Option';

export const navOptions: Option<number>[] = [
  { label: 'customers', value: 0 },
  { label: 'economy', value: 1 },
  { label: 'products', value: 2 },
  { label: 'documents', value: 3 },
  { label: 'permission', value: 4 },
  { label: 'monitoring', value: 5 },
];
