import { gql } from '@apollo/client';

export const GET_INTERNAL_GROUPS = gql`
  query GetInternalGroups($pageSize: Int, $pageNumber: Int) {
    internalGroups(pageSize: $pageSize, pageNumber: $pageNumber) {
      paging {
        totalNumberOfElements
      }
      groups {
        id
        name
        admin
        roles {
          id
          name
        }
        users {
          id
          name
        }
        customers {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_INTERNAL_GROUPS = gql`
  query GetAllInternalGroups{
    internalGroups(pageSize: 500, pageNumber: 0) {
      groups {
        id
        name
        roles {
          id
          name
        }
        users {
          id
        }
        customers {
          id
          name
        }
      }
    }
  }
`;

export const GET_TOTAL_NUMBER_OF_INTERNAL_GROUPS = gql`
  query GetTotalNumberOfInternalGroups{
    internalGroups(pageSize: 1, pageNumber: 0) {
      paging {
        totalNumberOfElements
      }
    }
  }
`;

