import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_REPORTING_RULE_CONFIGURATION } from 'src/graphql/schema/query/GetReportingRuleConfiguration';
import { InsuranceEventType } from 'src/types/ReportingRule';
import { ReportingRuleConfiguration } from 'src/types/ReportingRuleConfiguration';
import MessagesTab from './MessagesTab';

interface ConfigurationOverviewDrawerProps {
  ruleConfigId: string;
  onClose: () => void;
  insuranceProviderId: string;
  onSelectRule: (
    insuranceEventType: InsuranceEventType,
    ruleId: string,
    configurationRuleId: string,
    providerId: string,
  ) => void;
}

const ConfigurationOverviewDrawer: FC<ConfigurationOverviewDrawerProps> = ({
  ruleConfigId,
  insuranceProviderId,
  onSelectRule,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <QueryWrapper
      query={GET_REPORTING_RULE_CONFIGURATION}
      options={{ variables: { reportingRuleConfigurationId: ruleConfigId }, skip: !ruleConfigId }}
    >
      {(reportingRuleConfiguration: ReportingRuleConfiguration) => (
        <DrawerBase
          onClose={onClose}
          title={reportingRuleConfiguration?.name}
          subTitle={reportingRuleConfiguration?.description}
        >
          <Tabs>
            <Tab title={t('insuranceManager:message-configuration-drawer.message-drawer-tab')}>
              <MessagesTab
                onSelectRule={onSelectRule}
                ruleConfigId={ruleConfigId}
                insuranceProviderId={insuranceProviderId}
              />
            </Tab>
          </Tabs>
        </DrawerBase>
      )}
    </QueryWrapper>
  );
};

export default ConfigurationOverviewDrawer;
