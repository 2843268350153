import { gql } from '@apollo/client';

export const GET_PAYMENT_NOTES = gql`
  query InternalGetPaymentNotes($paymentId: UUID!) {
    internalPaymentNotes(paymentId: $paymentId) {
      paymentId
      createdDate
      note
    }
  }
`;
