import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import InsuranceTypeMappingsTable from './InsuranceTypeMappingsTable';
import InsuranceTypeMappingsSearchInput from './InsuranceTypeMappingsSearchInput';
import { SEARCH_INSURANCE_TYPE_MAPPINGS } from 'src/graphql/schema/query/insurancetypemapping/SearchInsuranceTypeMappings';
import { getInitialInsuranceTypeMappingsRequest } from './utils/insuranceTypeMappingUtils';
import { Pagination } from 'fgirot-k2-ui-components';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { InsuranceTypeMappingsRequest } from 'src/types/InsuranceTypeMappingsRequest';
import { InsuranceTypeMappingsPage } from 'src/types/InsuranceTypeMapping';
import './insurance-type-mappings-tab.scss';

const InsuranceTypeMappingsTab = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 16;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const [insuranceTypeMappingsRequest, setInsuranceTypeMappingsRequest] = useState<InsuranceTypeMappingsRequest>(
    getInitialInsuranceTypeMappingsRequest(),
  );

  const handleTextSearch = (searchInput: string) => {
    setInsuranceTypeMappingsRequest({
      ...insuranceTypeMappingsRequest,
      searchInput,
    });
  };

  return (
    <div className="insurance-type-mappings-tab">
      <QueryWrapper
        query={SEARCH_INSURANCE_TYPE_MAPPINGS}
        options={{
          variables: {
            request: { searchInput: insuranceTypeMappingsRequest.searchInput, pageNumber: queryPageNumber, pageSize },
          },
          fetchPolicy: 'no-cache',
        }}
      >
        {({ searchInsuranceTypeMappings, paging }: InsuranceTypeMappingsPage) => {
          const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
          const showPagination = totalPages > 1;

          return (
            <>
              <div className="insurance-type-mappings-tab__search-input">
                <InsuranceTypeMappingsSearchInput
                  searchRequest={insuranceTypeMappingsRequest}
                  onSearch={handleTextSearch}
                />
              </div>
              <InsuranceTypeMappingsTable insuranceTypeMappings={searchInsuranceTypeMappings} />
              {showPagination && (
                <div data-testid="insurance-type-mappings-tab-pagination">
                  <Pagination
                    currentPage={pageNumber}
                    onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </>
          );
        }}
      </QueryWrapper>
    </div>
  );
};

export default InsuranceTypeMappingsTab;
