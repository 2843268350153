import { Checkbox, TableRow, Td } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/Invoice';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import './invoices-table-row.scss';

interface InvoiceTableRowProps {
  invoice: Invoice;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InvoicesTableRow: FC<InvoiceTableRowProps> = ({ invoice, checked, onChange }) => {
  const { t } = useTranslation();

  return (
    <TableRow className="invoices-table-row" data-testid={`invoices-table-row__${invoice.id}`}>
      <Td compressed>
        <Checkbox checked={checked} onChange={onChange} />
      </Td>
      <Td compressed>{invoice.employer.legalName}</Td>
      <Td compressed>{invoice.insuranceProvider ? invoice.insuranceProvider.legalName : '-'}</Td>
      <Td compressed>{invoice.invoiceNumber}</Td>
      <Td compressed>{formatSweAmountText(`${invoice.remainingAmount}`)}</Td>
      <Td compressed>{stringOrBlank(t(`invoice:invoice-status.${invoice.invoiceStatus}`))}</Td>
      <Td compressed>{stringOrBlank(invoice.dueDate)}</Td>
      <Td compressed>{stringOrBlank(invoice.paymentDueDate)}</Td>
      <Td compressed>{t(`payment:payment-service-provider.${invoice.account.paymentServiceProvider}`)}</Td>
      <Td compressed>{t(`payment:bank.${invoice.account.bank}`)}</Td>
      <Td compressed>{invoice.account.accountNumber}</Td>
      <Td compressed>{invoice.accountNumberTo}</Td>
    </TableRow>
  );
};

export default InvoicesTableRow;
