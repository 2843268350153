import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import TdFullname from 'src/components/Common/TdFullname';
import { ExternalUser } from 'src/types/ExternalUser';

interface AdminUsersTableRowProps {
  externalUser: ExternalUser;
  onClick: (userId: string) => void;
}

const AdminUsersTableRow: FC<AdminUsersTableRowProps> = ({ externalUser, onClick }) => {
  const { user, externalGroups } = externalUser;
  const customerName = externalGroups.find((group) => group.admin)?.customerName ?? '-';
  return (
    <TableRow onClick={() => onClick(user.id)} data-testid={`admin-users-table-row-${user.id}`}>
      <Td>{<TdFullname firstName={user.firstName} lastName={user.lastName} />}</Td>
      <Td>{user.email ?? ''}</Td>
      <Td>{customerName}</Td>
    </TableRow>
  );
};

export default AdminUsersTableRow;
