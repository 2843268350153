import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageBase from '../../components/PageBase';
import React from 'react';
import './permission.scss';
import ModuleCard from 'src/components/Common/ModuleCard';
import PermissionModuleCardBox from './PermissionModuleCardBox';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_TOTAL_NUMBER_OF_INTERNAL_USERS } from 'src/graphql/schema/query/GetInternalUsers';
import { GetInternalUsersPage } from 'src/types/InternalUser';
import { GET_TOTAL_NUMBER_OF_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';
import { GetGroupsPage } from 'src/types/Group';
import { GET_TOTAL_NUMBER_OF_EXTERNAL_ADMIN_USERS } from 'src/graphql/schema/query/GetExternalUsers';
import { GetExternalUsersPage } from 'src/types/ExternalUser';

const Permission = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnClickCard = (path: string) => {
    navigate(path);
  };

  return (
    <PageBase title={t('permission:title')} header={true}>
      <div className="permission">
        <ModuleCard
          title={t('permission:internal.title')}
          iconType="Users"
          onClick={() => handleOnClickCard('/permission/internal')}
        >
          <div className="permission__module-card-box-wrapper">
            <QueryWrapper query={GET_TOTAL_NUMBER_OF_INTERNAL_GROUPS}>
              {({ paging }: GetGroupsPage) => (
                <PermissionModuleCardBox
                  title={t('permission:module-card.groups-label')}
                  subTitle={paging.totalNumberOfElements?.toString()}
                />
              )}
            </QueryWrapper>
            <QueryWrapper query={GET_TOTAL_NUMBER_OF_INTERNAL_USERS}>
              {({ paging }: GetInternalUsersPage) => (
                <PermissionModuleCardBox
                  title={t('permission:module-card.users-label')}
                  subTitle={paging.totalNumberOfElements?.toString()}
                />
              )}
            </QueryWrapper>
          </div>
        </ModuleCard>
        <ModuleCard
          title={t('permission:external.title')}
          iconType="Users"
          onClick={() => handleOnClickCard('/permission/external')}
        >
          <QueryWrapper query={GET_TOTAL_NUMBER_OF_EXTERNAL_ADMIN_USERS}>
            {({ paging }: GetExternalUsersPage) => (
              <PermissionModuleCardBox
                title={t('permission:module-card.admin-users-label')}
                subTitle={paging.totalNumberOfElements?.toString()}
              />
            )}
          </QueryWrapper>
        </ModuleCard>
      </div>
    </PageBase>
  );
};

export default Permission;
