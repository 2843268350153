import { Customer } from './Customer';
import { InternalGroup, ExternalGroup } from './Group';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  identifier: string;
  identifierType: string;
  orderRef: string;
  userType: string;
  admin: boolean;
  validTo: string;
  accountNonLocked: boolean;
  customers: [Customer];
}

export interface GetUser {
  user: User;
  externalGroups: ExternalGroup[];
  internalGroups: InternalGroup[];
  customers: Customer[];
}

export enum IdentifierType {
  BANK_ID = 'BANK_ID',
  E_ID = 'E_ID',
}
