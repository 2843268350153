import { gql } from '@apollo/client';

export const INTERNAL_ADD_CUSTOMER_TO_GROUP = gql`
  mutation InternalAddCustomerToGroup($groupId: UUID!, $customerId: UUID!) {
    internalAddCustomerToGroup(groupId: $groupId, customerId: $customerId) {
      groupId
      customerId
    }
  }
`;
