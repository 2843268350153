import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { Payment } from 'src/types/Payment';
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortDateAsc,
  sortDateDesc,
  sortNumericalAsc,
  sortNumericalDesc,
} from 'src/util/SortUtil';
import PaymentsTableHeads from './PaymentsTableHeads';
import PaymentsTableRow from './PaymentsTableRow';
import { PaymentsTableSortField } from './PaymentsTableSortField';
import PaymentDrawer from '../PaymentDrawer';
import './payments-table.scss';

interface PaymentsTableProps {
  payments: Payment[];
  checked: { id: string; checked: boolean }[];
  onChange: (id: string) => void;
}

const PaymentsTable: FC<PaymentsTableProps> = ({ payments, checked, onChange }) => {
  const [sortField, setSortField] = useState<PaymentsTableSortField>('employer');
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>(undefined);
  const handleSortFieldChange = (sortField: PaymentsTableSortField) => setSortField(sortField);
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));

  const handleOpenPaymentDrawer = (id: string) => {
    setSelectedPaymentId(id);
  };

  const handleClosePaymentDrawer = () => {
    setSelectedPaymentId(undefined);
  };

  const sortFunction = (a: Payment, b: Payment) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalAsc : sortNumericalDesc;
    const sortDate = sortDirection === 'UP' ? sortDateDesc : sortDateAsc;

    switch (sortField) {
      case 'employer':
        return sortAlphabetical(a.employer.legalName, b.employer.legalName);
      case 'insuranceProvider':
        return sortAlphabetical(a.invoice.insuranceProvider?.legalName, b.invoice.insuranceProvider?.legalName);
      case 'invoiceNumber':
        return sortAlphabetical(a.invoice.invoiceNumber, b.invoice.invoiceNumber);
      case 'amount':
        return sortNumerical(a.amount, b.amount);
      case 'errorCode':
        return sortAlphabetical(a.errorCode, b.errorCode);
      case 'paymentStatus':
        return sortAlphabetical(a.paymentStatus, b.paymentStatus);
      case 'paymentDueDate':
        return sortDate(a.paymentDueDate, b.paymentDueDate);
      case 'paymentCreatedDate':
        return sortDate(a.paymentCreatedDate, b.paymentCreatedDate);
      case 'paymentServiceProvider':
        return sortAlphabetical(a.paymentServiceProvider, b.paymentServiceProvider);
      case 'bank':
        return sortAlphabetical(a.bank, b.bank);
      case 'accountFrom':
        return sortAlphabetical(a.accountFrom, b.accountFrom);
      case 'accountTo':
        return sortAlphabetical(a.accountTo, b.accountTo);
      case 'description':
        return sortAlphabetical(a.description, b.description);
      default:
        return undefined;
    }
  };

  const sortedPayments = [...payments].sort(sortFunction) || [];
  return (
    <>
      <div className="payments-table">
        <TableBase compressed>
          <TableHead filled>
            <PaymentsTableHeads
              sortDirection={sortDirection}
              sortField={sortField}
              onSortDirectionChange={handleSortDirectionChange}
              onSortFieldChange={handleSortFieldChange}
            />
          </TableHead>
          <TableBody>
            {sortedPayments.map((payment) => (
              <PaymentsTableRow
                key={`PaymentsTableRow-${payment.id}`}
                payment={payment}
                onChange={() => onChange(payment.id)}
                checked={checked.find((box) => box.id === payment.id)?.checked ?? false}
                onRowClick={handleOpenPaymentDrawer}
              />
            ))}
          </TableBody>
        </TableBase>
      </div>
      {selectedPaymentId !== undefined && (
        <PaymentDrawer
          open={!!selectedPaymentId}
          onClose={handleClosePaymentDrawer}
          payment={payments.find((payment) => payment.id === selectedPaymentId)}
        />
      )}
    </>
  );
};

export default PaymentsTable;
