import { Payment } from 'src/types/Payment';
import { SearchPaymentsRequest } from 'src/types/SearchPaymentsRequest';

export const getInitialSearchPaymentsRequest = (): SearchPaymentsRequest => {
  return {
    searchInput: '',
    pageNumber: 0,
    pageSize: 20,
    filters: {
      paymentDueDateFilter: '',
      paymentCreatedDateFilter: {
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      },
      banksFilter: null,

      paymentStatuses: ['PAYMENT_REQUEST_CREATED', 'PAYMENT_FAILED'],
      paymentServiceProvidersFilter: null,
    },
  };
};

export const groupPaymentByCustomer = (payments: Payment[]): Map<string, Payment[]> => {
  return payments.reduce((map, payment) => {
    const grouped = map.get(payment.customerId);
    if (grouped) {
      grouped.push(payment);
    } else {
      map.set(payment.customerId, [payment]);
    }
    return map;
  }, new Map<string, Payment[]>());
};

export const groupPaymentByEmployer = (payments: Payment[]): Map<string, Payment[]> => {
  return payments.reduce((map, payment) => {
    const grouped = map.get(payment.employer.id);
    if (grouped) {
      grouped.push(payment);
    } else {
      map.set(payment.employer.id, [payment]);
    }
    return map;
  }, new Map<string, Payment[]>());
};