import React from 'react';
import { Header, Button } from 'fgirot-k2-ui-components';
import { useShowNavElements } from 'src/service/routes/LocationHooks';
import './page-header.scss';
import { useKeycloak } from 'fgirot-k2-react-keycloak-provider';

const PageHeader = () => {
  const shouldShowNavElements = useShowNavElements();
  const { keycloak, initialized } = useKeycloak();

  return (
    shouldShowNavElements && (
      <Header className="page-header">
        <Button onClick={() => initialized && keycloak.logout()} label="Logga ut" className="page-header__logout-btn" />
      </Header>
    )
  );
};
export default PageHeader;
