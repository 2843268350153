import { gql } from '@apollo/client';

export const INTERNAL_ADD_USER_TO_GROUP = gql`
  mutation InternalAddUserToGroup($groupId: UUID!, $userId: UUID!, $request: AddUserToGroupInput!) {
    internalAddUserToGroup(groupId: $groupId, userId: $userId, request: $request) {
      groupId
      userId
    }
  }
`;

