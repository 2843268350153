import { PeriodAggregationBalance } from 'src/types/PeriodAggregation';

export const groupByEmployer = (
  periodAggregations: PeriodAggregationBalance[],
): Map<string, PeriodAggregationBalance[]> => {
  return periodAggregations.reduce((map, periodAggregation) => {
    const grouped = map.get(`${periodAggregation.employer.legalName}`);

    if (grouped) {
      grouped.push(periodAggregation);
      map.set(`${periodAggregation.employer.legalName}`, grouped);
    } else {
      map.set(`${periodAggregation.employer.legalName}`, [periodAggregation]);
    }
    return map;
  }, new Map<string, PeriodAggregationBalance[]>());
};
