import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { Customer } from 'src/types/Customer';
import './customers-table-row.scss';

interface CustomersTableRowProps {
  customer: Customer;
  onClick: (customerId: string) => void;
}

const CustomersTableRow: FC<CustomersTableRowProps> = ({ customer, onClick }) => {
  return (
    <TableRow className={['customers-table-row'].join(' ')} onClick={() => onClick(customer.id)}>
      <Td>{customer.name}</Td>
      {/* TODO: fix number of employers */}
      <Td>{'-'}</Td>
    </TableRow>
  );
};

export default CustomersTableRow;
