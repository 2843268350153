import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import InvoicesTableRow from './InvoicesTableRow';
import { InvoicesTableSortField } from './InvoicesTableSortField';
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortDateAsc,
  sortDateDesc,
  sortNumericalAsc,
  sortNumericalDesc,
} from 'src/util/SortUtil';
import InvoicesTableHeads from './InvoicesTableHeads';
import { Invoice } from 'src/types/Invoice';
import './invoices-table.scss';

interface InvoicesTableProps {
  invoices: Invoice[];
  checked: string[];
  onChange: (id: string) => void;
}

const InvoicesTable: FC<InvoicesTableProps> = ({ invoices, checked, onChange }) => {
  const [sortField, setSortField] = useState<InvoicesTableSortField>('employer');
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');

  const handleSortFieldChange = (sortField: InvoicesTableSortField) => setSortField(sortField);
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));

  const sortFunction = (a: Invoice, b: Invoice) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalAsc : sortNumericalDesc;
    const sortDate = sortDirection === 'UP' ? sortDateDesc : sortDateAsc;

    switch (sortField) {
      case 'employer':
        return sortAlphabetical(a.employer.legalName, b.employer.legalName);
      case 'insuranceProvider':
        return sortAlphabetical(a.insuranceProvider.legalName, b.insuranceProvider.legalName);
      case 'invoiceNumber':
        return sortAlphabetical(a.invoiceNumber, b.invoiceNumber);
      case 'totalAmount':
        return sortNumerical(a.totalAmount, b.totalAmount);
      case 'invoiceStatus':
        return sortAlphabetical(a.invoiceStatus, b.invoiceStatus);
      case 'dueDate':
        return sortDate(a.dueDate, b.dueDate);
      case 'paymentDueDate':
        return sortDate(a.paymentDueDate, b.paymentDueDate);
      case 'paymentServiceProvider':
        return sortAlphabetical(a.account.paymentServiceProvider, b.account.paymentServiceProvider);
      case 'bank':
        return sortAlphabetical(a.account.bank, b.account.bank);
      case 'accountNumberFrom':
        return sortAlphabetical(a.account.accountNumber, b.account.accountNumber);
      case 'accountNumberTo':
        return sortAlphabetical(a.accountNumberTo, b.accountNumberTo);
      default:
        return undefined;
    }
  };

  const sortedInvoices = [...invoices].sort(sortFunction) || [];

  return (
    <div className="invoices-table">
      <TableBase compressed>
        <TableHead filled>
          <InvoicesTableHeads
            sortDirection={sortDirection}
            sortField={sortField}
            onSortDirectionChange={handleSortDirectionChange}
            onSortFieldChange={handleSortFieldChange}
          />
        </TableHead>
        <TableBody>
          {sortedInvoices.map((invoice) => (
            <InvoicesTableRow
              key={`InvoiceTableRow-${invoice.id}`}
              invoice={invoice}
              onChange={() => onChange(invoice.id)}
              checked={checked.includes(invoice.id) ? true : false}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default InvoicesTable;
