import { gql } from '@apollo/client';

export const GET_INTERNAL_USER = gql`
  query GetUser($userId: UUID!) {
    internalUser(userId: $userId) {
      user {
        id
        firstName
        lastName
        email
        phone
        identifier
        identifierType
        orderRef
        userType
        admin
        validTo
        accountNonLocked
        customers {
          name
          id
        }
      }
      internalGroups {
        name
        id
      }
      customers {
        name
        id
      }
      
    }
  }
`;
