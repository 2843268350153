import React, { FC, useState } from 'react';
import { Invoice, InvoiceStatusEnum } from 'src/types/Invoice';
import { Paging } from 'src/types/PageableResponse';
import { SearchInvoicesFilters, SearchInvoicesRequest } from 'src/types/SearchInvoicesRequest';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { useCreatePaymentMessages } from 'src/service/CreatePaymentMessages';
import { usePatchInvoiceHeader } from 'src/service/PatchInvoiceHeader';
import InvoicesSearchInput from '../InvoicesSearchInput';
import InvoicesFilterDetails from '../InvoicesFilterDetails';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import InvoicesTable from '../InvoicesTable';
import InvoicesCalculatedAmount from '../InvoicesCalculatedAmount';
import SearchInvoicesFiltersModal from '../SearchInvoicesFiltersModal';
import { useTranslation } from 'react-i18next';
import { groupInvoiceByCustomer, groupInvoiceByEmployer } from '../utils/invoiceUtils';

interface InvoicesProps {
  invoices: Invoice[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  searchInvoicesRequest: SearchInvoicesRequest;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: SearchInvoicesFilters) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const Invoices: FC<InvoicesProps> = ({
  invoices,
  paging,
  pageNumber,
  pageSize,
  searchInvoicesRequest,
  onTextSearch,
  onApplyFilters,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
  const showPagination = totalPages > 1;

  const [checked, setChecked] = useState<string[]>([]);
  const [sentPayments, setSentPayments] = useState<string[]>([]);
  const [pausedInvoices, setPausedInvoices] = useState<string[]>([]);
  const createPaymentMessages = useCreatePaymentMessages();
  const patchInvoiceHeader = usePatchInvoiceHeader();

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const toggleChecked = (id: string) => {
    if (checked.includes(id)) {
      setChecked((prev) => prev.filter((c) => c !== id));
    } else {
      setChecked((prev) => [...prev, id]);
    }
  };

  const createPayments = () => {
    if (checked.length > 0) {
      const checkedInvoices = invoices.filter(invoice => {
        return checked.includes(invoice.id);
      });    
      const groupedByCustomer = groupInvoiceByCustomer(checkedInvoices);
      groupedByCustomer.forEach((customerGroup) => {
        const groupedByEmployer = groupInvoiceByEmployer(customerGroup);
        groupedByEmployer.forEach((employerGroup) => {
          const invoicesToSend = employerGroup.map(invoice => invoice.id); 
          createPaymentMessages({invoices: invoicesToSend}, employerGroup[0].employer.id, employerGroup[0].customerId)
          setSentPayments((prev) => [...prev, ...invoicesToSend]);
        });
      });
      setChecked([]);
    }
  };

  const patchInvoiceHeaderStatus = (status: string) => {
    const invoicesToPatch = checked.map((invoiceId) => ({
      invoiceHeaderId: invoiceId,
      invoiceStatus: status,
    }));
    patchInvoiceHeader(invoicesToPatch, invoices.find((i) => checked.find((cI) => cI === i.id))?.employer.id);

    if (status === InvoiceStatusEnum.PAUSED) {
      setPausedInvoices((prev) => [...prev, ...checked]);
    } else {
      setPausedInvoices((prev) => prev.filter((invoiceId) => !checked.includes(invoiceId)));
    }
    setChecked([]);
  };

  const toggleAll = () => {
    if (checked.length === invoices.length && invoices.every((invoice) => checked.includes(invoice.id))) {
      setChecked([]);
    } else {
      setChecked(invoices.map((invoice) => invoice.id));
    }
  };

  const remainingInvoices = invoices.filter((i) => !sentPayments.includes(i.id));

  const hasPausedInvoice = checked.some((invoiceId) => pausedInvoices.includes(invoiceId));

  return (
    <>
      <div className="invoices-tab">
        <InvoicesSearchInput onSearch={onTextSearch} searchRequest={searchInvoicesRequest} />
        <InvoicesFilterDetails searchRequest={searchInvoicesRequest} applyFilters={onApplyFilters} />
        <div className="invoices-tab__mark-button">
          <Button type="primary" label={t('economy:invoices-tab.buttons.mark-button')} onClick={toggleAll} />
        </div>
        <div className="invoices-tab__table-buttons">
          <Button
            type="primary"
            label={t('economy:invoices-tab.buttons.send-button')}
            onClick={createPayments}
            disabled={hasPausedInvoice}
          />
          <Button
            type="primary"
            data-testid="invoices-pause-button"
            label={t('economy:invoices-tab.buttons.pause-button')}
            onClick={() => patchInvoiceHeaderStatus(InvoiceStatusEnum.PAUSED)}
            disabled={hasPausedInvoice}
          />
          <Button
            type="primary"
            label={t('economy:invoices-tab.buttons.resume-button')}
            onClick={() => patchInvoiceHeaderStatus(InvoiceStatusEnum.PREPARED_FOR_PAYMENTS)}
          />
        </div>

        <InvoicesTable invoices={remainingInvoices} onChange={toggleChecked} checked={checked} />
        {showPagination && (
          <div data-testid="invoices-tab-pagination">
            <Pagination
              currentPage={pageNumber}
              onPageChange={(nextPageNumber) => onPageChange(nextPageNumber)}
              totalPages={totalPages}
            />
          </div>
        )}
        <InvoicesCalculatedAmount invoices={remainingInvoices.filter((invoice) => checked.includes(invoice.id))} />
      </div>
      {filtersModalOpen && (
        <SearchInvoicesFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filters={searchInvoicesRequest?.filters}
        />
      )}
      <div className="invoices-tab__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-cy="filter-button"
        />
      </div>
    </>
  );
};

export default Invoices;
