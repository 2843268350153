import { gql } from '@apollo/client';

export const GET_INSURANCE_PROVIDER = gql`
  query ($insuranceProviderId: UUID!) {
    insuranceProvider(insuranceProviderId: $insuranceProviderId) {
      id
      legalName
      organisationNumber
      defaultReportScheduling {
        time
        dayOfMonth
        isLastDayOfMonth
        cronExpression
      }
    }
  }
`;
