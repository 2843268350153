import { Banner, Option } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import AccountingBalanceListItem from './AccountingBalanceListItem';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_PERIOD_AGGREGATIONS } from 'src/graphql/schema/query/GetPeriodAggregations';
import { PeriodAggregationsPage } from 'src/types/PeriodAggregation';
import { groupByEmployer } from './Utils/accountingBalanceUtils';
import When from 'src/components/Common/When';
import PeriodFilter from './PeriodFilter';
import { Month } from 'src/types/Month';
import { useTranslation } from 'react-i18next';
import './accounting-balance-tab.scss';

const AccountingBalanceTab = () => {
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const [selectedMonth, setSelectedMonth] = useState<Option<Month>>();
  const [selectedYear, setSelectedYear] = useState<Option<number>>();
  const [selectedPeriod, setSelectedPeriod] = useState('');

  return (
    <>
      <PeriodFilter
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        setSelectedPeriod={setSelectedPeriod}
      />
      <When condition={selectedPeriod.replace(/-/g, '').length < 6}>
        <Banner type="announcement" message={t('economy:accounting-balance-tab.banner-announcement-message')} />
      </When>
      <When condition={selectedPeriod.replace(/-/g, '').length === 6}>
        <QueryWrapper
          query={GET_PERIOD_AGGREGATIONS}
          options={{
            fetchPolicy: 'no-cache',
            variables: {
              accountingPeriod: selectedPeriod.replace(/-/g, ''),
              onlyImbalance: true,
              pageSize: pageSize,
              pageNumber: queryPageNumber,
            },
          }}
        >
          {({ internalPeriodAggregations, paging }: PeriodAggregationsPage) => {
            const groupedPeriodAggregationsByEmployer = groupByEmployer(internalPeriodAggregations);
            return (
              <>
                <When condition={internalPeriodAggregations.length === 0}>
                  <Banner type="success" message={t('economy:accounting-balance-tab.banner-success-message')} />
                </When>
                <div className="accounting-balance-tab__section">
                  {Array.from(groupedPeriodAggregationsByEmployer.entries()).map(
                    ([employerLegalName, periodAggregations]) => (
                      <AccountingBalanceListItem
                        key={`AccountingBalanceListItem-${employerLegalName}`}
                        paging={paging}
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        onPageChange={handleOnPageChange}
                        groupedPeriodAggregationsByEmployer={periodAggregations}
                        employerLegalName={employerLegalName}
                      />
                    ),
                  )}
                </div>
              </>
            );
          }}
        </QueryWrapper>
      </When>
    </>
  );
};

export default AccountingBalanceTab;
