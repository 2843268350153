import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useTranslation } from 'react-i18next';
import { InsuranceTypeMapping } from 'src/types/InsuranceTypeMapping';
import { InsuranceTypeMappingDrawerTab } from 'src/types/tabs/InvoiceRowMappingsDrawerTab';
import InvoiceRowMappingsDrawerTab from './InvoiceRowMappingsDrawerTab';

interface InsuranceTypeMappingDrawerOverviewProps {
  insuranceTypeMapping: InsuranceTypeMapping;
  onClose: () => void;
  currentTab: InsuranceTypeMappingDrawerTab;
}

const InsuranceTypeMappingDrawerOverview: FC<InsuranceTypeMappingDrawerOverviewProps> = ({
  insuranceTypeMapping,
  onClose,
  currentTab,
}) => {
  const { t } = useTranslation();
  return (
    <DrawerBase
      onClose={onClose}
      title={`${insuranceTypeMapping?.name}`}
      subTitle={
        <div className="insurance-type-mapping-drawer__sub-title">
          <Typography>{insuranceTypeMapping.insuranceProvider.legalName}</Typography>
          {insuranceTypeMapping.benefitType && (
            <Pill label={t(`benefitTypes:abbreviations.${insuranceTypeMapping.benefitType}`)} />
          )}
        </div>
      }
    >
      <Tabs tabIndex={currentTab}>
        <Tab title={t('insuranceManager:insurance-type-mapping-drawer.invoice-row-mappings-drawer-tab.tab-label')}>
          <InvoiceRowMappingsDrawerTab insuranceTypeMapping={insuranceTypeMapping} />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default InsuranceTypeMappingDrawerOverview;
