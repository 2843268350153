import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { InsuranceProduct } from 'src/types/InsuranceProduct';
import InsuranceProductsTableRow from './InsuranceProductsTableRow';
import './insurance-products-table.scss';

interface InsuranceProductsTableProps {
  insuranceProducts: InsuranceProduct[];
  onClick: (insuranceProductId: string) => void;
}

const InsuranceProductsTable: FC<InsuranceProductsTableProps> = ({ insuranceProducts, onClick }) => {
  const { t } = useTranslation();

  const head = [
    { name: t('insuranceManager:product'), centered: false },
    { name: t('insuranceManager:provider'), centered: false },
    { name: t('insuranceManager:status'), centered: false },
  ];

  return (
    <div className={['insurance-products-table'].join(' ')}>
      <TableBase>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th centered={title.centered} key={`InsuranceProductsTable-${title.name}-${index}`}>
                {title.name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {insuranceProducts.map((product) => (
            <InsuranceProductsTableRow
              key={`InsuranceProductsTableRow-${product.id}`}
              insuranceProduct={product}
              onClick={() => onClick(product.id)}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default InsuranceProductsTable;
