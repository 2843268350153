import { useTranslation } from 'react-i18next';
import { Pagination, Typography } from 'fgirot-k2-ui-components';
import React, { useState, FC } from 'react';
import { InsuranceProvidersPage } from 'src/types/InsuranceProvider';
import InsuranceProvidersTable from './InsuranceProvidersTable';
import './insurance-providers-tab.scss';
import InsuranceProviderDrawer from './InsuranceProviderDrawer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SEARCH_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/SearchInsuranceProviders';
import { SearchInsuranceProvidersRequest } from 'src/types/SearchInsuranceProvidersRequest';
import InsuranceProvidersSearchInput from './InsuranceProvidersTable/InsuranceProvidersSearchInput';
import When from 'src/components/Common/When';
import { getTotalPages } from 'src/util/Pagination/Pagination';

const InsuranceProvidersTab: FC = () => {
  const [selectedInsuranceProviderId, setSelectedInsuranceProviderId] = useState<string>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const [searchInsuranceProvidersRequest, setSearchInsuranceProvidersRequest] =
    useState<SearchInsuranceProvidersRequest>({
      searchInput: '',
      pageNumber: 0,
      pageSize: 20,
    });
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);
  const handleTextSearch = (searchInput: string) => {
    setSearchInsuranceProvidersRequest({
      ...searchInsuranceProvidersRequest,
      searchInput,
    });
  };
  const handleSelectInsuranceProvider = (insuranceProviderId: string) => {
    setSelectedInsuranceProviderId(insuranceProviderId);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <QueryWrapper
      query={SEARCH_INSURANCE_PROVIDERS}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: { ...searchInsuranceProvidersRequest },
        },
      }}
    >
      {({ internalInsuranceProviders: insuranceProviders, paging }: InsuranceProvidersPage) => {
        const totalPages = getTotalPages(paging.totalNumberOfElements, 25);
        return (
          <div className="insurance-providers-tab__displayed-providers">
            <Typography bold component="h3" variant="h3">
              {`${t('insuranceManager:insurance-providers')}`}
            </Typography>
            <div className="insurance-providers-tab__search-provider">
              <InsuranceProvidersSearchInput
                onSearch={handleTextSearch}
                searchRequest={searchInsuranceProvidersRequest}
              />
            </div>
            <InsuranceProvidersTable insuranceProviders={insuranceProviders} onClick={handleSelectInsuranceProvider} />
            <When condition={paging.totalNumberOfElements > 0}>
              <div className="insurance-providers-tab" data-testid="insurance-providers-tab-pagination">
                <Pagination currentPage={pageNumber + 1} totalPages={totalPages} onPageChange={handlePageChange} />
              </div>
            </When>
            {drawerOpen && (
              <InsuranceProviderDrawer
                open={drawerOpen}
                onClose={handleCloseDrawer}
                selectedInsuranceProviderId={selectedInsuranceProviderId}
              />
            )}
          </div>
        );
      }}
    </QueryWrapper>
  );
};

export default InsuranceProvidersTab;
