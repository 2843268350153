import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React  from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import './admin-users-drawer.scss';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { User } from 'src/types/User';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import AdminUsersDrawerAboutTab from './AdminUsersDrawerAboutTab';
import { GET_USER } from 'src/graphql/schema/GetUser';

interface AdminUsersDrawerProps {
  open: boolean;
  onClose: () => void;
  userId: string;
}

const AdminUsersDrawer: React.FC<AdminUsersDrawerProps> = ({ open, onClose, userId }) => {
  const { t } = useTranslation();
  const breadcrumbs = [t('permission:breadcrumbs.external-permission'), t('permission:breadcrumbs.admin-users')];

  return (
    <div className="admin-users-drawer">
      <QueryWrapper query={GET_USER} options={{ variables: { userId } }}>
        {(user: User) => (
          <>
            <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
              <DrawerBase
                onClose={onClose}
                title={
                  <>
                    <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
                    <Typography variant="h3" component="h3" bold className="admin-users-drawer__title">
                      {`${user.firstName??''} ${user.lastName??''}`}
                    </Typography>
                  </>
                }
              >
                <Tabs>
                  <Tab title={`${t('permission:external.admin-users-drawer.tabs.about-label')}`}>
                    <AdminUsersDrawerAboutTab user={user} />
                  </Tab>
                </Tabs>
              </DrawerBase>
            </DrawerModal>
          </>
        )}
      </QueryWrapper>
    </div>
  );
};

export default AdminUsersDrawer;
