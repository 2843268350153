import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TableBase from 'src/components/Common/TableBase';
import { Customer } from 'src/types/Customer';
import CustomersTableRow from './CustomersTableRow';
import './customers-table.scss';

interface CustomersTableProps {
  customers: Customer[];
}

const CustomersTable: FC<CustomersTableProps> = ({ customers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const head = [
    { name: t('customers:customer'), centered: false },
    { name: t('customers:employer'), centered: false },
  ];

  const handleNavigateToSelectedCustomer = (customerId: string) => {
    navigate(`/customers/${customerId}/customer`);
  };

  return (
    <div className={['customers-table'].join(' ')}>
      <TableBase maxHeightPercentage={75}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th centered={title.centered} key={`CustomersTable-${title.name}-${index}`}>
                {title.name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <CustomersTableRow
              key={`CustomersTable-${customer.id}`}
              customer={customer}
              onClick={handleNavigateToSelectedCustomer}
            />
          ))}
        </TableBody>
      </TableBase>
    </div>
  );
};

export default CustomersTable;
