import { PageableResponse } from "./PageableResponse";
import { Permission } from "./Permission";

export interface Role {
  id: string;
  customerId: string;
  name: string;
  permissions: Permission[];
}

export interface GetRolesPage extends PageableResponse {
  roles:Role[];
}

export const INTERNAL_ADMIN_ROLE_NAME = 'INTERNAL_ADMIN';