import React, { useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { InsuranceProduct } from 'src/types/InsuranceProduct';
import './insurance-product-drawer.scss';
import DrawerBase from 'src/components/Common/DrawerBase';
import { Tab, Tabs } from 'fgirot-k2-ui-components';
import AboutDrawerTab from './AboutDrawerTab';
import { useTranslation } from 'react-i18next';
import { InsuranceProductsDrawerTab } from 'src/types/tabs/InsuranceProductsDrawerTab';
interface InsuranceProductsDrawerProps {
  open: boolean;
  onClose: () => void;
  insuranceProduct: InsuranceProduct;
}

const InsuranceProductsDrawer: React.FC<InsuranceProductsDrawerProps> = ({ open, onClose, insuranceProduct }) => {
  const [currentTab, setCurrentTab] = useState<InsuranceProductsDrawerTab>(InsuranceProductsDrawerTab.ABOUT);
  const { t } = useTranslation();

  return (
    <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
      <DrawerBase onClose={onClose} title={insuranceProduct.name}>
        <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
          <Tab title={`${t('insuranceManager:insurance-product-drawer.about')}`}>
            <AboutDrawerTab insuranceProduct={insuranceProduct} />
          </Tab>
        </Tabs>
      </DrawerBase>
    </DrawerModal>
  );
};

export default InsuranceProductsDrawer;
