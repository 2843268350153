import { Option, Select, Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import TemplatesTab from 'src/components/Documents/TemplatesTab';
import { GET_EMPLOYERS_QUERY } from 'src/graphql/schema/query/GetEmployers';
import useTabParams from 'src/hooks/useTabParams';
import { Customer } from 'src/types/Customer';
import { Employer } from 'src/types/Employer';
import { DocumentsTab } from 'src/types/tabs/DocumentsTab';
import { createOptionsFromList } from 'src/util/OptionUtil';
import './documents-overview.scss';

interface DocumentsOverviewProps {
  customers: Customer[];
}

const DocumentsOverview: FC<DocumentsOverviewProps> = ({ customers }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useTabParams('tab', DocumentsTab.TEMPLATES);

  const [customer, setCustomer] = useState<Customer>({
    id: '',
    name: '',
  });
  const [employer, setEmployer] = useState<Employer>({
    id: '',
    employerGroupId: '',
    customerNumber: null,
    legalName: '',
    organisationNumber: '',
    description: '',
    employmentGroups: null,
  });

  const customerOptions = createOptionsFromList(customers, 'id', 'name');

  const handleCustomerChange = (option: Option<string>) => {
    setCustomer((prev) => {
      return { ...prev, id: option.value };
    });
  };

  const handleEmployerChange = (option: Option<string>) => {
    setEmployer((prev) => {
      return { ...prev, id: option.value };
    });
  };

  return (
    <>
      <div className="documents-overview">
        <div>
          <Select
            placeholder={t('documents:filters.customer-placeholder')}
            options={customerOptions}
            selected={customerOptions.find((option) => option.value === customer.id)}
            onChange={handleCustomerChange}
          />
        </div>
        <div>
          {!customer.id ? (
            <Select
              placeholder={t('documents:filters.employer-placeholder')}
              options={[]}
              selected={[]}
              onChange={() => alert('disabled')}
              disabled={!customer.id}
            />
          ) : (
            <QueryWrapper query={GET_EMPLOYERS_QUERY} options={{ variables: { customerId: customer.id } }}>
              {(employers: Employer[]) => {
                const employerOptions = createOptionsFromList(employers, 'id', 'legalName');
                return (
                  <Select
                    placeholder={t('documents:filters.employer-placeholder')}
                    options={employerOptions}
                    selected={employerOptions.find((option) => option.value === employer.id)}
                    onChange={handleEmployerChange}
                    disabled={!customer.id}
                  />
                );
              }}
            </QueryWrapper>
          )}
        </div>
      </div>
      <Tabs className="documents" tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('documents:tabs.templates')}>
          <TemplatesTab employer={employer} customerId={customer.id} />
        </Tab>
      </Tabs>
    </>
  );
};

export default DocumentsOverview;
