import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { createOptions } from 'src/util/OptionUtil';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import { SearchRejectedInvoicesFilters } from 'src/types/ShearchRejectedInvoicesRequest';
import { rejectedBy, RejectedBy } from 'src/types/RejectionDetail';
import './rejected-invoices-filters-modal.scss';

interface RejectedInvoicesFiltersModalProps {
  open: boolean;
  filters: SearchRejectedInvoicesFilters;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchRejectedInvoicesFilters) => void;
}

const RejectedInvoicesFiltersModal: FC<RejectedInvoicesFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<SearchRejectedInvoicesFilters>({ ...filters });

  const handleRejectedByFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      rejectedByFilter: options.length > 0 ? options.map((option) => option.value) : null,
    });
  };

  const handleRejectionCreatedStartDateFilterChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      rejectionCreatedDateFilter: {
        ...newFilters.rejectionCreatedDateFilter,
        startDate: value,
      },
    });
  };

  const handleRejectionCreatedEndDateFilterChange = (value: string) => {
    setNewFilters({
      ...newFilters,
      rejectionCreatedDateFilter: {
        ...newFilters.rejectionCreatedDateFilter,
        endDate: value,
      },
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  const rejectedByOptions: Option<RejectedBy>[] = createOptions(rejectedBy, t, 'invoice:rejected-by');

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="rejected-invoices-filters-modal"
        title={t('economy:rejected-invoices-modal.title')}
        icon={<Icon type="Sliders" className="rejected-invoices-filters-modal__icon" />}
        size="wide"
      >
        <div className="rejected-invoices-filters-modal__filters">
          <div className="rejected-invoices-filters-modal__select">
            <Select
              label={t('economy:rejected-invoices-modal.rejected-by-label')}
              placeholder={t('common:all')}
              selectAll
              options={rejectedByOptions}
              selected={rejectedByOptions.filter((option) => newFilters?.rejectedByFilter?.includes(option.value))}
              onChange={handleRejectedByFilterChange}
              small
              outlined
              multiple
            />
          </div>
          <div className="rejected-invoices-filters-modal__date-section">
            <UpdateDatePicker
              label={t('economy:rejected-invoices-modal.rejection-created-start-date-label')}
              fieldValue={newFilters?.rejectionCreatedDateFilter?.startDate?.toString() ?? ''}
              fieldKey="createdDate"
              onBlur={(_, value) => {
                handleRejectionCreatedStartDateFilterChange(value);
              }}
            />
          </div>
          <div className="rejected-invoices-filters-modal__date-section">
            <UpdateDatePicker
              label={t('economy:rejected-invoices-modal.rejection-created-end-date-label')}
              fieldValue={newFilters?.rejectionCreatedDateFilter?.endDate?.toString() ?? ''}
              fieldKey="createdDate"
              onBlur={(_, value) => {
                handleRejectionCreatedEndDateFilterChange(value);
              }}
            />
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('economy:rejected-invoices-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default RejectedInvoicesFiltersModal;
