import { Button, Drawer, Icon, InlineEdit, ListGroup, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import './internal-users-drawer-add-user-to-group.scss';
import { useTranslation } from 'react-i18next';
import { User } from 'src/types/User';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { AddUserToGroupInput, InternalGroup } from 'src/types/Group';
import { useInternalAddUserToGroup } from 'src/service/internalGroup/InternalAddUserToGroup';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import TextInput from 'src/components/Common/TextInput';

interface InternalUsersDrawerAddUserToGroupProps {
  onClose: () => void;
  user: User;
  allGroups: InternalGroup[];
  userGroups: InternalGroup[];
  breadcrumbs: string[];
}

const InternalUsersDrawerAddUserToGroup: React.FunctionComponent<InternalUsersDrawerAddUserToGroupProps> = ({
  onClose,
  user,
  allGroups,
  userGroups,
  breadcrumbs,
}) => {
  const { t } = useTranslation();
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const groupsUserIsNotPartOfList = allGroups.filter(
    (group) => !userGroups.some((userGroup) => userGroup.id === group.id),
  );
  const groupOptions = createOptionsFromList(groupsUserIsNotPartOfList, 'id', 'name');
  const addUserToInternalGroup = useInternalAddUserToGroup();

  const addUserToGroup = () => {
    const request: AddUserToGroupInput = {
      validFrom: null,
      validTo: null,
    };
    addUserToInternalGroup(selectedGroupId, user.id, request);
    onClose();
  };

  const filterBySearchInput = (option: Option<string>) => {
    return option.label.toLowerCase().includes(searchInput.toLowerCase());
  };

  return (
    <Drawer
      leftButton={<Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onClose} />}
      type="elements"
      onClose={onClose}
    >
      <div className="internal-users-drawer-add-user-to-group__title">
        <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
        <Typography variant="h3" component="h3" bold>
          {t('permission:internal.internal-users-drawer.groups.add-user-to-group')}
        </Typography>
        <div className="internal-users-drawer-add-user-to-group__sub-title">
          <Typography>{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</Typography>
        </div>
        <ListGroup variant={'inline-edit'}>
          <TextInput
            type="text"
            className="internal-users-drawer-add-user-to-group__search-input"
            validationKey="text64"
            variant="default"
            maxLength={65}
            value={searchInput}
            placeholder={t('permission:internal.internal-users-drawer.groups.filter-groups')}
            onChange={(e) => setSearchInput(e.target.value)}
            data-testid="select-customer-modal-input"
          />
          <InlineEdit
            label={t('permission:internal.internal-users-drawer.groups.group-label')}
            className="internal-users-drawer-add-user-to-group__select"
          >
            <Select
              data-testid="group-select"
              allowSelectMaxWidth
              selected={groupOptions.find((option) => option.value === selectedGroupId)}
              options={groupOptions.filter(filterBySearchInput).slice(0, 10)}
              onChange={(option) => setSelectedGroupId(option.value)}
              placeholder={t('common:select')}
            />
          </InlineEdit>
          <Button
            className="internal-users-drawer-add-user-to-group__button"
            label={t('permission:internal.internal-users-drawer.groups.add-user-to-group')}
            onClick={addUserToGroup}
            disabled={!selectedGroupId}
            data-testid={`add-button-${!selectedGroupId ? 'disabled' : 'active'}`}
          />
        </ListGroup>
      </div>
    </Drawer>
  );
};

export default InternalUsersDrawerAddUserToGroup;
