import { gql } from '@apollo/client';

export const GET_BANKGIRO_NUMBERS = gql`
  query internalBankgiroNumbers($insuranceProviderId: UUID!) {
    internalBankgiroNumbers(insuranceProviderId: $insuranceProviderId) {
      id
      number
    }
  }
`;
