import { gql } from '@apollo/client';

export const GET_INTERNAL_USERS = gql`
  query GetInternalUsers($pageSize: Int, $pageNumber: Int) {
    internalUsers(pageSize: $pageSize, pageNumber: $pageNumber) {
      paging {
        totalNumberOfElements
      }
      internalUsers {
        user {
          firstName
          lastName
          email
          id
          admin
        }
        internalGroups {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_INTERNAL_USERS = gql`
  query GetAllInternalUsers {
    internalUsers(pageSize:500, pageNumber: 0) {
      internalUsers {
        user {
          firstName
          lastName
          email
          id
          admin
          userType
        }
        internalGroups {
          id
          name
        }
      }
    }
  }
`;

export const GET_TOTAL_NUMBER_OF_INTERNAL_USERS = gql`
  query GetTotalNumberOfInternalUsers{
    internalUsers(pageSize: 1 , pageNumber: 0) {
      paging {
        totalNumberOfElements
      }
    }
  }
`;

