import { gql } from '@apollo/client';

export const GET_MESSAGE_METADATA = gql`
  query GetMessageMetadata($messageTransactionId: UUID) {
    internalMessageMetadata(messageTransactionId: $messageTransactionId) {
      id
      customerId
      messageTransactionId
      messageFileReference
      messageFileName
      receiptFileReference
      receiptFileName
      responseFileReference
      responseFileName
      insuranceProvider {
        id
        legalName
      }
      employer {
        id
        legalName
      }
      wageFilePeriod
      messageStatus
      transferChannel
      messageSentAt
      availableMessageFormat
    }
  }
`;