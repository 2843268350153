import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { MessageMetadata } from 'src/types/MessageMetadata';

interface MessageMetadataTableRowProps {
  message: MessageMetadata;
  onClick: (id: string) => void;
}

const MessageMetadataTableRow: FC<MessageMetadataTableRowProps> = ({ message, onClick }) => {
  return (
    <TableRow onClick={() => onClick(message.id)} data-testid={`MessageMetadataTableRow-${message.id}`}>
      <Td>{message.messageFileName}</Td>
      <Td>{message.messageTransactionId}</Td>
    </TableRow>
  );
};

export default MessageMetadataTableRow;
