import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';

interface TemplatesTableHeadsProps {
  sortDirection: SortDirection;
  sortField: string;
  onSortDirectionChange: () => void;
  onSortFieldChange: (sortField: string) => void;
}

const TemplatesTableHeads: FC<TemplatesTableHeadsProps> = ({
  sortDirection,
  sortField,
  onSortDirectionChange,
  onSortFieldChange,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t('documents:templates-tab.table.head.name'),
      sortDirection,
      fieldName: 'templateName',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('templateName');
      },
    },
    {
      title: t('documents:templates-tab.table.head.report-type'),
      sortDirection,
      fieldName: 'formatType',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('formatType');
      },
    },
    {
      title: t('documents:templates-tab.table.head.scheduling'),
      sortDirection,
      fieldName: 'scheduling',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('scheduling');
      },
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head) => {
        return (
          <Th
            includeSortButtons
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`templates-table-heads-${head.fieldName}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default TemplatesTableHeads;
