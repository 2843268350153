import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { useTranslation } from 'react-i18next';
import { MessageMetadata } from 'src/types/MessageMetadata';
import MessageMetadataSearchInput from './MessageMetadataSearchInput';
import MessageMetadataDrawer from './MessegeMetadataDrawer';
import { Banner } from 'fgirot-k2-ui-components';
import { GET_MESSAGE_METADATA } from 'src/graphql/schema/query/monitoring/GetMessageMetadata';
import MessageMetadataTable from './MessageMetadataTable';
import './transaction-analysis-tab.scss';

const TransactionAnalysisTab = () => {
  const { t } = useTranslation();
  const [messageTransactionId, setMessageTransactionId] = useState<string>(undefined);
  const [selectedMessageId, setSelectedMessageId] = useState<string>();

  const handleCloseDrawer = () => {
    setSelectedMessageId(undefined);
  };

  const handleDrawerOpen = (id: string) => {
    setSelectedMessageId(id);
  };

  const handleTextSearch = (searchInput: string) => {
    setMessageTransactionId(searchInput);
  };

  const messageTransactionIdRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  const isSearchInputCorrect = messageTransactionIdRegex.test(messageTransactionId?.toString());

  return (
    <div className="transaction-analysis-tab">
      <MessageMetadataSearchInput onSearch={handleTextSearch} searchRequest={messageTransactionId} />
      {messageTransactionId && (
        <QueryWrapper
          query={GET_MESSAGE_METADATA}
          options={{ variables: { messageTransactionId: messageTransactionId } }}
        >
          {(messageMetadata: MessageMetadata[]) => {
            return (
              <>
                {isSearchInputCorrect ? (
                  <>
                    <MessageMetadataTable messageMetadata={messageMetadata} onClick={handleDrawerOpen} />
                    {selectedMessageId && (
                      <MessageMetadataDrawer
                        open={!!selectedMessageId}
                        onClose={handleCloseDrawer}
                        message={messageMetadata.find((message) => message.id === selectedMessageId)}
                      />
                    )}
                  </>
                ) : (
                  <Banner
                    className="transaction-analysis-tab__banner"
                    type="warning"
                    message={t('monitoring:transaction-analysis-tab.banner-error-message')}
                  />
                )}
              </>
            );
          }}
        </QueryWrapper>
      )}
    </div>
  );
};

export default TransactionAnalysisTab;
