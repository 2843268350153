import { useApolloMutation } from '../graphql/Mutation';
import { CreateExternalUser } from 'src/types/ExternalUser';
import { GET_USERS } from 'src/graphql/schema/query/GetUsers';
import { CREATE_USER } from 'src/graphql/schema/mutation/user/CreateUser';

export const useCreateUser = () => {
  const apolloMutation = useApolloMutation();

  const createUser = (request: CreateExternalUser, customerId: string) =>
    apolloMutation(CREATE_USER, '', { request }, [GET_USERS], customerId);

  return createUser;
};
