import { useTranslation } from 'react-i18next';
import PageBase from '../../../components/PageBase';
import React from 'react';
import './external-permission.scss';
import AdminUsersTab from 'src/components/Permission/ExternalPermission/AdminUsersTab';
import { Tab, Tabs } from 'fgirot-k2-ui-components';

const ExternalPermission = () => {
  const { t } = useTranslation();
  return (
    <PageBase title={t('permission:external.title')} header={true}>
      <div className="external-permission">
        <Tabs className="internal-permission" tabIndex={0}>
          <Tab title={t('permission:external.tabs.admin-users-label')}>
            <AdminUsersTab />
          </Tab>
        </Tabs>
      </div>
    </PageBase>
  );
};

export default ExternalPermission;
