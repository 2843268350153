import { Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import { MessageMetadata } from 'src/types/MessageMetadata';
import MessageDrawerTab from './MessageDrawerTab';
import './message-metadata-drawer.scss';

interface MessageMetadataDrawerProps {
  open: boolean;
  onClose: () => void;
  message: MessageMetadata;
}

const MessageMetadataDrawer: React.FC<MessageMetadataDrawerProps> = ({ open, onClose, message }) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    t('monitoring:transaction-analysis-tab.drawer.bread-crumbs.monitoring'),
    t('monitoring:transaction-analysis-tab.drawer.bread-crumbs.transaction-analysis'),
  ];

  return (
    <div className="message-metadata-drawer">
      <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
        <DrawerBase
          onClose={onClose}
          title={
            <>
              <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
              <Typography variant="h3" component="h3" bold>
                {t('monitoring:transaction-analysis-tab.drawer.title') + message.messageTransactionId}
              </Typography>
            </>
          }
        >
          <Tabs>
            <Tab title={t('monitoring:transaction-analysis-tab.drawer.tab-title')}>
              <MessageDrawerTab onClose={onClose} message={message} />
            </Tab>
          </Tabs>
        </DrawerBase>
      </DrawerModal>
    </div>
  );
};

export default MessageMetadataDrawer;
