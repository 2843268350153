import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { SearchPaymentsFilters, SearchPaymentsRequest } from 'src/types/SearchPaymentsRequest';
import './payments-filter-details.scss';

interface PaymentsFilterDetailsProps {
  searchRequest: SearchPaymentsRequest;
  applyFilters: (filters: SearchPaymentsFilters) => void;
}

export const PaymentsFilterDetails: FC<PaymentsFilterDetailsProps> = ({ searchRequest, applyFilters }) => {
  const { t } = useTranslation();
  const filtersApplied =
    searchRequest.filters?.paymentDueDateFilter?.length > 0 ||
    searchRequest.filters?.paymentCreatedDateFilter?.startDate?.length > 0 ||
    searchRequest.filters?.paymentCreatedDateFilter?.endDate?.length > 0 ||
    searchRequest.filters?.banksFilter?.length > 0 ||
    searchRequest.filters?.paymentServiceProvidersFilter?.length > 0 ||
    searchRequest.filters.paymentStatuses?.length > 0;

  const removePaymentDueDateFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      paymentDueDateFilter: '',
    });
  };

  const removePaymentCreatedStartDateFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      paymentCreatedDateFilter: {
        ...searchRequest.filters?.paymentCreatedDateFilter,
        startDate: '',
        startTime: '',
      },
    });
  };

  const removePaymentCreatedEndDateFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      paymentCreatedDateFilter: {
        ...searchRequest.filters?.paymentCreatedDateFilter,
        endDate: '',
        endTime: '',
      },
    });
  };

  const removeBanksFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      banksFilter: null,
    });
  };

  const removePaymentStatusesFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      paymentStatuses: [],
    });
  };

  const removePaymentServiceProvidersFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      paymentServiceProvidersFilter: null,
    });
  };

  return (
    <div className="invoices-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {searchRequest.filters?.paymentDueDateFilter?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.paymentDueDateFilter +
                ' ' +
                t('economy:search-payments-filters:payment-due-date')
              }
              onClick={removePaymentDueDateFilter}
            />
          )}
          {searchRequest.filters?.paymentCreatedDateFilter?.startDate?.length > 0 && (
            <FilterButton
              label={
                `${searchRequest.filters?.paymentCreatedDateFilter?.startDate} ${
                  searchRequest.filters?.paymentCreatedDateFilter?.startTime === undefined
                    ? ''
                    : searchRequest.filters?.paymentCreatedDateFilter?.startTime
                } ` + t('economy:search-payments-filters:payment-created-date-start-date')
              }
              onClick={removePaymentCreatedStartDateFilter}
            />
          )}
          {searchRequest.filters?.paymentCreatedDateFilter?.endDate?.length > 0 && (
            <FilterButton
              label={
                `${searchRequest.filters?.paymentCreatedDateFilter?.endDate} ${
                  searchRequest.filters?.paymentCreatedDateFilter?.endTime === undefined
                    ? ''
                    : searchRequest.filters?.paymentCreatedDateFilter?.endTime
                } ` + t('economy:search-payments-filters:payment-created-date-end-date')
              }
              onClick={removePaymentCreatedEndDateFilter}
            />
          )}
          {searchRequest.filters?.banksFilter?.length > 0 && (
            <FilterButton
              label={searchRequest.filters?.banksFilter.length + ' ' + t('economy:search-payments-filters:bank')}
              onClick={removeBanksFilter}
            />
          )}
          {searchRequest.filters?.paymentStatuses?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.paymentStatuses.length +
                ' ' +
                t('economy:search-payments-filters:payment-status')
              }
              onClick={removePaymentStatusesFilter}
            />
          )}
          {searchRequest.filters?.paymentServiceProvidersFilter?.length > 0 && (
            <FilterButton
              label={
                searchRequest.filters?.paymentServiceProvidersFilter.length +
                ' ' +
                t('economy:search-payments-filters:payment-service-provider')
              }
              onClick={removePaymentServiceProvidersFilter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PaymentsFilterDetails;
