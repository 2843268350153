import { gql } from '@apollo/client';

export const INTERNAL_REMOVE_USER_FROM_GROUP = gql`
  mutation InternalRemoveUserFromGroup($groupId: UUID!, $userId: UUID!) {
    internalRemoveUserFromGroup(groupId: $groupId, userId: $userId) {
      groupId
      userId
    }
  }
`;
