import React, { useState } from 'react';
import './admin-users-tab.scss';
import AdminUsersTable from './AdminUsersTable';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EXTERNAL_ADMIN_USERS } from 'src/graphql/schema/query/GetExternalUsers';
import { GetExternalUsersPage } from 'src/types/ExternalUser';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import AdminUsersDrawer from './AdminUsersDrawer';
import { useTranslation } from 'react-i18next';
import {  GetCustomerPage } from 'src/types/Customer';
import CreateExternalAdminUserModal from './CreateExternalAdminUserModal';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/customer/GetCustomers';
import { UserFilter } from 'src/types/user/Users';

const AdminUsersTab = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 15;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleOnCloseDrawer = () => {
    setSelectedUserId(undefined);
  };

  const handleClickOnUserRow = (userId: string) => {
    setSelectedUserId(userId);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="admin-users-tab">
      <QueryWrapper
        query={GET_EXTERNAL_ADMIN_USERS}
        options={{ variables: { userFilter: UserFilter.ADMIN_USERS, pageSize, pageNumber: queryPageNumber } }}
      >
        {({ externalUsers, paging }: GetExternalUsersPage) => {
          const hasUsers = !!externalUsers?.length;
          const totalPages = getTotalPages(paging.totalNumberOfElements, pageSize);
          const showPagination = totalPages > 1;
          return (
            <>
              {!hasUsers && <div data-testid={`no-users`}>Inga användare hittade</div>}
              {hasUsers && (
                <>
                  <AdminUsersTable adminUsers={externalUsers} onClick={handleClickOnUserRow} />
                </>
              )}
              {showPagination && (
                <div data-testid="admin-users-tab-pagination">
                  <Pagination
                    currentPage={pageNumber}
                    onPageChange={(nextPageNumber) => handleOnPageChange(nextPageNumber)}
                    totalPages={totalPages}
                  />
                </div>
              )}
              {selectedUserId && (
                <AdminUsersDrawer open={!!selectedUserId} onClose={handleOnCloseDrawer} userId={selectedUserId} />
              )}
            </>
          );
        }}
      </QueryWrapper>
      <QueryWrapper query={GET_ALL_CUSTOMERS}>
        {({customers}: GetCustomerPage) => (
          <CreateExternalAdminUserModal open={modalOpen} onClose={handleCloseModal} customers={customers} />
        )}
      </QueryWrapper>
      <div className="admin-users-tab__create-user-modal-button" data-testid="admin-users-tab-create-user-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'PlusLarge'} />}
          label={t('permission:create-user-button')}
          onClick={handleOpenModal}
        />
      </div>
    </div>
  );
};

export default AdminUsersTab;
