import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { InsuranceProviderDrawerTab } from 'src/types/tabs/InsuranceProviderDrawerTab';
import InsuranceProviderAboutTab from './InsuranceProviderAboutTab';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDER } from 'src/graphql/schema/query/GetInsuranceProvider';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import InsuranceProviderSchedulingTab from './InsuranceProviderSchedulingTab';
import InsuranceProviderMessageTab from './InsuranceProviderMessageTab';
import BankgiroNumbersTab from './BankgiroNumbersTab';

interface InsuranceProviderDrawerProps {
  open: boolean;
  onClose: () => void;
  selectedInsuranceProviderId: string;
}

function InsuranceProviderDrawer({ open, onClose, selectedInsuranceProviderId }: InsuranceProviderDrawerProps) {
  const [currentTab, setCurrentTab] = useState<InsuranceProviderDrawerTab>(InsuranceProviderDrawerTab.ABOUT);
  const { t } = useTranslation();

  return (
    <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
      <QueryWrapper
        query={GET_INSURANCE_PROVIDER}
        options={{ variables: { insuranceProviderId: selectedInsuranceProviderId } }}
      >
        {(insuranceProvider: InsuranceProvider) => (
          <DrawerBase onClose={onClose} title={insuranceProvider.legalName}>
            <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
              <Tab title={t('common:about')}>
                <InsuranceProviderAboutTab insuranceProvider={insuranceProvider} />
              </Tab>
              <Tab title={t('insuranceManager:insurance-provider-drawer.reporting')}>
                <InsuranceProviderSchedulingTab insuranceProvider={insuranceProvider} />
              </Tab>
              <Tab title={t('insuranceManager:insurance-provider-drawer.message')}>
                <InsuranceProviderMessageTab insuranceProvider={insuranceProvider} />
              </Tab>
              <Tab title={t('insuranceManager:insurance-provider-drawer.bankgiro-numbers')}>
                <BankgiroNumbersTab insuranceProvider={insuranceProvider} />
              </Tab>
            </Tabs>
          </DrawerBase>
        )}
      </QueryWrapper>
    </DrawerModal>
  );
}

export default InsuranceProviderDrawer;
