import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Button,
  Card,
  CardAction,
  Checkbox,
  Modal,
  Option,
  RadioCard,
  RadioGroup,
  Select,
} from 'fgirot-k2-ui-components';
import './create-internal-user-modal.scss';
import bankIdLogo from './BankID_logo.svg';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { createOptionsFromList } from 'src/util/OptionUtil';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import { IdentifierType } from 'src/types/User';
import { useInternalCreateUser } from 'src/service/user/InternalCreateUser';
import { CreateInternalUser } from 'src/types/InternalUser';
import useValidateCreateInternalUserForm from './util';
import { loggedInUserIsFGAdmin } from 'src/service/user/getUserFromToken';

interface CreateInternalUserModalProps {
  open: boolean;
  onClose: () => void;
}

const CONNECTION_LIMITED = 'LIMITED';
const CONNECTION_RUNNING = 'RUNNING';

const CreateInternalUserModal: FunctionComponent<CreateInternalUserModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const createInternalUser = useInternalCreateUser();
  const connectionOptions = [
    { value: CONNECTION_LIMITED, label: t('permission:create-user-modal.connection-limited-label') },
    { value: CONNECTION_RUNNING, label: t('permission:create-user-modal.connection-running-label') },
  ];
  const [selectedConnection, setSelectedConnection] = useState(CONNECTION_RUNNING);
  const connectionOptionsList = createOptionsFromList(connectionOptions, 'value', 'label');

  const isFGAdmin = loggedInUserIsFGAdmin();

  const [internalCreateUserRequest, setInternalCreateUserRequest] = useState<CreateInternalUser>({
    firstName: '',
    lastName: '',
    identifier: '',
    identifierType: 'BANK_ID',
    phone: '',
    email: '',
    orderRef: '',
    validTo: '',
    admin: false,
  });

  const formHasErrors = useValidateCreateInternalUserForm(internalCreateUserRequest, selectedConnection);

  const handleOnClose = () => {
    setInternalCreateUserRequest({
      firstName: '',
      lastName: '',
      identifier: '',
      identifierType: 'BANK_ID',
      phone: '',
      email: '',
      orderRef: '',
      validTo: '',
      admin: false,
    });
    setSelectedConnection(CONNECTION_RUNNING);
    onClose();
  };
  const handleFirstName = (event: ChangeEvent<HTMLInputElement>) =>
    setInternalCreateUserRequest((prev) => {
      return { ...prev, firstName: event.target.value };
    });
  const handleLastName = (event: ChangeEvent<HTMLInputElement>) =>
    setInternalCreateUserRequest((prev) => {
      return { ...prev, lastName: event.target.value };
    });
  const handleIdentifier = (event: ChangeEvent<HTMLInputElement>) =>
    setInternalCreateUserRequest((prev) => {
      return { ...prev, identifier: event.target.value };
    });
  const handleIdentifierType = (input: IdentifierType) =>
    setInternalCreateUserRequest((prev) => {
      return { ...prev, identifierType: input };
    });
  const handlePhone = (event: ChangeEvent<HTMLInputElement>) =>
    setInternalCreateUserRequest((prev) => {
      return { ...prev, phone: event.target.value };
    });
  const handleEmail = (event: ChangeEvent<HTMLInputElement>) =>
    setInternalCreateUserRequest((prev) => {
      return { ...prev, email: event.target.value };
    });
  const handleOrderRef = (event: ChangeEvent<HTMLInputElement>) =>
    setInternalCreateUserRequest((prev) => {
      return { ...prev, orderRef: event.target.value };
    });
  const handleSelectConnectionChange = (option: Option<string>) => {
    if (option.value === CONNECTION_RUNNING) {
      setInternalCreateUserRequest((prev) => {
        const validTo = option.value === CONNECTION_RUNNING ? '' : prev.validTo;
        return { ...prev, validTo };
      });
    }
    setSelectedConnection(option.value);
  };
  const handleValidToOnChange = (value: string) => {
    setInternalCreateUserRequest((prev) => {
      return { ...prev, validTo: value };
    });
  };

  const handleIsAdmin = (value: boolean) => {
    setInternalCreateUserRequest((prev) => {
      return { ...prev, admin: value };
    });
  };

  const handleCreateInternalUser = () => {
    if (!formHasErrors) {
      let request = internalCreateUserRequest;
      if (selectedConnection === CONNECTION_RUNNING) {
        request = { ...internalCreateUserRequest, validTo: null };
      } else {
        const date = new Date(request.validTo);
        request = { ...internalCreateUserRequest, validTo: date.toISOString() };
      }
      createInternalUser(request);
      handleOnClose();
    }
  };

  return (
    <Modal overlayBackground centered onClose={handleOnClose} open={open}>
      <Card title={t('permission:create-user-modal.internal-title')}>
        <div className="create-internal-user-modal" data-testid="create-internal-user-modal">
          <RadioGroup
            className="create-internal-user-modal__identification"
            label={t('permission:create-user-modal.identification-label')}
            selected={internalCreateUserRequest.identifierType}
            onChange={handleIdentifierType}
            card
          >
            <RadioCard value={'BANK_ID'} image={<img src={bankIdLogo} />} label={t('identifierTypes:BANK_ID')} />
            <></>
          </RadioGroup>
          <div className="create-internal-user-modal__grid">
            <TextInput
              label={t('common:first-name')}
              type="text"
              validationKey="text255"
              variant="default"
              value={internalCreateUserRequest.firstName}
              onChange={handleFirstName}
              data-testid="input-first-name"
              mandatory
            />
            <TextInput
              label={t('common:last-name')}
              type="text"
              validationKey="text255"
              variant="default"
              value={internalCreateUserRequest.lastName}
              onChange={handleLastName}
              data-testid="input-last-name"
              mandatory
            />
            <TextInput
              label={t('permission:create-user-modal.identifier-person-number-label')}
              type="text"
              validationKey="personNumber"
              variant="default"
              value={internalCreateUserRequest.identifier}
              onChange={handleIdentifier}
              data-testid="input-identifier"
              mandatory
            />
            <TextInput
              label={t('permission:create-user-modal.phone-label')}
              type="tel"
              validationKey="phoneNumber"
              variant="default"
              value={internalCreateUserRequest.phone}
              onChange={handlePhone}
              data-testid="input-phone"
              mandatory
            />
          </div>
          <TextInput
            label={t('common:email')}
            className="create-internal-user-modal__email"
            type="email"
            validationKey="email"
            variant="default"
            maxLength={255}
            value={internalCreateUserRequest.email}
            onChange={handleEmail}
            data-testid="input-email"
            mandatory
          />
          <TextInput
            label={t('permission:create-user-modal.order-ref-label')}
            className="create-internal-user-modal__reason"
            type="text"
            validationKey="text255"
            variant="default"
            value={internalCreateUserRequest.orderRef}
            onChange={handleOrderRef}
            data-testid="input-order-ref"
            mandatory
          />

          <div className="create-internal-user-modal__grid" data-testid="connection-wrapper">
            <Select
              allowSelectMaxWidth
              className="create-internal-user-modal__grid__select"
              label={t('permission:create-user-modal.connection-label')}
              selected={connectionOptionsList.find((option) => option.value === selectedConnection)}
              options={connectionOptionsList}
              onChange={handleSelectConnectionChange}
              placeholder={t('common:select')}
              data-testid="select"
            />

            <UpdateDatePicker
              label={t('permission:create-user-modal.valid-to-label')}
              fieldValue={internalCreateUserRequest.validTo}
              fieldKey="paymentDueDate"
              disabled={selectedConnection !== CONNECTION_LIMITED}
              onBlur={(_, value) => {
                handleValidToOnChange(value);
              }}
              data-testid="date-picker"
            />
          </div>
          {isFGAdmin && (
            <Checkbox
              checked={internalCreateUserRequest.admin}
              onChange={(e) => handleIsAdmin(e.target.checked)}
              label={t('permission:create-user-modal.admin-checkbox-label')}
            />
          )}
          <CardAction>
            <Button onClick={handleOnClose} type="link" label={t('common:cancel')} data-testid="cancel-button" />
            <Button
              label={t('common:add')}
              disabled={formHasErrors}
              onClick={handleCreateInternalUser}
              data-testid={`submit-button-${formHasErrors ? 'disabled' : 'active'}`}
            />
          </CardAction>
        </div>
      </Card>
    </Modal>
  );
};

export default CreateInternalUserModal;
