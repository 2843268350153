import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import DrawerBase from 'src/components/Common/DrawerBase';
import RejectionDetailsTab from '../RejectionDetailsTab';
import { RejectionDetail } from 'src/types/RejectionDetail';
import { useTranslation } from 'react-i18next';
import { RejectedInvoiceDrawerTab } from 'src/types/tabs/RejectedInvoiceDrawerTab';
import { Invoice } from 'src/types/Invoice';

interface RejectedInvoiceDrawerOverviewProps {
  invoice: Invoice;
  rejectionDetails: RejectionDetail[];
  onClose: () => void;
  onSelectRejectionDetail: (id: string) => void;
  currentTab: RejectedInvoiceDrawerTab;
}

const RejectedInvoiceDrawerOverview: FC<RejectedInvoiceDrawerOverviewProps> = ({
  invoice,
  rejectionDetails,
  onClose,
  onSelectRejectionDetail,
  currentTab,
}) => {
  const { t } = useTranslation();
  return (
    <DrawerBase
      onClose={onClose}
      title={t('economy:rejected-invoice-drawer.drawer-title') + ` ${invoice?.invoiceNumber}`}
      subTitle={
        <div className="rejected-invoice-drawer__sub-title">
          <Typography>{invoice.employer.legalName}</Typography>
          <Pill label={invoice.insuranceProvider?.legalName} />
        </div>
      }
      data-testid="rejected-invoice-drawer-overview-id"
    >
      <Tabs tabIndex={currentTab}>
        <Tab title={t('economy:rejected-invoice-drawer.rejection-details-drawer-tab.tab-label')}>
          <RejectionDetailsTab onSelectRejectionDetail={onSelectRejectionDetail} rejectionDetails={rejectionDetails} />
        </Tab>
      </Tabs>
    </DrawerBase>
  );
};

export default RejectedInvoiceDrawerOverview;
