import { InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceProvider } from 'src/types/InsuranceProvider';

interface InsuranceProviderAboutTabProps {
  insuranceProvider: InsuranceProvider;
}

function InsuranceProviderAboutTab({ insuranceProvider }: InsuranceProviderAboutTabProps) {
  const { t } = useTranslation();

  return (
    <div>
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('insuranceManager:insurance-provider-legal-name')} bold>
          <Typography variant="body2">{insuranceProvider.legalName}</Typography>
        </InlineEdit>
        <InlineEdit label={t('insuranceManager:insurance-provider-drawer.organisation-number')} bold>
          <Typography variant="body2">{insuranceProvider.organisationNumber}</Typography>
        </InlineEdit>
      </ListGroup>
    </div>
  );
}

export default InsuranceProviderAboutTab;
