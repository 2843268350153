import Joi from 'joi';

export const inputSchema = Joi.object({
  personNumber: Joi.string().allow(null, '').pattern(new RegExp('^\\d{8}-\\d{4}$')),
  expisoft: Joi.string().allow(null, '').min(12), // Set real pattern when we know how it looks
  amount: Joi.number().allow(null, '').max(1000000000).min(-1000000000),
  amount2Dec: Joi.string().allow(null, '').pattern(new RegExp('^([0-9\\s]{1,11}((\\,)[0-9]{0,2})?)$')),
  amount4Dec: Joi.string().allow(null, '').pattern(new RegExp('^([0-9\\s]{1,11}((\\,)[0-9]{0,4})?)$')),
  age: Joi.number().allow(null, '').integer().max(80).min(15),
  days: Joi.number().integer().max(600).min(0),
  text64: Joi.string().allow(null, '').max(64),
  text128: Joi.string().allow(null, '').max(128),
  text255: Joi.string().allow(null, '').max(255),
  isoDate: Joi.string().allow(null, '').pattern(new RegExp('^\\d{4}-\\d{2}-\\d{2}$')),
  hourWithMinutes: Joi.string().allow(null, '').pattern(new RegExp('^([0-1][0-9]|2[0-3]):[0-5][0-9]$')),
  uuid: Joi.string()
    .allow(null, '')
    .pattern(new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$')),
  phoneNumber: Joi.string()
    .allow(null, '')
    .pattern(/^(\d+|-?)$/)
    .max(16),
  email: Joi.string().allow(null, '').pattern(new RegExp('^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,})$')),
});
