import { gql } from '@apollo/client';

export const GET_INSURANCE_PROVIDERS = gql`
  query {
    insuranceProviders {
      id
      legalName
      organisationNumber
    }
  }
`;
