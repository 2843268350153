import React from 'react';
import { Tabs, Tab } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageBase from 'src/components/PageBase';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { Customer } from 'src/types/Customer';
import { GET_CUSTOMER } from 'src/graphql/schema/query/customer/GetCustomer';
import EmployersTab from './EmployersTab';
import useTabParams from 'src/hooks/useTabParams';
import { CustomerDetailsTab } from 'src/types/tabs/CustomerDetailsTab';
import LoadingPage from 'src/components/Common/LoadingPage';

const CustomerDetails = () => {
  const [currentTab, setCurrentTab] = useTabParams('tab', CustomerDetailsTab.EMPLOYER);
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { t } = useTranslation();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <QueryWrapper
      query={GET_CUSTOMER}
      options={{ variables: { customerId }, skip: !customerId }}
      loadingComponent={<LoadingPage />}
    >
      {(customer: Customer) => (
        <PageBase title={customer.name} handleBackButton={handleBackClick}>
          <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
            <Tab title={t('customer:employer')}>
              <EmployersTab customerId={customerId} />
            </Tab>
          </Tabs>
        </PageBase>
      )}
    </QueryWrapper>
  );
};

export default CustomerDetails;
