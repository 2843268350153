import { Button, Drawer, Icon, InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/Common/TextArea';
import { CreatePaymentNoteInput } from 'src/types/request/CreatePaymentNoteInput';
import { useCreatePaymentNote } from 'src/service/CreatePaymentNote';
import './create-new-payment-note.scss';
import { isBlank } from 'src/util/StringUtil/StringUtil';
import { LARGE_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import { Payment } from 'src/types/Payment';

interface CreateNewPaymentNoteProps {
  payment: Payment;
  onClose: () => void;
  onBackClick: () => void;
}
const CreateNewPaymentNote: FC<CreateNewPaymentNoteProps> = ({ payment, onClose, onBackClick }) => {
  const { t } = useTranslation();

  const [paymentNoteInput, setPaymentNoteInput] = useState<CreatePaymentNoteInput>({
    note: '',
  });

  const isCreateButtonDisabled = isBlank(paymentNoteInput.note) || paymentNoteInput.note.length < 3;

  const handleNoteInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPaymentNoteInput({ ...paymentNoteInput, note: e.target.value });
  };

  const handleAddNewPaymentNote = () => {
    useCreatePaymentNote(payment.id, { note: paymentNoteInput.note }, payment.employer.id).then(() => onBackClick());
  };

  return (
    <Drawer
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onBackClick} />
      }
      type="elements"
      onClose={onClose}
    >
      <div className="payment-drawer__title">
        <Typography variant="h3" component="h3" bold>
          {t('economy:payment-drawer.drawer-title') + ` ${payment.invoice.invoiceNumber}`}
        </Typography>
        <div className="payment-drawer__sub-title">
          <Typography>{payment.employer.legalName}</Typography>
          <Pill label={payment.invoice.insuranceProvider?.legalName} />
        </div>
      </div>
      <div className="create-new-payment-note">
        <Typography variant="body1" bold>
          {t('economy:payment-drawer.payment-notes-tab.note-label')}
        </Typography>
      </div>
      <div>
        <ListGroup variant="inline-edit"></ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit label={t('economy:payment-drawer.payment-notes-tab.tab-label')} bold>
            <div className="create-new-payment-note__note-input">
              <TextArea
                small
                value={paymentNoteInput?.note}
                onChange={handleNoteInput}
                placeholder={t('economy:payment-drawer.payment-notes-tab.note-placeholder')}
                characterLimit={LARGE_TEXTAREA_MAX_CHARACTERS}
              />
            </div>
          </InlineEdit>
        </ListGroup>
      </div>
      <div className="create-new-payment-note__buttons">
        <Button
          className="create-new-payment-note__button"
          label={t('common:add')}
          onClick={handleAddNewPaymentNote}
          disabled={isCreateButtonDisabled}
        />
      </div>
    </Drawer>
  );
};
export default CreateNewPaymentNote;
