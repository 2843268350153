import { gql } from '@apollo/client';

export const RESEND_REPORTING_MESSAGE = gql`
  mutation InternalResendReportingMessage($messageTransactionId: UUID!) {
    internalResendReportingMessage(messageTransactionId: $messageTransactionId) {
      newMessageTransactionId
      messageMetadataId
    }
  }
`;
