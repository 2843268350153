import { gql } from '@apollo/client';

export const GET_REJECTION_DETAILS = gql`
  query InternalGetRejectionDetails($invoiceHeaderId: UUID!) {
    internalRejectionDetails(invoiceHeaderId: $invoiceHeaderId) {
      id
      createdDate
      rejectedBy
      rejectionCause
      rejectionData
    }
  }
`;
