import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceAggregationBalance } from 'src/types/PeriodAggregation';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';

interface AccountingBalanceDetailsProps {
  groupedInvoicesByEmployer: InvoiceAggregationBalance[];
}

const AccountingBalanceDetails: FC<AccountingBalanceDetailsProps> = ({ groupedInvoicesByEmployer }) => {
  const { t } = useTranslation();

  const Title = ({ title }: { title: string }) => (
    <Typography variant="caption" bold>
      {title}
    </Typography>
  );
  const Property = ({ value }: { value: string | JSX.Element }) => <Typography variant="body2">{value}</Typography>;

  return (
    <>
      <div className="accounting-balance-list-item__titles">
        <Title title={t('economy:accounting-balance-tab:accounting-balance-details.balance')} />
        <Title title={t('economy:accounting-balance-tab:accounting-balance-details.premium-balance')} />
        <Title title={t('economy:accounting-balance-tab:accounting-balance-details.salary-tax-balance')} />
        <Title title={t('economy:accounting-balance-tab:accounting-balance-details.invoice-number')} />
      </div>
      <ul className="accounting-balance-list-item__list">
        {groupedInvoicesByEmployer.map((invoiceByEmployer, index) => (
          <li key={`AccountingBalanceDetails-${invoiceByEmployer}-${index}`}>
            <button className="accounting-balance-list-item__button" data-testid={`accounting-balancing-list-item-${invoiceByEmployer.id}`}>
              <div className="accounting-balance-list-item__row">
                <Property value={stringOrBlank(formatSweAmountText(`${invoiceByEmployer.balance}`))} />
                <Property value={stringOrBlank(formatSweAmountText(`${invoiceByEmployer.premiumBalance}`))} />
                <Property value={stringOrBlank(formatSweAmountText(`${invoiceByEmployer.salaryTaxBalance}`))} />
                <Property value={stringOrBlank(invoiceByEmployer.invoice.invoiceNumber)} />
              </div>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};
export default AccountingBalanceDetails;
