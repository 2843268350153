import { INTERNAL_REMOVE_USER_FROM_GROUP } from 'src/graphql/schema/mutation/internalGroup/InternalRemoveUserFromGroup';
import { GET_INTERNAL_GROUP } from 'src/graphql/schema/query/GetInternalGroup';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';
import { GET_INTERNAL_USER } from 'src/graphql/schema/query/GetInternalUser';
import { GET_INTERNAL_USERS } from 'src/graphql/schema/query/GetInternalUsers';
import { useApolloMutation } from 'src/service/graphql/Mutation';

export const useInternalRemoveUserFromGroup = () => {
  const apolloMutation = useApolloMutation();

  const internalRemoveUserFromGroup = (groupId: string, userId: string) =>
    apolloMutation(INTERNAL_REMOVE_USER_FROM_GROUP, '', { groupId, userId }, [
      GET_INTERNAL_USER,
      GET_INTERNAL_USERS,
      GET_INTERNAL_GROUP,
      GET_INTERNAL_GROUPS,
    ]);

  return internalRemoveUserFromGroup;
};
