import { INTERNAL_REMOVE_CUSTOMER_FROM_GROUP } from 'src/graphql/schema/mutation/internalGroup/InternalRemoveCustomerFromGroup';
import { GET_INTERNAL_GROUP } from 'src/graphql/schema/query/GetInternalGroup';
import { GET_INTERNAL_GROUPS } from 'src/graphql/schema/query/GetInternalGroups';
import { useApolloMutation } from 'src/service/graphql/Mutation';

export const useInternalRemoveCustomerFromGroup = () => {
  const apolloMutation = useApolloMutation();

  const internalRemoveCustomerFromGroup = (groupId: string, customerId: string) =>
    apolloMutation(INTERNAL_REMOVE_CUSTOMER_FROM_GROUP, '', { groupId, customerId },[GET_INTERNAL_GROUP, GET_INTERNAL_GROUPS]);

  return internalRemoveCustomerFromGroup;
};
