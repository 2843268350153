import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import './insurance-providers-table-row.scss';

interface InsuranceProvidersTableRowProps {
  insuranceProvider: InsuranceProvider;
  onClick: (insuranceProviderId: string) => void;
}

const InsuranceProvidersTableRow: FC<InsuranceProvidersTableRowProps> = ({ insuranceProvider, onClick }) => {
  return (
    <TableRow className={['insurance-providers-table-row'].join(' ')} onClick={() => onClick(insuranceProvider.id)}>
      <Td>{insuranceProvider.legalName}</Td>
      <Td>{insuranceProvider.organisationNumber}</Td>
    </TableRow>
  );
};

export default InsuranceProvidersTableRow;
