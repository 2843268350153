import { DrawerNavigation, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentNote } from 'src/types/PaymentNote';
import './payment-note-tab.scss';

interface PaymentNoteTabProps {
  onAddNewPaymentNote: () => void;
  onSelectPaymentNote: (id: string) => void;
  paymentNotes: PaymentNote[];
}

const PaymentNoteTab: FC<PaymentNoteTabProps> = ({ onAddNewPaymentNote, onSelectPaymentNote, paymentNotes }) => {
  const { t } = useTranslation();

  return (
    <div className="payment-note-tab">
      <ListGroup variant="drawer-navigation">
        <DrawerNavigation
          label={t('economy:payment-drawer.payment-notes-tab.add-payment-notes-button-label')}
          onClick={onAddNewPaymentNote}
        />
      </ListGroup>
      <ListGroup variant="drawer-navigation">
        {paymentNotes.map((note) => (
          <DrawerNavigation
            key={`PaymentNoteTab-${note.paymentId}`}
            label={
              <Typography bold variant="body2" component="span">
                {t('economy:payment-drawer.payment-notes-tab.created-date-label') +
                  ` ${String(note.createdDate).substring(0, 10)}`}
              </Typography>
            }
            value={note.note}
            onClick={() => onSelectPaymentNote(note.paymentId)}
          />
        ))}
      </ListGroup>
    </div>
  );
};
export default PaymentNoteTab;
