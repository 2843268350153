import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Button,
  Card,
  CardAction,
  Divider,
  Modal,
  Option,
  RadioCard,
  RadioGroup,
  Select,
} from 'fgirot-k2-ui-components';
import './create-external-admin-user-modal.scss';
import bankIdLogo from './BankID_logo.svg';
import freja_eid from './freja_eid.png';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { createOptionsFromList } from 'src/util/OptionUtil';
import UpdateDatePicker from 'src/components/Common/UpdateDatePicker';
import { CreateExternalUser } from 'src/types/ExternalUser';
import { Customer } from 'src/types/Customer';
import useValidateCreateAdminUserForm from './util';
import { useCreateUser } from 'src/service/user/CreateExternalAdminUser';
import { IdentifierType } from 'src/types/User';

interface CreateExternalAdminUserModalProps {
  open: boolean;
  onClose: () => void;
  customers: Customer[];
}

const CONNECTION_LIMITED = 'LIMITED';
const CONNECTION_RUNNING = 'RUNNING';

const CreateExternalAdminUserModal: FunctionComponent<CreateExternalAdminUserModalProps> = ({
  open,
  onClose,
  customers,
}) => {
  const { t } = useTranslation();
  const createExternalAdminUser = useCreateUser();
  const connectionOptions = [
    { value: CONNECTION_LIMITED, label: t('permission:create-user-modal.connection-limited-label') },
    { value: CONNECTION_RUNNING, label: t('permission:create-user-modal.connection-running-label') },
  ];
  const [selectedConnection, setSelectedConnection] = useState(CONNECTION_RUNNING);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const connectionOptionList = createOptionsFromList(connectionOptions, 'value', 'label');
  const customerOptionList = createOptionsFromList(customers, 'id', 'name');

  const [request, setRequest] = useState<CreateExternalUser>({
    firstName: '',
    lastName: '',
    identifier: '',
    identifierType: IdentifierType.BANK_ID,
    phone: '',
    email: '',
    orderRef: '',
    validTo: '',
    admin: true,
  });

  const formHasErrors = useValidateCreateAdminUserForm(request, selectedConnection, selectedCustomerId);
  const identifierLabel =
    request.identifierType === IdentifierType.BANK_ID
      ? t('permission:create-user-modal.identifier-person-number-label')
      : t('permission:create-user-modal.identifier-e-id-label');

  const handleOnClose = () => {
    setRequest({
      firstName: '',
      lastName: '',
      identifier: '',
      identifierType: IdentifierType.BANK_ID,
      phone: '',
      email: '',
      orderRef: '',
      validTo: '',
      admin: true,
    });
    setSearchInput('');
    setSelectedConnection(CONNECTION_RUNNING);
    setSelectedCustomerId('');
    onClose();
  };
  const handleFirstName = (event: ChangeEvent<HTMLInputElement>) =>
    setRequest((prev) => {
      return { ...prev, firstName: event.target.value };
    });
  const handleLastName = (event: ChangeEvent<HTMLInputElement>) =>
    setRequest((prev) => {
      return { ...prev, lastName: event.target.value };
    });
  const handleIdentifier = (event: ChangeEvent<HTMLInputElement>) =>
    setRequest((prev) => {
      return { ...prev, identifier: event.target.value };
    });
  const handleIdentifierType = (input: IdentifierType) =>
    setRequest((prev) => {
      return { ...prev, identifierType: input };
    });
  const handlePhone = (event: ChangeEvent<HTMLInputElement>) =>
    setRequest((prev) => {
      return { ...prev, phone: event.target.value };
    });
  const handleEmail = (event: ChangeEvent<HTMLInputElement>) =>
    setRequest((prev) => {
      return { ...prev, email: event.target.value };
    });
  const handleOrderRef = (event: ChangeEvent<HTMLInputElement>) =>
    setRequest((prev) => {
      return { ...prev, orderRef: event.target.value };
    });
  const handleSelectConnectionChange = (option: Option<string>) => {
    if (option.value === CONNECTION_RUNNING) {
      setRequest((prev) => {
        const validTo = option.value === CONNECTION_RUNNING ? '' : prev.validTo;
        return { ...prev, validTo };
      });
    }
    setSelectedConnection(option.value);
  };

  const handleSelectCustomerChange = (option: Option<string>) => {
    setSelectedCustomerId(option.value);
  };

  const handleValidToOnChange = (value: string) => {
    setRequest((prev) => {
      return { ...prev, validTo: value };
    });
  };

  const handleCreateAdminUser = () => {
    if (!formHasErrors) {
      let req = request;
      if (selectedConnection === CONNECTION_RUNNING) {
        req = { ...request, validTo: null };
      } else {
        const date = new Date(request.validTo);
        req = { ...request, validTo: date.toISOString() };
      }
      createExternalAdminUser(req, selectedCustomerId);
      handleOnClose();
    }
  };

  const filterBySearchInput = (customer: Option<string>) => {
    return customer.label.toLowerCase().includes(searchInput.toLowerCase());
  };

  return (
    <Modal overlayBackground centered onClose={handleOnClose} open={open}>
      <Card title={t('permission:create-user-modal.external-title')}>
        <div className="create-external-admin-user-modal" data-testid="create-external-admin-user-modal">
          <RadioGroup
            label={t('permission:create-user-modal.identification-label')}
            selected={request.identifierType}
            onChange={handleIdentifierType}
            card
          >
            <RadioCard
              value={IdentifierType.BANK_ID}
              image={<img src={bankIdLogo} />}
              label={t('identifierTypes:BANK_ID')}
            />
            <RadioCard value={IdentifierType.E_ID} image={<img src={freja_eid} />} label={t('identifierTypes:E_ID')} />
          </RadioGroup>
          <TextInput
            label={identifierLabel}
            className="create-external-admin-user-modal__identifier"
            type="text"
            validationKey={request.identifierType === IdentifierType.BANK_ID ? 'personNumber' : 'expisoft'}
            variant="default"
            value={request.identifier}
            onChange={handleIdentifier}
            mandatory
            data-testid="input-identifier"
          />
          <div className="create-external-admin-user-modal__grid">
            <TextInput
              label={t('common:first-name')}
              type="text"
              validationKey="text255"
              variant="default"
              value={request.firstName}
              onChange={handleFirstName}
              mandatory
              data-testid="input-first-name"
            />
            <TextInput
              label={t('common:last-name')}
              type="text"
              validationKey="text255"
              variant="default"
              value={request.lastName}
              onChange={handleLastName}
              mandatory
              data-testid="input-last-name"
            />
            <TextInput
              label={t('permission:create-user-modal.phone-label')}
              type="tel"
              variant="default"
              validationKey="phoneNumber"
              value={request.phone}
              onChange={handlePhone}
              mandatory
              data-testid="input-phone"
            />
          </div>
          <TextInput
            label={t('common:email')}
            className="create-external-admin-user-modal__email"
            type="email"
            validationKey="email"
            maxLength={255}
            variant="default"
            value={request.email}
            onChange={handleEmail}
            mandatory
            data-testid="input-email"
          />
          <TextInput
            label={t('permission:create-user-modal.order-ref-label')}
            className="create-external-admin-user-modal__reason"
            type="text"
            validationKey="text255"
            variant="default"
            value={request.orderRef}
            onChange={handleOrderRef}
            data-testid="input-order-ref"
          />
          <Divider className="create-external-admin-user-modal__divider" />
          <TextInput
            type="text"
            variant="default"
            value={searchInput}
            placeholder={t('permission:create-user-modal.filter-customers')}
            onChange={(e) => setSearchInput(e.target.value)}
            data-testid="select-customer-modal-input"
          />
          <Select
            allowSelectMaxWidth
            className="create-external-admin-user-modal__grid__select"
            label={t('permission:create-user-modal.mandatory-customer-label')}
            selected={customerOptionList.find((option) => option.value === selectedCustomerId)}
            options={customerOptionList.filter(filterBySearchInput).slice(0, 10)}
            onChange={handleSelectCustomerChange}
            placeholder={t('common:select')}
          />
          <Divider className="create-external-admin-user-modal__divider" />
          <div className="create-external-admin-user-modal__grid" data-testid="connection-wrapper">
            <Select
              allowSelectMaxWidth
              className="create-external-admin-user-modal__grid__select"
              label={t('permission:create-user-modal.connection-label')}
              selected={connectionOptionList.find((option) => option.value === selectedConnection)}
              options={connectionOptionList}
              onChange={handleSelectConnectionChange}
              placeholder={t('common:select')}
            />

            <UpdateDatePicker
              label={t('permission:create-user-modal.valid-to-label')}
              fieldValue={request.validTo}
              fieldKey="paymentDueDate"
              disabled={selectedConnection !== CONNECTION_LIMITED}
              onBlur={(_, value) => {
                handleValidToOnChange(value);
              }}
              data-testid="date-picker"
            />
          </div>
          <CardAction>
            <Button onClick={handleOnClose} type="link" label={t('common:cancel')} data-testid="cancel-button" />
            <Button
              label={t('common:add')}
              disabled={formHasErrors}
              onClick={handleCreateAdminUser}
              data-testid={`submit-button-${formHasErrors ? 'disabled' : 'active'}`}
            />
          </CardAction>
        </div>
      </Card>
    </Modal>
  );
};

export default CreateExternalAdminUserModal;
