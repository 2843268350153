import { useTranslation } from 'react-i18next';
import PageBase from '../../../components/PageBase';
import React from 'react';
import './internal-permission.scss';
import { Tab, Tabs } from 'fgirot-k2-ui-components';
import useTabParams from 'src/hooks/useTabParams';
import { PermissionTab } from 'src/types/tabs/PermissionTab';
import InternalUsersTab from 'src/components/Permission/InternalPermission/InternalUsersTab';
import GroupsTab from 'src/components/Permission/InternalPermission/GroupsTab';



const InternalPermission = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useTabParams('tab', PermissionTab.USERS);

  return (
    <PageBase title={t('permission:internal.title')} header={true}>
       <Tabs className="internal-permission" tabIndex={currentTab} onChange={setCurrentTab}>
        <Tab title={t('permission:internal.tabs.groups-label')}>
          <GroupsTab/>
        </Tab>
        <Tab title={t('permission:internal.tabs.users-label')}>
          <InternalUsersTab />
        </Tab>
      </Tabs>
    </PageBase>
  );
};

export default InternalPermission;