import React, { FC, useState } from 'react';
import { ReportingRuleConfigurationPage } from 'src/types/ReportingRuleConfiguration';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { useTranslation } from 'react-i18next';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { SEARCH_REPORTING_RULE_CONFIGURATIONS } from 'src/graphql/schema/query/SearchReportingRuleConfigurations';
import { ReportingRuleConfigurationsRequest } from 'src/types/ReportingRuleConfigurationsRequest';
import Configurations from './Configurations';
import { Option, Select } from 'fgirot-k2-ui-components';
import { createOptionsFromListWithAdditionalLabel } from 'src/util/OptionUtil';
import './configuration-tab.scss';

interface ConfigurationTabProps {
  insuranceProviders: InsuranceProvider[];
}

const ConfigurationTab: FC<ConfigurationTabProps> = ({ insuranceProviders }: ConfigurationTabProps) => {
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const [request, setRequest] = useState<ReportingRuleConfigurationsRequest>({
    pageNumber: pageNumber,
    pageSize: queryPageNumber,
    filters: { insuranceProviderIdsFilter: null },
  });

  const handleInsuranceProviderFilterChange = (options: Option<string>[]) => {
    setRequest({
      ...request,
      filters: { insuranceProviderIdsFilter: options.length === 0 ? null : options.map((option) => option.value) },
    });
  };

  const insuranceProviderOptions = createOptionsFromListWithAdditionalLabel(
    insuranceProviders,
    'id',
    'legalName',
    'organisationNumber',
  );

  return (
    <section className="configuration-tab__section">
      <QueryWrapper
        query={SEARCH_REPORTING_RULE_CONFIGURATIONS}
        options={{
          variables: {
            request: {
              pageNumber: queryPageNumber,
              pageSize: pageSize,
              filters: request?.filters,
            },
          },
        }}
      >
        {({ reportingRuleConfigurations, paging }: ReportingRuleConfigurationPage) => (
          <>
            <Select
              placeholder={t('insuranceManager:provider')}
              selected={insuranceProviderOptions.filter((option) =>
                request?.filters?.insuranceProviderIdsFilter?.includes(option.value),
              )}
              options={insuranceProviderOptions}
              onChange={handleInsuranceProviderFilterChange}
              selectAll
              multiple
            />
            <Configurations
              configurations={reportingRuleConfigurations}
              insuranceProviders={insuranceProviders}
              paging={paging}
              pageNumber={pageNumber}
              pageSize={pageSize}
              onPageChange={handleOnPageChange}
            />
          </>
        )}
      </QueryWrapper>
    </section>
  );
};

export default ConfigurationTab;
