import React, { ChangeEvent, FC, useState } from 'react';
import { Button, Card, CardAction, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { ReportingRuleConfigurationRequest } from 'src/types/request/ReportingRuleConfigurationRequest';
import { createReportingRuleConfiguration } from 'src/service/CreateReportingRuleConfiguration';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/GetInsuranceProviders';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { createOptionsFromList } from 'src/util/OptionUtil';
import TextArea from 'src/components/Common/TextArea';
import { SMALL_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import { isBlank } from 'src/util/StringUtil/StringUtil';
import TextInput from 'src/components/Common/TextInput';

interface CreateMessageConfigurationModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateMessageConfigurationModal: FC<CreateMessageConfigurationModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [createConfigurationRequest, setCreateConfigurationRequest] = useState<ReportingRuleConfigurationRequest>({
    name: '',
    description: '',
  });
  const [insuranceProvider, setInsuranceProvider] = useState<InsuranceProvider>({
    id: '',
    legalName: '',
    organisationNumber: '',
  });

  const isButtonDisabled = createConfigurationRequest.name.length < 3 || createConfigurationRequest.name.length > 64;

  const handleOnClose = () => {
    setCreateConfigurationRequest(undefined);
    onClose();
  };

  const handleInsuranceProviderChange = (option: Option<string>) => {
    setInsuranceProvider((prev) => {
      return { ...prev, id: option.value };
    });
  };

  const handleConfigurationName = (event: ChangeEvent<HTMLInputElement>) =>
    setCreateConfigurationRequest((prev) => {
      return { ...prev, name: event.target.value };
    });

  const handleConfigurationDescription = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setCreateConfigurationRequest((prev) => {
      return { ...prev, description: event.target.value };
    });

  const handleCreateNewMessageConfiguration = () => {
    createReportingRuleConfiguration(insuranceProvider.id, {
      name: createConfigurationRequest.name,
      description: createConfigurationRequest.description,
    })
      .catch(() => {
        alert('Error when creating a reporting rule configuration');
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Modal overlayBackground centered onClose={handleOnClose} open={open}>
      <Card
        className="create-modal-card"
        title={t('insuranceManager:configuration-modal.new-message-configuration')}
        size="narrow"
      >
        <QueryWrapper query={GET_INSURANCE_PROVIDERS} type="list">
          {(insuranceProviders: InsuranceProvider[]) => {
            const insuranceProviderOptions = createOptionsFromList(insuranceProviders, 'id', 'legalName');
            return (
              <div>
                <Typography variant="caption">{t('insuranceManager:configuration-modal.choose-insurance-provider-label')}</Typography>
                <Select
                  outlined
                  className="create-message-configuration-modal__dropdown"
                  placeholder={t('insuranceManager:configuration-modal.choose-insurance-provider')}
                  options={insuranceProviderOptions}
                  selected={insuranceProviderOptions.find((option) => option.value === insuranceProvider.id)}
                  onChange={handleInsuranceProviderChange}
                />
              </div>
            );
          }}
        </QueryWrapper>
        <TextInput
          label={t('insuranceManager:configuration-modal.name')}
          type="text"
          mandatory
          validationKey="text64"
          variant="default"
          maxLength={65}
          placeholder={t('insuranceManager:configuration-modal.name-placeholder')}
          value={createConfigurationRequest.name}
          disabled={isBlank(insuranceProvider.id)}
          onChange={handleConfigurationName}
        />
        <div>
          <Typography variant="caption">{t('insuranceManager:configuration-modal.description')}</Typography>
          <TextArea
            placeholder={t('insuranceManager:configuration-modal.description-placeholder')}
            onChange={handleConfigurationDescription}
            validationKey="text255"
            characterLimit={SMALL_TEXTAREA_MAX_CHARACTERS}
            value={createConfigurationRequest.description}
          />
        </div>
        <CardAction>
          <Button onClick={handleOnClose} type="link" label={t('common:cancel')} />
          <Button
            label={t('common:create')}
            disabled={isButtonDisabled}
            onClick={handleCreateNewMessageConfiguration}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateMessageConfigurationModal;
