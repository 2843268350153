import { gql } from '@apollo/client';

export const CREATE_REPORTING_RULE_CONFIGURATION = gql`
  mutation ($insuranceProviderId: UUID!, $request: ReportingRuleConfigurationRequest!) {
    createReportingRuleConfiguration(insuranceProviderId: $insuranceProviderId, request: $request) {
      id
      insuranceProviderId
      name
      description
      reportingRuleGroups {
        reportingType
        rules {
          id
          insuranceEventType
          availableMessageFormat
        }
      }
    }
  }
`;
