import { gql } from '@apollo/client';

export const GET_INTERNAL_ROLES = gql`
  query ($pageSize: Int, $pageNumber: Int) {
    internalRoles(pageSize: $pageSize, pageNumber: $pageNumber) {
      roles {
        id
        customerId
        name
        permissions {
          value
        }
      }
    }
  }
`;
