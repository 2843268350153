import { TableRow, Td } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/Invoice';
import { formatSweAmountText } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil/StringUtil';
import './rejected-invoices-table-row.scss';

interface RejectedInvoicesTableRowProps {
  invoice: Invoice;
  onClick: (id: string) => void;
}

const RejectedInvoicesTableRow: FC<RejectedInvoicesTableRowProps> = ({ invoice, onClick }) => {
  const { t } = useTranslation();

  return (
    <TableRow
      className="rejected-invoices-table-row"
      onClick={() => onClick(invoice.id)}
      data-testid={`rejected-invoices-table-row__${invoice.id}`}
    >
      <Td compressed>{stringOrBlank(invoice.employer?.legalName)}</Td>
      <Td compressed>{invoice.fileName}</Td>
      <Td compressed>{stringOrBlank(invoice.insuranceProvider?.legalName)}</Td>
      <Td compressed>{invoice.accountNumberTo}</Td>
      <Td compressed>{invoice.invoiceNumber}</Td>
      <Td compressed>{formatSweAmountText(`${invoice.totalAmount}`)}</Td>
      <Td compressed>{t(`invoice:invoice-type-code.${invoice.invoiceTypeCode}`)}</Td>
      <Td compressed>{stringOrBlank(invoice.dueDate)}</Td>
      <Td compressed>{stringOrBlank(invoice.paymentDueDate)}</Td>
    </TableRow>
  );
};

export default RejectedInvoicesTableRow;
