import { Invoice } from 'src/types/Invoice';
import { SearchInvoicesRequest } from 'src/types/SearchInvoicesRequest';

export const getInitialSearchInvoicesRequest = (): SearchInvoicesRequest => {
  return {
    searchInput: '',
    pageNumber: 0,
    pageSize: 20,
    filters: {
      paymentDueDateFilter: '',
      banksFilter: null,
      paymentServiceProvidersFilter: null,
      invoiceTypeCodesFilter: ['DEBIT'],
    },
  };
};

export const groupInvoiceByCustomer = (invoices: Invoice[]): Map<string, Invoice[]> => {
  return invoices.reduce((map, invoice) => {
    const grouped = map.get(invoice.customerId);
    if (grouped) {
      grouped.push(invoice);
    } else {
      map.set(invoice.customerId, [invoice]);
    }
    return map;
  }, new Map<string, Invoice[]>());
};

export const groupInvoiceByEmployer = (invoices: Invoice[]): Map<string, Invoice[]> => {
  return invoices.reduce((map, invoice) => {
    const grouped = map.get(invoice.employer.id);
    if (grouped) {
      grouped.push(invoice);
    } else {
      map.set(invoice.employer.id, [invoice]);
    }
    return map;
  }, new Map<string, Invoice[]>());
};
