import { client } from 'src/graphql';
import { CREATE_REPORTING_RULE_CONFIGURATION } from 'src/graphql/schema/mutation/CreateReportingRuleConfiguration';
import { GET_REPORTING_RULE_CONFIGURATION } from 'src/graphql/schema/query/GetReportingRuleConfiguration';
import { SEARCH_REPORTING_RULE_CONFIGURATIONS } from 'src/graphql/schema/query/SearchReportingRuleConfigurations';
import { ReportingRuleConfigurationRequest } from 'src/types/request/ReportingRuleConfigurationRequest';

export const createReportingRuleConfiguration = (
  insuranceProviderId: string,
  request: ReportingRuleConfigurationRequest,
): Promise<void> => {
  return client
    .mutate({
      mutation: CREATE_REPORTING_RULE_CONFIGURATION,
      variables: { insuranceProviderId, request },
    })
    .then(() => {
      client.refetchQueries({
        include: [GET_REPORTING_RULE_CONFIGURATION, SEARCH_REPORTING_RULE_CONFIGURATIONS],
      });
    });
};
