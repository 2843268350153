import { gql } from '@apollo/client';

export const GET_DOCUMENT_TEMPLATES = gql`
  query InternalGetDocumentTemplates {
    internalDocumentTemplates {
      id
      name
      description
      formatType
    }
  }
`;
