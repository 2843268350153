import { gql } from '@apollo/client';

export const SEARCH_REPORTING_RULE_CONFIGURATIONS = gql`
  query SearchReportingRuleConfigurations($request: ReportingRuleConfigurationsRequest) {
    reportingRuleConfigurations(request: $request) {
      paging {
        hasNext
        totalNumberOfElements
      }
      reportingRuleConfigurations {
        id
        insuranceProviderId
        name
        description
        reportingRuleGroups {
          reportingType
          rules {
            id
            insuranceEventType
            availableMessageFormat
            messageFormatCategory
          }
        }
      }
    }
  }
`;
