import { GET_USER } from 'src/graphql/schema/GetUser';
import { useApolloMutation } from '../graphql/Mutation';
import { INTERNAL_LOCK_USER } from 'src/graphql/schema/mutation/user/InternalLockUser';
import { INTENRANL_UNLOCK_USER } from 'src/graphql/schema/mutation/user/InternalUnlockUser';
import { LOCK_USER } from 'src/graphql/schema/mutation/user/LockUser';
import { UNLOCK_USER } from 'src/graphql/schema/mutation/user/UnlockUser';
import { GET_INTERNAL_USER } from 'src/graphql/schema/query/GetInternalUser';

export const useLockUser = () => {
  const apolloMutation = useApolloMutation();
  const lockUser = (userId: string) => apolloMutation(LOCK_USER, '', { userId }, [GET_USER]);
  return lockUser;
};

export const useUnlockUser = () => {
  const apolloMutation = useApolloMutation();
  const unlockUser = (userId: string) => apolloMutation(UNLOCK_USER, '', { userId }, [GET_USER]);
  return unlockUser;
};

export const useInternalLockUser = () => {
  const apolloMutation = useApolloMutation();
  const internalLockUser = (userId: string) => apolloMutation(INTERNAL_LOCK_USER, '', { userId }, [GET_INTERNAL_USER]);
  return internalLockUser;
};

export const useInternalUnlockUser = () => {
  const apolloMutation = useApolloMutation();
  const internalUnlockUser = (userId: string) => apolloMutation(INTENRANL_UNLOCK_USER, '', { userId }, [GET_INTERNAL_USER]);
  return internalUnlockUser;
};