import { Button, Drawer, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { ChangeEvent, useState } from 'react';
import './add-user-to-group.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { AddUserToGroupInput, GetGroup, GroupUser } from 'src/types/Group';
import { InternalUser } from 'src/types/InternalUser';
import {
  useInternalAddUserToGroup,
} from 'src/service/internalGroup/InternalAddUserToGroup';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';

interface AddUserToGroupProps {
  onClose: () => void;
  group: GetGroup;
  groupUsers: GroupUser[];
  allUsers: InternalUser[];
  breadcrumbs:string[];
}

const AddUserToGroup: React.FunctionComponent<AddUserToGroupProps> = ({
  onClose,
  group,
  groupUsers,
  allUsers,
  breadcrumbs
}) => {
  const { t } = useTranslation();
  const [searchUserInput, setSearchUserInput] = useState('');
  const addUserToInternalGroup = useInternalAddUserToGroup();
  const searchUserInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserInput(event.target.value);
  };

  const addUser = (userId: string) => {
    const request: AddUserToGroupInput = { validFrom: null, validTo: null };
    addUserToInternalGroup(group.id, userId, request);
  };

  const mapNamesToOneString = (firstName: string, lastName: string) => {
    return `${firstName} ${lastName}`;
  };

  const usersAlreadyPartOfGroup = ({ user }: InternalUser) => !groupUsers.find((gUser) => gUser.id === user.id);
  const filterNonAdminsWhenAdminGroup = (user: InternalUser, adminGroup: boolean) =>
    adminGroup ? user.user.admin : true;
  const nameMatchSearchString = ({ user }: InternalUser) => mapNamesToOneString(user.firstName, user.lastName).toLowerCase().includes(searchUserInput.toLowerCase());

  return (
    <Drawer
      leftButton={<Button type="link" label={t('common:back')} icon={<Icon type="ArrowLeft" />} onClick={onClose} />}
      type="elements"
      onClose={onClose}
    >
      <div className="add-user-to-group__title">
        <DrawerBreadcrumbs breadcrumbs={breadcrumbs}/>
        <Typography variant="h3" component="h3" bold>
          {t('permission:internal.groups-drawer.users.add-new-user')}
        </Typography>
        <div className="add-user-to-group__sub-title">
          <Typography>{group.name}</Typography>
        </div>
        <ListGroup variant='inline-edit'>
        <InlineEdit
          label={t('permission:internal.groups-drawer.users.search-label')}
          className="add-user-to-group__search-input"
        >
          <TextInput value={searchUserInput} onChange={searchUserInputOnChange} placeholder={t('common:name')} data-testid="search-input"/>
        </InlineEdit>
        </ListGroup>
      </div>
      <ListGroup variant='inline-edit'>
      {allUsers
        .filter(usersAlreadyPartOfGroup)
        .filter((user)=>filterNonAdminsWhenAdminGroup(user, group.admin))
        .filter(nameMatchSearchString)
        .slice(0,15)
        .map(({ user }) => (
          <InlineEdit label={mapNamesToOneString(user.firstName, user.lastName)} alignRight key={user.id} data-testid="user-list-item">
            <Button label={t('common:add')} onClick={() => addUser(user.id)} />
          </InlineEdit>
        ))}
        </ListGroup>
    </Drawer>
  );
};

export default AddUserToGroup;
