import { Banner, SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { sortAlphabeticalAsc, sortAlphabeticalDesc } from 'src/util/SortUtil';
import TemplateTableRow from './TemplateTableRow';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateEmployerAssignment } from 'src/types/documenttemplate/DocumentTemplateEmployerAssignment';
import { TemplatesTableSortField } from './TemplatesTableSortField';
import TemplatesTableHeads from './TemplateTableHeads';

interface TemplatesTableProps {
  assignments: DocumentTemplateEmployerAssignment[];
  onClick: (templateId: string) => void;
}

const TemplatesTable: FC<TemplatesTableProps> = ({ assignments, onClick }) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<TemplatesTableSortField>('templateName');
  const [sortDirection, setSortDirection] = useState<SortDirection>('DOWN');

  const handleSortFieldChange = (sortField: TemplatesTableSortField) => setSortField(sortField);
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));

  const sortFunction = (a: DocumentTemplateEmployerAssignment, b: DocumentTemplateEmployerAssignment) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;

    switch (sortField) {
      case 'templateName':
        return sortAlphabetical(a.templateName, b.templateName);
      case 'formatType':
        return sortAlphabetical(a.formatType, b.formatType);
      default:
        return undefined;
    }
  };

  const sortedAssignments = [...assignments].sort(sortFunction) || [];

  return (
    <>
      {sortedAssignments.length > 0 ? (
        <TableBase>
          <TableHead filled>
            <TemplatesTableHeads
              sortDirection={sortDirection}
              sortField={sortField}
              onSortDirectionChange={handleSortDirectionChange}
              onSortFieldChange={handleSortFieldChange}
            />
          </TableHead>
          <TableBody>
            {sortedAssignments.map((assignment) => {
              return (
                <TemplateTableRow key={`TemplateTableRow-${assignment.id}`} assignment={assignment} onClick={onClick} />
              );
            })}
          </TableBody>
        </TableBase>
      ) : (
        <Banner message={t('documents:templates-tab.table.banner-info-message')} />
      )}
    </>
  );
};

export default TemplatesTable;
