import { useApolloMutation } from './graphql/Mutation';
import { CreateResendPaymentInput } from 'src/types/request/CreateResendPaymentInput';
import { CREATE_RESEND_PAYMENT } from 'src/graphql/schema/mutation/CreateResendPayment';
import { SEARCH_PAYMENTS } from 'src/graphql/schema/query/SearchPayments';

export const useCreateResendPayments = () => {
  const apolloMutation = useApolloMutation();

  const createResendPayments = (request: CreateResendPaymentInput, employerId: string, customerId: string) =>
    apolloMutation(CREATE_RESEND_PAYMENT, employerId, { request }, [SEARCH_PAYMENTS], customerId);

  return createResendPayments;
};
